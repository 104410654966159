#rebrand {
  .order-item {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: var(--color-white-md);

    &.active {
      .order-item__head {
        margin-bottom: 20px;
      }

      .order-item__head {
        &::after {
          transform: rotate(0);
          transition: transform 0.3s ease-in;
        }
      }

      .order-item__body {
        display: grid;
        transition: display 0s linear 0s;
      }
    }

    &__head {
      position: relative;
      width: 95%;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      cursor: pointer;

      &::after {
        content: url("../../../images/arrow.svg");
        position: absolute;
        top: 10px;
        right: -5%;
        transform: rotate(180deg);
        transition: transform 0.3s ease-in;
      }

      @media (--breakpoint-desktop) {
        grid-template-rows: auto auto;
        align-items: start;
        gap: 10px;

        &::after {
          top: 40%;
        }
      }
    }

    &__content {
      width: 85%;

      &-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--redesign-color-black-md);
      }

      @media (--breakpoint-desktop) {
        grid-row: 2;
        width: 75%;
      }
    }

    &__status {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -10px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        transform: translateY(-50%);
      }

      p {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &--new {
        color: var(--redesign-color-green);

        &::before {
          background-color: var(--redesign-color-green);
        }
      }

      &--processed {
        color: var(--redesign-color-yellow);

        &::before {
          background-color: var(--redesign-color-yellow);
        }
      }

      &--collected {
        color: var(--redesign-color-orange);

        &::before {
          background-color: var(--redesign-color-orange);
        }
      }

      &--received {
        color: var(--redesign-color-green-md);

        &::before {
          background-color: var(--redesign-color-green-md);
        }
      }

      &--completed {
        color: var(--color-gray-dk);

        &::before {
          background-color: var(--color-gray-dk);
        }
      }

      @media (--breakpoint-desktop) {
        grid-row: 1;
        padding-left: 12px;

        &::before {
          left: 0;
        }
      }
    }

    &__body {
      display: none;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      transition: display 0s linear 0.3s;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }

    &__wrapper {
      padding: 20px;
      border-radius: 4px;
      background-color: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        padding: 16px;
        border-radius: 10px;
      }
    }

    &__list {
      padding: 20px 0;
      border-bottom: 1px solid var(--redesign-color-border);

      &:first-of-type {
        padding-top: 0;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
      }

      &-title {
        color: var(--redesign-color-black-md);
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 600;
          color: var(--redesign-color-black-md);

          @media (--breakpoint-tablet-sm) {
            font-size: 14px;
          }
        }

        span {
          font-weight: 400;
          color: var(--color-gray-dk);
        }
      }
    }

    &__info {
      padding-top: 20px;

      &-item,
      &-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--redesign-color-black-md);
      }

      &-item {
        padding-bottom: 10px;

        > h4 {
          font-size: 18px;
          font-weight: 600;

          @media (--breakpoint-tablet-sm) {
            font-size: 16px;
          }
        }

        > p {
          font-size: 18px;
          font-weight: 600;

          span {
            font-size: 14px;
          }
        }
      }

      &-price {
        > h4 {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
        }

        > p {
          font-size: 20px;
          font-weight: 700;

          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    @media (--breakpoint-tablet-sm) {
      padding: 16px;
    }
  }
}
