/* ------------------------------------------------------------ *\
    #variables
\* ------------------------------------------------------------ */
@import "partials/_font-family.css";
@import "partials/settings.css";
/* ------------------------------------------------------------ *\
    #plugins
\* ------------------------------------------------------------ */
@import "plugins/slick.css";
@import "plugins/slick-theme.css";
@import "plugins/colorbox.css";
@import "plugins/toastr.css";
@import "plugins/perfect-scrollbar.css";
@import "plugins/materialize.parallax.css";
@import "plugins/nouislider.css";
@import "plugins/jquery.bootstrap-touchspin.css";
@import "plugins/jquery.fancybox.css";
@import "plugins/gs-iconfonts.css";
@import "plugins/swiper.css";
/* ------------------------------------------------------------ *\
    #generic
\* ------------------------------------------------------------ */
@import "partials/generic.css";
/* ------------------------------------------------------------ *\
    #regions
\* ------------------------------------------------------------ */
@import "partials/regions.css";
/* ------------------------------------------------------------ *\
    #modules
\* ------------------------------------------------------------ */
@import "partials/modules.css";
/* ------------------------------------------------------------ *\
    #responsive
\* ------------------------------------------------------------ */
@import "partials/responsive.css";
@import "partials/responsive.css";
#rebrand .filter-colors {
  position: relative;
}
#rebrand .filter-colors .color-variations {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .filter-colors .color-variations {
    font-size: 16px;
    color: var(--redesign-color-black-md);
  }
}
#rebrand .filter-colors .color-variation {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}
#rebrand .filter-colors .color-variation label {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-mid);
  border-radius: 3px;
}
#rebrand .filter-colors .color-variation label::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: currentColor;
  transform-origin: center center;
  transition: all 0.15s ease-in;
}
#rebrand .filter-colors .color-variation input:checked + label:before {
  content: url("../../../images/check.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none;
}
#rebrand .filter-colors .color-variation input:checked + label[style*=white]:before {
  content: url("../../../images/check-dark.svg");
}

#rebrand .products-listing-sidebar {
  position: relative;
}
#rebrand .products-listing-sidebar .sidebar {
  margin-right: 15px;
}
#rebrand .products-listing-sidebar .sidebar .filters-aside {
  margin-right: 0;
}
#rebrand .products-listing-sidebar__wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
#rebrand .products-listing-sidebar__filters {
  display: flex;
  align-items: start;
  gap: 30px;
}
#rebrand .products-listing-sidebar__filters .filter-total__element {
  flex-wrap: wrap;
  row-gap: 15px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-sidebar__filters .filter-total__element {
    flex-wrap: nowrap;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-sidebar__filters {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #rebrand .products-listing-sidebar__filters::-webkit-scrollbar {
    display: none;
  }
}
#rebrand .products-listing-sidebar__content {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (--breakpoint-desktop) {
  #rebrand .products-listing-sidebar__content {
    display: none;
  }
}
#rebrand .products-listing-sidebar__counter {
  display: none;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 18px;
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border-radius: 100px;
}
#rebrand .products-listing-sidebar__counter.active {
  display: flex;
}
#rebrand .products-listing-sidebar__title {
  font-size: 20px;
  color: var(--redesign-color-black-md);
}
#rebrand .products-listing-sidebar__button button {
  height: auto;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--redesign-color-primary);
  white-space: nowrap;
  text-transform: uppercase;
  overflow: visible;
}
@media (--breakpoint-desktop) {
  #rebrand .products-listing-sidebar__button {
    display: none;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .products-listing-sidebar__categories {
    display: none;
  }
  #rebrand .products-listing-sidebar__categories.active {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
}

#rebrand .product-preview {
  position: relative;
  padding-top: 30px;
}
#rebrand .product-preview__container {
  display: grid;
  grid-template-columns: 116px auto 1fr;
  gap: 34px;
  grid-template-areas: "swiper picture content" ". locations .";
}
#rebrand .product-preview__container .box-type-2 {
  padding: 0;
}
#rebrand .product-preview__container .box-type-2:hover .picture {
  overflow: unset;
}
#rebrand .product-preview__container .box-type-2:hover img {
  transform: none;
}
#rebrand .product-preview__container .box-type-2 .picture {
  max-width: 112px;
  margin-bottom: 12px;
  cursor: pointer;
}
#rebrand .product-preview__container .box-type-2 .picture.active img {
  border: 2px solid var(--redesign-color-primary);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__container .box-type-2 .picture.active img {
    border: none;
  }
}
#rebrand .product-preview__container .box-type-2 .picture img {
  border-radius: 4px;
  border: 2px solid transparent;
}
@media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
  #rebrand .product-preview__container .box-type-2 .picture img {
    max-width: 328px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__container .box-type-2 .picture img {
    min-width: 328px;
    border: none;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__container .box-type-2 .picture {
    max-width: 100%;
    min-height: 300px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-preview__container {
    grid-template-columns: 1fr;
    grid-template-areas: "swiper" "content" "locations";
  }
}
@media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
  #rebrand .product-preview__container {
    grid-template-columns: 328px auto;
    grid-template-areas: "swiper content" "swiper locations";
    gap: 23px;
  }
}
@media (--breakpoint-tablet-up) and (--breakpoint-desktop) {
  #rebrand .product-preview__container {
    grid-template-columns: 518px auto;
    grid-template-areas: "swiper content" "swiper locations";
    gap: 23px;
  }
}
#rebrand .product-preview__content {
  grid-area: content;
}
#rebrand .product-preview__content-top {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  column-gap: 17px;
  row-gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--redesign-color-border);
  grid-template-areas: "title brand" "code info";
}
#rebrand .product-preview__content-top .title {
  grid-area: title;
  max-width: 436px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview__content-top .logo {
  grid-area: brand;
}
#rebrand .product-preview__content-top .logo img {
  float: right;
}
#rebrand .product-preview__content-top .products-code {
  grid-area: code;
  font-size: 12px;
  color: var(--color-gray-dk);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__content-top .products-code {
    font-size: 14px;
  }
}
#rebrand .product-preview__content-top .product-info {
  grid-area: info;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 6px;
  font-size: 12px;
  color: var(--redesign-color-primary);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__content-top .product-info {
    justify-self: start;
    font-size: 14px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__content-top {
    grid-template-areas: "title title" "code brand" "info brand";
    gap: 0;
  }
}
#rebrand .product-preview__content-info {
  padding-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .product-preview__content-list {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 32px;
  margin-bottom: 16px;
  list-style: none;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__content-list {
    grid-template-columns: 1fr;
  }
}
#rebrand .product-preview__content-item {
  position: relative;
}
#rebrand .product-preview__content-item::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--redesign-color-black-md);
  transform: translateY(-50%);
}
#rebrand .product-preview__content-item span {
  display: block;
  padding-left: 12px;
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview__content-link {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
  color: var(--redesign-color-black-md);
  text-transform: uppercase;
  text-decoration: underline;
}
#rebrand .product-preview__btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
#rebrand .product-preview__item .picture {
  position: relative;
  width: 574px;
  height: 526px;
  margin-bottom: 18px;
}
#rebrand .product-preview__item .picture > img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview__item .picture {
    display: none;
  }
}
#rebrand .product-preview .box-type-2 {
  display: flex;
  gap: 20px;
  grid-area: swiper;
  justify-content: center;
  overflow: hidden;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .box-type-2 {
    position: relative;
    width: 100%;
    height: fit-content;
    padding-bottom: 30px;
  }
}
#rebrand .product-preview .boxes-type-10 {
  max-width: 120px;
  margin: 0;
  padding: 0;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .boxes-type-10 {
    max-width: 100%;
  }
}
#rebrand .product-preview .slick-slide {
  border: 0;
}
#rebrand .product-preview .slick-slide > .picture {
  max-width: 112px;
  height: 112px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .slick-slide > .picture {
    max-width: 500px;
    height: 300px;
    margin: 0 auto;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-preview .slick-slide > .picture {
    max-width: 100%;
  }
}
#rebrand .product-preview .slick-list {
  margin-top: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .slick-list {
    margin-top: 0;
  }
}
#rebrand .product-preview .box-type-10 {
  width: 100%;
  border: 0;
  border-radius: 10px;
}
#rebrand .product-preview .slick-current > img {
  border: 2px solid var(--redesign-color-primary);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .slick-current > img {
    border: 0;
  }
}
#rebrand .product-preview .slick-dots {
  bottom: -30px;
}
#rebrand .product-preview .slick-dots li.slick-active button::before {
  background-color: var(--redesign-color-primary);
  opacity: 1;
}
#rebrand .product-preview .slick-dots li,
#rebrand .product-preview .slick-dots li button {
  width: 12px;
  height: 12px;
}
#rebrand .product-preview .slick-dots li button::before {
  width: 12px;
  height: 12px;
  color: transparent;
  border: 1px solid var(--redesign-color-primary);
  border-radius: 50%;
  opacity: 1;
}
#rebrand .product-preview .product-variations {
  display: block;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .product-preview .size-variations,
#rebrand .product-preview .color-variations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .size-variations,
  #rebrand .product-preview .color-variations {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .color-variation label {
    width: 100%;
    justify-content: start;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .field {
    width: 100%;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .size-variations option {
    display: none;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .color-variations .select-box {
    width: 100%;
    padding: 10px 0;
  }
}
#rebrand .product-preview select,
#rebrand .product-preview .select-box {
  min-width: 166px;
  border-radius: 100px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview select,
  #rebrand .product-preview .select-box {
    display: none;
  }
}
#rebrand .product-preview .select-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border: 1px solid var(--color-neutral-mid);
  font-size: 14px;
  cursor: pointer;
}
#rebrand .product-preview .select-box.active .select-options {
  display: block;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .select-box.active .select-options {
    display: none;
  }
}
#rebrand .product-preview .select-box::before {
  position: absolute;
  top: 50%;
  right: 10px;
}
#rebrand .product-preview .selected {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
#rebrand .product-preview .select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid var(--redesign-color-silver);
  background-color: var(--color-white);
  display: none;
  z-index: 10;
}
#rebrand .product-preview .option {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
#rebrand .product-preview .option:hover {
  background-color: var(--redesign-color-smoke);
}
#rebrand .product-preview .color-box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid;
}
#rebrand .product-preview .color-name {
  display: inline-block;
  font-size: 14px;
  color: var(--color-black);
  white-space: nowrap;
}
#rebrand .product-preview .field-select::before,
#rebrand .product-preview .select-box::before {
  content: url("../../../images/select-arrow.svg");
  width: 10px;
  height: 15px;
  border-width: 0;
  transform: translateY(-50%);
}
#rebrand .product-preview .field-number > input {
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .product-preview .field-number > button {
  position: absolute;
  background-color: unset;
  border: 0;
  cursor: pointer;
}
#rebrand .product-preview .counter-minus {
  top: 50%;
  transform: translateX(-50%);
  left: 20px;
}
#rebrand .product-preview .counter-plus {
  top: 35%;
  transform: translateX(-35%);
  right: 10px;
}
#rebrand .product-preview .counter-container {
  margin-bottom: 8px;
}
#rebrand .product-preview .counter-container.counters {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 17px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .counter-container.counters {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-preview .counter-container {
    max-width: 375px;
    margin: 0 auto;
    margin-bottom: 12px;
  }
}
#rebrand .product-preview .counter-image {
  display: block;
  width: 27px;
  height: 18px;
  padding-top: 35px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .counter-image {
    padding-top: 20px;
    margin: 0 auto;
  }
}
#rebrand .product-preview .price-total {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}
#rebrand .product-preview .price-total span {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--redesign-color-black-md);
  text-transform: uppercase;
}
#rebrand .product-preview .price-total strong {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
}
#rebrand .product-preview .price-total strong > span {
  font-size: 20px;
}
#rebrand .product-preview .price-total .default-price {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview .price-total .default-price span {
  font-size: 20px;
  text-transform: lowercase;
}
#rebrand .product-preview .price-total .new-price {
  padding: 8px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border-radius: 4px;
}
#rebrand .product-preview .price-total .new-price span {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-white);
  text-transform: lowercase;
}
#rebrand .product-preview .price-total .old-price {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview .price-total .old-price::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 90%;
  height: 1px;
  background-color: var(--redesign-color-black-md);
  transform: rotate(-15deg);
}
#rebrand .product-preview .price-total .old-price span {
  font-size: 14px;
  text-transform: lowercase;
}
#rebrand .product-preview .buttons-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
#rebrand .product-preview .buttons-container .btns {
  width: 100%;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-preview .buttons-container .btns {
    max-width: 375px;
    margin: 0 auto;
  }
}
#rebrand .product-preview .buttons-container .btn-secondary,
#rebrand .product-preview .buttons-container .btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 100px;
}
#rebrand .product-preview .buttons-container .btn-secondary img,
#rebrand .product-preview .buttons-container .btn-primary img {
  width: 18px;
  height: 18px;
}
#rebrand .product-preview .buttons-container #buy-now-btn {
  border: 1px solid transparent;
}
#rebrand .product-preview .buttons-container #fast-delivery-btn {
  color: var(--redesign-color-primary);
  background-color: transparent;
  border: 1px solid var(--redesign-color-primary);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .buttons-container {
    grid-template-columns: 1fr;
    gap: 15px;
    margin-bottom: 24px;
  }
}
#rebrand .product-preview .btn-products {
  min-width: 194px;
  max-width: 328px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  background-color: var(--redesign-color-white-md);
  color: var(--redesign-color-primary);
  text-align: center;
  text-transform: uppercase;
}
#rebrand .product-preview .msg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  font-size: 14px;
  line-height: 16px;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview .msg img {
  width: 23px;
  height: 23px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .msg {
    margin-bottom: 0;
  }
}
#rebrand .product-preview .product-calc-utils {
  padding: 9px 0;
  margin-bottom: 8px;
  font-size: 12px;
  color: var(--color-gray-dk);
  background-color: var(--color-white-md);
  text-align: center;
}
#rebrand .product-preview .product-calc-info {
  margin-bottom: 40px;
  font-size: 12px;
  color: var(--color-gray-dk);
}
#rebrand .product-preview .counter-box-prices {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
#rebrand .product-preview .counter-box-prices .default-price,
#rebrand .product-preview .counter-box-prices .new-price {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}
#rebrand .product-preview .counter-box-prices .default-price span,
#rebrand .product-preview .counter-box-prices .new-price span {
  font-size: 14px;
}
#rebrand .product-preview .counter-box-prices .default-price {
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview .counter-box-prices .new-price {
  color: var(--redesign-color-primary);
}
#rebrand .product-preview .counter-box-prices .old-price {
  font-size: 14px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
#rebrand .product-preview .counter-box-prices .old-price span {
  position: relative;
  font-size: 12px;
}
#rebrand .product-preview .counter-box-prices .old-price span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  width: 70%;
  height: 1px;
  background-color: var(--redesign-color-black-md);
  transform: rotate(-15deg);
}
#rebrand .product-preview .size-variations-trigger {
  display: none;
  min-width: 166px;
  max-width: 100%;
  width: 100%;
  height: 40px;
  padding: 11px;
  color: var(--redesign-color-black-md);
  border: 1px solid var(--color-gray-dk);
  border-radius: 100px;
  background: var(--color-white);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .size-variations-trigger {
    display: block;
  }
}
#rebrand .product-preview .modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#rebrand .product-preview .modal__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px 0;
  background-color: var(--color-white);
  border-radius: 8px 8px 0 0;
}
#rebrand .product-preview .modal__title {
  margin-bottom: 24px;
  text-align: center;
}
#rebrand .product-preview .modal__options {
  padding: 0 10px;
}
#rebrand .product-preview .modal__options li {
  position: relative;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid var(--redesign-color-border);
  list-style: none;
  cursor: pointer;
}
#rebrand .product-preview .modal__options li.active::after {
  content: url("../../../images/filters-check.svg");
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
#rebrand .product-preview .modal__options li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
#rebrand .product-preview .modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview .products-locations {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
}

#rebrand .product-preview-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
#rebrand .product-preview-modal__container {
  position: relative;
  background-color: #fff;
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview-modal__container {
    padding: 12px;
  }
}
#rebrand .product-preview-modal__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview-modal__title {
    max-width: 211px;
    width: 100%;
    font-size: 16px;
  }
}
#rebrand .product-preview-modal__close {
  position: absolute;
  top: 24px;
  right: 24px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview-modal__close {
    top: 14px;
    right: 12px;
    width: 35px;
    height: 35px;
  }
}
#rebrand .product-preview-modal .slider-for {
  margin-bottom: 32px;
}
#rebrand .product-preview-modal .slick-slide img {
  margin: 0 auto;
  max-width: 542px;
  height: 417px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-preview-modal .slick-slide img {
    max-width: 304px;
    height: 278px;
  }
}
#rebrand .product-preview-modal .slick-slide:hover img {
  transform: scale3D(1.1, 1.1, 1.1);
  transform-style: preserve-3d;
}
#rebrand .product-preview-modal .slick-next,
#rebrand .product-preview-modal .slick-prev {
  width: 35px;
  height: 35px;
  top: 50%;
  z-index: 10;
}
#rebrand .product-preview-modal .slick-next::before,
#rebrand .product-preview-modal .slick-prev::before {
  content: url("../../../images/product-swiper-arrow.svg");
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  opacity: 1;
}
#rebrand .product-preview-modal .slick-next {
  right: 0;
  transform: translateY(-50%);
}
#rebrand .product-preview-modal .slick-prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
#rebrand .product-preview-modal .slider-nav {
  padding-top: 12px;
  border-top: 1px solid #dbdbdb;
}
#rebrand .product-preview-modal .slider-nav .slick-slide {
  width: fit-content !important;
}
#rebrand .product-preview-modal .slider-nav .slick-slide img {
  border-radius: 4px;
  border: 2px solid #dbdbdb;
  width: 85px;
  height: 85px;
}
#rebrand .product-preview-modal .slider-nav .slick-slide.slick-current img {
  border: 2px solid #99cf28;
}
#rebrand .product-preview-modal .slick-track {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

#rebrand .accent-images-swiper {
  padding-top: 20px;
  margin-bottom: 60px;
}
#rebrand .accent-images-swiper__container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-images-swiper__container {
    grid-template-columns: 1fr;
  }
}
#rebrand .accent-images-swiper__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-images-swiper__list {
    flex-direction: row;
    gap: 12px;
  }
}
#rebrand .accent-images-swiper__item {
  min-width: 200px;
  height: 257px;
}
#rebrand .accent-images-swiper__item img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  aspect-ratio: auto;
}
@media (--breakpoint-desktop-lg) {
  #rebrand .accent-images-swiper__item {
    min-width: 158px;
    width: 100%;
    height: auto;
  }
}
#rebrand .accent-images-swiper .swiper-container {
  max-width: 100%;
  width: 100%;
  max-height: 533px;
  height: auto;
  overflow: hidden;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-images-swiper .swiper-container {
    max-height: unset;
  }
}
#rebrand .accent-images-swiper .box-type-2 {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
#rebrand .accent-images-swiper .box-type-2 img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-images-swiper .box-type-2 img {
    height: 420px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .accent-images-swiper .box-type-2 {
    max-width: 100%;
  }
  #rebrand .accent-images-swiper .box-type-2 img {
    display: block;
    min-width: 328px;
    width: 100%;
  }
}
#rebrand .accent-images-swiper .swiper-pagination {
  position: absolute;
  bottom: 5%;
}
#rebrand .accent-images-swiper .swiper-pagination-bullet {
  border-color: #fff;
  opacity: 1;
}
#rebrand .accent-images-swiper .swiper-button-next,
#rebrand .accent-images-swiper .swiper-button-prev {
  width: 35px;
  height: 35px;
}
#rebrand .accent-images-swiper .swiper-button-next::after {
  content: url("../../../images/next-button.svg");
}
#rebrand .accent-images-swiper .swiper-button-prev::after {
  content: url("../../../images/prev-button.svg");
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-images-swiper {
    margin-bottom: 50px;
  }
}

/* ------------------------------------------------------------ *\
    #responsive
\* ------------------------------------------------------------ */
#rebrand .accent-products-popular__nav {
  position: relative;
  display: flex;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__nav {
    margin-bottom: 25px;
  }
}
#rebrand .accent-products-popular__title {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__title {
    display: block;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-products-popular .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
  background: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-basic {
    min-width: 243px;
  }
}
#rebrand .accent-products-popular .box-type-2 {
  padding: 19px 25px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-type-2 {
    padding: 12px 12px 0;
  }
  #rebrand .accent-products-popular .box-type-2 .picture {
    min-height: 195px;
    max-height: 200px;
    height: 100%;
  }
}
#rebrand .accent-products-popular .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .accent-products-popular .title {
  margin-bottom: 12px;
}
#rebrand .accent-products-popular .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .accent-products-popular .box-price-wrap {
  text-align: left;
}
#rebrand .accent-products-popular .box-price {
  vertical-align: unset;
}
#rebrand .accent-products-popular .swiper-button-prev,
#rebrand .accent-products-popular .swiper-button-next {
  top: 0;
  transform: translateY(-100%);
}
#rebrand .accent-products-popular .swiper-button-prev::after,
#rebrand .accent-products-popular .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .swiper-button-prev,
  #rebrand .accent-products-popular .swiper-button-next {
    width: auto;
    height: auto;
    top: unset;
    margin-top: 0;
    transform: none;
  }
  #rebrand .accent-products-popular .swiper-button-prev::after,
  #rebrand .accent-products-popular .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-popular .swiper-button-prev {
  right: 50px;
  left: auto;
}

#rebrand .accent-promo-offers {
  margin-bottom: 80px;
}
#rebrand .accent-promo-offers__top {
  position: relative;
}
#rebrand .accent-promo-offers__title {
  margin-bottom: 37px;
  font-size: 28px;
  font-weight: 600;
  color: #251f20;
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-promo-offers__title {
    font-size: 24px;
    text-align: left;
  }
}
#rebrand .accent-promo-offers__item-image {
  max-width: 420px;
  width: 100%;
  height: 420px;
}
#rebrand .accent-promo-offers__item-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-promo-offers__item-image {
    max-width: 243px;
    height: 243px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-promo-offers .wrapper {
    padding-right: 0;
  }
}
#rebrand .accent-promo-offers .swiper-wrapper {
  width: max-content;
}
#rebrand .accent-promo-offers .swiper-button-prev,
#rebrand .accent-promo-offers .swiper-button-next {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-promo-offers .swiper-button-prev,
  #rebrand .accent-promo-offers .swiper-button-next {
    display: flex;
  }
  #rebrand .accent-promo-offers .swiper-button-prev::after,
  #rebrand .accent-promo-offers .swiper-button-next::after {
    font-size: 20px;
    font-weight: 600;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-promo-offers .swiper-button-prev {
    left: auto;
    right: 50px;
  }
}
#rebrand .accent-promo-offers .box-basic {
  width: 100%;
}
#rebrand .accent-promo-offers .box-type-2 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

#rebrand .footer {
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  line-height: 1.3;
  background: #f7f7f7;
  color: #939393;
}
#rebrand .footer__top {
  position: relative;
  padding: 25px 15px;
}
#rebrand .footer__top-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media (--breakpoint-desktop) {
  #rebrand .footer__top-container {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .footer__top {
    padding: 40px 0 0;
  }
}
#rebrand .footer__image > img {
  display: block;
  max-width: 150px;
  width: 100%;
  margin-bottom: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .footer__image {
    margin-bottom: 40px;
  }
}
#rebrand .footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#rebrand .footer__contacts a:hover {
  text-decoration: none;
}
#rebrand .footer__contacts-item {
  display: flex;
  align-items: end;
  gap: 8px;
  font-family: var(--font-rebrand);
  font-size: 14px;
  line-height: 16px;
  color: #251f20;
}
#rebrand .footer__contacts-item > svg {
  display: block;
  width: 20px;
  height: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .footer__contacts {
    flex-direction: row;
    padding: 0;
    max-width: 100%;
  }
  #rebrand .footer__contacts a {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 15px;
  }
  #rebrand .footer__contacts > svg {
    width: 25px;
    height: 25px;
  }
}
#rebrand .footer__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 30px;
}
#rebrand .footer__list-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  border: 0;
  background: transparent;
}
#rebrand .footer__list-button.active {
  margin-bottom: 12px;
}
#rebrand .footer__list-button.active svg {
  transform: rotate(180deg);
}
#rebrand .footer__list-button svg {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .footer__list-button {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 0;
    cursor: pointer;
  }
  #rebrand .footer__list-button svg {
    display: block;
  }
}
#rebrand .footer__list-item {
  font-family: var(--font-rebrand);
  font-feature-settings: "aalt" 1;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .footer__list-item {
    padding-bottom: 20px;
  }
  #rebrand .footer__list-item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: #dbdbdb;
  }
}
#rebrand .footer__list-title {
  font-family: var(--font-rebrand);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  color: #251f20;
  text-transform: uppercase;
  font-weight: normal;
}
#rebrand .footer__list-links {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding-left: 0;
}
#rebrand .footer__list-links li {
  padding-bottom: 2px;
}
#rebrand .footer__list-links a {
  font-size: 14px;
  line-height: 16px;
  color: #251f20;
}
#rebrand .footer__list-links a:hover {
  padding-left: 5px;
  color: var(--color-primary);
  text-decoration: none;
}
#rebrand .footer__list-links button {
  font-size: 14px;
  line-height: 16px;
  border: 0;
  color: #251f20;
  background-color: transparent;
}
#rebrand .footer__list-links button:hover {
  padding-left: 5px;
  color: var(--color-primary);
  text-decoration: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .footer__list-links {
    display: none;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .footer__list {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
}
#rebrand .footer__bottom::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
  left: 0;
  right: 0;
}
#rebrand .footer__bottom .copy {
  float: left;
  line-height: 24px;
}
#rebrand .footer__bottom .trademark {
  float: right;
  display: flex;
  align-items: center;
}
#rebrand .footer__bottom .trademark .icon-svg {
  display: inline-block;
  margin-left: 10px;
  height: 24px;
  width: 80px;
  fill: #4b9cd7;
}
#rebrand .footer__bottom .copyrights {
  padding: 15px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .footer__bottom .copyrights {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .footer__bottom .copyrights {
    padding: 20px 0;
  }
}

#rebrand .breadcrumbs {
  position: relative;
}
#rebrand .breadcrumbs.secondary-bg {
  background-color: var(--color-white-md);
}
#rebrand .breadcrumbs.search .breadcrumbs__item a {
  text-transform: initial;
}
#rebrand .breadcrumbs::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--redesign-color-border);
  bottom: 0;
  width: 100%;
  height: 1px;
}
#rebrand .breadcrumbs__list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
  list-style: none;
}
#rebrand .breadcrumbs__item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: var(--color-gray-dk);
}
#rebrand .breadcrumbs__item a {
  color: var(--redesign-color-black-md);
  text-decoration: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .breadcrumbs__item {
    display: none;
  }
  #rebrand .breadcrumbs__item:nth-last-child(2) {
    display: flex;
  }
  #rebrand .breadcrumbs__item svg {
    transform: rotate(180deg);
  }
}

#rebrand .product-categories-section {
  padding-bottom: 100px;
}
#rebrand .product-categories-section__head {
  margin-bottom: 45px;
  background-color: var(--color-white-md);
}
#rebrand .product-categories-section__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}
#rebrand .product-categories-section__content-title {
  font-size: 35px;
  color: var(--redesign-color-black-md);
}
#rebrand .product-categories-section__content-title span {
  color: var(--redesign-color-primary);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section__content {
    flex-direction: column;
    align-items: normal;
    gap: 24px;
  }
  #rebrand .product-categories-section__content-image {
    display: block;
    max-width: 305px;
    height: 110px;
    margin: 0 auto;
    transform: scaleX(-1);
  }
}
#rebrand .product-categories-section__container {
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section__container {
    grid-template-columns: 1fr;
  }
}
#rebrand .product-categories-section__sidebar {
  display: block;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section__sidebar {
    display: none;
  }
  #rebrand .product-categories-section__sidebar.active {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
}
#rebrand .product-categories-section__elements {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (--breakpoint-desktop) {
  #rebrand .product-categories-section__elements {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}
#rebrand .product-categories-section__box {
  position: relative;
  height: fit-content;
  color: var(--color-white);
}
#rebrand .product-categories-section__box .picture img {
  border-radius: 10px;
}
#rebrand .product-categories-section__box-info {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section__box-info {
    top: 12px;
    left: 12px;
    font-size: 14px;
  }
}
#rebrand .product-categories-section .categories-menu {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section .categories-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 12px 0;
    color: var(--redesign-color-black-md);
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid var(--redesign-color-border);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
#rebrand .product-categories-section__content-image svg {
  width: 328px;
  height: 107px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories-section {
    padding-bottom: 50px;
  }
}

#rebrand .categories-sidebar-nav .hidden {
  display: none;
}
#rebrand .categories-sidebar-nav .visible {
  display: block;
}
#rebrand .categories-sidebar-nav__title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
  text-transform: uppercase;
}
@media (--breakpoint-desktop) {
  #rebrand .categories-sidebar-nav__title {
    display: none;
  }
}
#rebrand .categories-sidebar-nav__body .close {
  display: none;
}
@media (--breakpoint-desktop) {
  #rebrand .categories-sidebar-nav__body {
    padding: 20px 16px;
  }
  #rebrand .categories-sidebar-nav__body .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 16px;
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
#rebrand .categories-sidebar-nav__filters .category,
#rebrand .categories-sidebar-nav__filters .subcategory {
  font-size: 16px;
  line-height: 1.75;
  vertical-align: middle;
  cursor: pointer;
}
#rebrand .categories-sidebar-nav__filters .category a,
#rebrand .categories-sidebar-nav__filters .subcategory a {
  color: var(--color-gray-dk);
}
#rebrand .categories-sidebar-nav__filters .subcategories__name {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 6px;
  padding: 10px;
  margin-bottom: 18px;
  border: 1px solid var(--redesign-color-border);
  border-radius: 3px;
}
#rebrand .categories-sidebar-nav__filters .subcategories__name a {
  display: flex;
  gap: 8px;
  font-weight: 400;
  background-color: transparent;
  border: 0;
  color: var(--color-gray-dk);
  cursor: pointer;
}
#rebrand .categories-sidebar-nav__filters .subcategories__name a::before {
  content: url("../../../images/back-btn.svg");
  display: inline-block;
  width: 11px;
  height: 11px;
}
#rebrand .categories-sidebar-nav__filters .subcategories__name span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-desktop) {
  #rebrand .categories-sidebar-nav__filters .subcategories__name {
    gap: 16px;
    padding: 0 0 18px;
    border: 0;
    border-bottom: 1px solid var(--redesign-color-border);
  }
}
#rebrand .categories-sidebar-nav__head {
  font-size: 18px;
  line-height: 1.2em;
  text-align: center;
  color: var(--color-black);
}
@media (--breakpoint-desktop-up) {
  #rebrand .categories-sidebar-nav__head {
    display: none;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .categories-sidebar-nav {
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
    border-radius: 15px 15px 0 0;
  }
}

/* ------------------------------------------------------------ *\
    #products bought together
\* ------------------------------------------------------------ */
#rebrand .accent-products-popular__nav {
  position: relative;
  display: flex;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__nav {
    margin-bottom: 25px;
  }
}
#rebrand .accent-products-popular__title {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__title {
    display: block;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-products-popular .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
  background: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-basic {
    min-width: 243px;
  }
}
#rebrand .accent-products-popular .box-type-2 {
  padding: 19px 25px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-type-2 {
    padding: 12px 12px 0;
  }
  #rebrand .accent-products-popular .box-type-2 .picture {
    min-height: 195px;
    max-height: 200px;
    height: 100%;
  }
}
#rebrand .accent-products-popular .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .accent-products-popular .title {
  margin-bottom: 12px;
}
#rebrand .accent-products-popular .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .accent-products-popular .box-price-wrap {
  text-align: left;
}
#rebrand .accent-products-popular .box-price {
  vertical-align: unset;
}
#rebrand .accent-products-popular .swiper-button-prev,
#rebrand .accent-products-popular .swiper-button-next {
  top: 0;
  transform: translateY(-100%);
}
#rebrand .accent-products-popular .swiper-button-prev::after,
#rebrand .accent-products-popular .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .swiper-button-prev,
  #rebrand .accent-products-popular .swiper-button-next {
    width: auto;
    height: auto;
    top: unset;
    margin-top: 0;
    transform: none;
  }
  #rebrand .accent-products-popular .swiper-button-prev::after,
  #rebrand .accent-products-popular .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-popular .swiper-button-prev {
  right: 50px;
  left: auto;
}

#rebrand .products-bought-together {
  position: relative;
  padding: 50px 0;
  background-color: var(--color-white-md);
  margin-bottom: 80px;
}
#rebrand .products-bought-together__title {
  margin-bottom: 50px;
  font-size: 28px;
  font-weight: 600;
  line-height: 26px;
  color: var(--redesign-color-black-md);
  text-align: center;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together__title {
    margin-bottom: 25px;
    font-size: 24px;
  }
}
#rebrand .products-bought-together__boxes-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  height: fit-content;
  padding-right: 50px;
  border-right: 1px solid var(--redesign-color-border);
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together__boxes-container {
    padding: 0 0 20px;
    gap: 12px;
    border-right: 0;
    border-bottom: 1px solid var(--redesign-color-border);
  }
}
#rebrand .products-bought-together__info {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (--breakpoint-desktop) {
  #rebrand .products-bought-together__info {
    flex-direction: row-reverse;
  }
}
#rebrand .products-bought-together .boxes-related {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .boxes-related {
    grid-template-columns: 1fr;
  }
}
#rebrand .products-bought-together .box-related {
  position: relative;
  display: grid;
  grid-template-columns: 20px 105px auto;
  align-items: center;
  gap: 20px;
  max-width: 774px;
  width: 100%;
  padding: 12px 20px;
  margin: 0;
  border-radius: 8px;
}
#rebrand .products-bought-together .box-related::after {
  content: url("../../../images/add.svg");
  position: absolute;
  bottom: -30px;
  left: 50%;
}
#rebrand .products-bought-together .box-related:last-child::after {
  content: none;
}
#rebrand .products-bought-together .box-related.checked {
  border: 1px solid var(--redesign-color-primary);
}
#rebrand .products-bought-together .box-related.checked .picture img {
  border: 1px solid var(--redesign-color-primary);
}
#rebrand .products-bought-together .box-related .picture {
  margin-bottom: 0;
}
#rebrand .products-bought-together .box-related .picture img {
  height: 81px;
  border-radius: 4px;
  border: 1px solid var(--redesign-color-border);
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .box-related {
    padding: 12px;
    gap: 12px;
    max-width: 100%;
  }
  #rebrand .products-bought-together .box-related::after {
    content: none;
  }
}
#rebrand .products-bought-together .added-products {
  margin: 0 0 0 50px;
  text-align: left;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .added-products {
    padding: 30px 0 0;
    margin: 0;
    text-align: center;
  }
}
#rebrand .products-bought-together .added-products .box-added-products {
  width: 100%;
  text-align: left;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .added-products .box-added-products {
    text-align: center;
  }
}
#rebrand .products-bought-together .added-title {
  max-width: 100%;
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .added-title {
    margin-bottom: 12px;
  }
}
#rebrand .products-bought-together .total-price {
  font-size: 28px;
  font-weight: 700;
  line-height: 26px;
  color: var(--redesign-color-black-md);
}
#rebrand .products-bought-together .total-price span {
  font-size: 24px;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .total-price {
    margin-bottom: 24px;
    font-size: 24px;
  }
  #rebrand .products-bought-together .total-price span {
    font-size: 20px;
  }
}
#rebrand .products-bought-together .related-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (--breakpoint-desktop) {
  #rebrand .products-bought-together .related-content {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
}
#rebrand .products-bought-together .related-content .title {
  max-width: 255px;
  height: 100%;
  margin-bottom: 0;
  font-size: 16px;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .related-content .title {
    font-size: 14px;
  }
}
#rebrand .products-bought-together .related-price {
  font-size: 18px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
}
#rebrand .products-bought-together .related-price span {
  font-size: 14px;
}
#rebrand .products-bought-together .btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 252px;
  width: 100%;
  height: 100%;
  gap: 10px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}
#rebrand .products-bought-together .btn-primary img {
  display: block;
  width: 18px;
  height: 18px;
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together .btn-primary {
    max-width: 328px;
    margin: 0 auto;
  }
}
#rebrand .products-bought-together .old-price {
  position: relative;
}
#rebrand .products-bought-together .old-price::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 90%;
  height: 1px;
  background-color: var(--redesign-color-black-md);
  transform: rotate(-15deg);
}
@media (--breakpoint-tablet) {
  #rebrand .products-bought-together {
    padding: 30px 0;
    margin-bottom: 50px;
  }
}

#rebrand .product-info__list {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  margin-bottom: 50px;
}
#rebrand .product-info__list.active .product-info__item {
  display: block;
}
#rebrand .product-info__list.active .product-info__button::after {
  transform: translateY(-50%);
  transition: transform 0.3s ease-out;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-info__list {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
}
@media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
  #rebrand .product-info__list {
    grid-template-columns: 328px auto;
    gap: 23px;
  }
}
@media (--breakpoint-tablet-up) and (--breakpoint-desktop) {
  #rebrand .product-info__list {
    grid-template-columns: 518px auto;
    gap: 23px;
  }
}
#rebrand .product-info__button {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
  background-color: transparent;
  border: 0;
  text-align: left;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-info__button {
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
  }
  #rebrand .product-info__button::after {
    content: url("../../../images/arrow.svg");
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s ease-in;
  }
}
#rebrand .product-info__item {
  display: block;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-info__item {
    display: none;
  }
}
#rebrand .product-info__description {
  margin-bottom: 12px;
  font-size: 14px;
}
#rebrand .product-info__description:last-of-type {
  margin-bottom: 0;
}
#rebrand .product-info__features {
  list-style: none;
}
#rebrand .product-info__features-item {
  position: relative;
  padding-left: 10px;
}
#rebrand .product-info__features-item span {
  display: block;
  padding-left: 10px;
  font-size: 14px;
}
#rebrand .product-info__features-item::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--redesign-color-black-md);
}
#rebrand .product-info__content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title link" "address link";
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .product-info__content:first-of-type {
  padding-top: 0;
}
#rebrand .product-info__content-title {
  grid-area: title;
  font-size: 16px;
}
#rebrand .product-info__content-address {
  grid-area: address;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-info__content-address {
    max-width: 276px;
  }
}
#rebrand .product-info__content-link {
  grid-area: link;
  justify-self: end;
}
#rebrand .product-info__content-link svg {
  fill: var(--color-gray-dk);
}
#rebrand .product-info__content-link svg:hover {
  fill: var(--redesign-color-primary);
}
#rebrand .product-info .table-basic tr:nth-of-type(odd) {
  background-color: var(--color-white-md);
}
#rebrand .product-info .table-basic td {
  border: 0;
  text-align: right;
}
#rebrand .product-info .table-basic td:first-child {
  text-align: left;
}
#rebrand .product-info .table-basic tr:hover td {
  background-color: transparent;
}
#rebrand .product-info .box-type-6 {
  margin: 0;
}

#rebrand .accent-banner {
  position: relative;
  background-image: url("../../../images/banner-background-desktop.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#rebrand .accent-banner__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner__container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-items: start;
    gap: 20px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner__content {
    grid-column: 1;
    grid-row: 1;
  }
}
#rebrand .accent-banner__title {
  max-width: 367px;
  width: 100%;
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner__title {
    max-width: 163px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .accent-banner__title {
    max-width: 200px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-banner__button {
  display: inline-block;
  padding: 12px 40px;
  background-color: #fff;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-banner__button {
    padding: 12px 28px;
  }
}
#rebrand .accent-banner__image {
  margin: 0 auto;
}
#rebrand .accent-banner__image img {
  display: block;
  max-width: 380px;
  width: 100%;
  height: 262px;
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner__image {
    grid-column: 1/span 2;
    grid-row: 2;
  }
}
#rebrand .accent-banner__apps {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
}
#rebrand .accent-banner__apps-item {
  max-width: 136px;
}
#rebrand .accent-banner__apps-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner__apps {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .accent-banner__apps img {
    max-width: 122px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-banner {
    padding-top: 44px;
    background-image: url("../../../images/banner-background-mobile.png");
  }
}

#rebrand .accent-news__title {
  margin-bottom: 40px;
  color: var(--redesign-color-black-md);
  font-size: 28px;
  text-align: center;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news__title {
    margin-bottom: 24px;
    font-size: 24px;
    text-align: left;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news__container {
    position: relative;
  }
}
#rebrand .accent-news__box {
  display: grid;
  grid-template-columns: 1fr auto;
  max-width: 420px;
  width: 100%;
  background-color: var(--color-white-md);
  border-radius: 10px;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news__box {
    display: flex;
    flex-direction: column-reverse;
  }
}
#rebrand .accent-news__content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
#rebrand .accent-news__content-title {
  margin-bottom: 6px;
  font-size: 16px;
  color: var(--redesign-color-black-md);
}
#rebrand .accent-news__content-description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 184px;
  min-height: 42px;
  margin-bottom: 14px;
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .accent-news__content-link {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: auto;
  text-transform: uppercase;
}
@media (--breakpoint-tablet) {
  #rebrand .accent-news__content {
    min-height: 173px;
    height: 100%;
    padding: 16px;
  }
}
#rebrand .accent-news__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news__image img {
    border-radius: 10px 10px 0 0;
  }
}
#rebrand .accent-news .swiper-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news .swiper-wrapper {
    flex-wrap: nowrap;
  }
}
#rebrand .accent-news .swiper-button-next,
#rebrand .accent-news .swiper-button-prev {
  display: none;
}
@media (--breakpoint-desktop) {
  #rebrand .accent-news .swiper-button-next,
  #rebrand .accent-news .swiper-button-prev {
    display: block;
  }
}
#rebrand .accent-news .swiper-button-prev {
  right: 50px;
  left: auto;
}
#rebrand .accent-news .swiper-button-next::after,
#rebrand .accent-news .swiper-button-prev::after {
  font-size: 20px;
  font-weight: 600;
}

#rebrand .accent-products-popular__nav {
  position: relative;
  display: flex;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__nav {
    margin-bottom: 25px;
  }
}
#rebrand .accent-products-popular__title {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular__title {
    display: block;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-products-popular .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
  background: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-basic {
    min-width: 243px;
  }
}
#rebrand .accent-products-popular .box-type-2 {
  padding: 19px 25px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .box-type-2 {
    padding: 12px 12px 0;
  }
  #rebrand .accent-products-popular .box-type-2 .picture {
    min-height: 195px;
    max-height: 200px;
    height: 100%;
  }
}
#rebrand .accent-products-popular .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .accent-products-popular .title {
  margin-bottom: 12px;
}
#rebrand .accent-products-popular .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .accent-products-popular .box-price-wrap {
  text-align: left;
}
#rebrand .accent-products-popular .box-price {
  vertical-align: unset;
}
#rebrand .accent-products-popular .swiper-button-prev,
#rebrand .accent-products-popular .swiper-button-next {
  top: 0;
  transform: translateY(-100%);
}
#rebrand .accent-products-popular .swiper-button-prev::after,
#rebrand .accent-products-popular .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-popular .swiper-button-prev,
  #rebrand .accent-products-popular .swiper-button-next {
    width: auto;
    height: auto;
    top: unset;
    margin-top: 0;
    transform: none;
  }
  #rebrand .accent-products-popular .swiper-button-prev::after,
  #rebrand .accent-products-popular .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-popular .swiper-button-prev {
  right: 50px;
  left: auto;
}

/* ------------------------------------------------------------ *\
    #accent-products-seen
\* ------------------------------------------------------------ */
#rebrand .accent-products-seen__nav {
  position: relative;
  display: flex;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen__nav {
    margin-bottom: 25px;
  }
}
#rebrand .accent-products-seen__title {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen__title {
    display: block;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-products-seen .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
  background: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen .box-basic {
    min-width: 243px;
  }
}
#rebrand .accent-products-seen .box-type-2 {
  padding: 19px 25px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen .box-type-2 {
    padding: 12px 12px 0;
  }
  #rebrand .accent-products-seen .box-type-2 .picture {
    min-height: 195px;
    max-height: 200px;
    height: 100%;
  }
}
#rebrand .accent-products-seen .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .accent-products-seen .title {
  margin-bottom: 12px;
}
#rebrand .accent-products-seen .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .accent-products-seen .box-price-wrap {
  text-align: left;
}
#rebrand .accent-products-seen .box-price {
  vertical-align: unset;
}
#rebrand .accent-products-seen .swiper-button-prev,
#rebrand .accent-products-seen .swiper-button-next {
  top: 0;
  transform: translateY(-100%);
}
#rebrand .accent-products-seen .swiper-button-prev::after,
#rebrand .accent-products-seen .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-seen .swiper-button-prev,
  #rebrand .accent-products-seen .swiper-button-next {
    width: auto;
    height: auto;
    top: unset;
    margin-top: 0;
    transform: none;
  }
  #rebrand .accent-products-seen .swiper-button-prev::after,
  #rebrand .accent-products-seen .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-seen .swiper-button-prev {
  right: 50px;
  left: auto;
}

/* ------------------------------------------------------------ *\
    #accent-products-promo
\* ------------------------------------------------------------ */
#rebrand .accent-products-promo {
  position: relative;
}
#rebrand .accent-products-promo__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 35px;
}
#rebrand .accent-products-promo__content h2 {
  font-size: 28px;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo__content h2 {
    font-size: 24px;
    max-width: 94%;
  }
}
#rebrand .accent-products-promo .wrapper {
  position: relative;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .wrapper {
    right: 0;
  }
}
#rebrand .accent-products-promo .swiper-wrapper {
  width: max-content;
}
#rebrand .accent-products-promo .swiper-wrapper--fullwidth {
  width: 100%;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .boxes {
    margin-bottom: 30px;
  }
}
#rebrand .accent-products-promo .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
  background: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .box-basic {
    min-width: 243px;
  }
}
#rebrand .accent-products-promo .box-type-2 {
  padding: 19px 25px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .box-type-2 {
    padding: 12px 12px 0;
  }
  #rebrand .accent-products-promo .box-type-2 .picture {
    min-height: 195px;
    max-height: 200px;
    height: 100%;
  }
}
#rebrand .accent-products-promo .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .accent-products-promo .title {
  margin-bottom: 12px;
}
#rebrand .accent-products-promo .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .accent-products-promo .box-price-wrap {
  text-align: left;
}
#rebrand .accent-products-promo .box-price {
  vertical-align: unset;
}
#rebrand .accent-products-promo .swiper-button-prev,
#rebrand .accent-products-promo .swiper-button-next {
  top: 5%;
}
#rebrand .accent-products-promo .swiper-button-prev::after,
#rebrand .accent-products-promo .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .swiper-button-prev,
  #rebrand .accent-products-promo .swiper-button-next {
    top: 3%;
  }
  #rebrand .accent-products-promo .swiper-button-prev::after,
  #rebrand .accent-products-promo .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-promo .swiper-button-prev {
  right: 50px;
  left: auto;
}
#rebrand .accent-products-promo .brochure-button {
  padding: 12px 20px;
  font-size: 14px;
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border: none;
  border-radius: 100px;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .brochure-button {
    width: 100%;
  }
}
#rebrand .accent-products-promo .brochure-wrapper.mobile {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-promo .brochure-wrapper.mobile {
    display: block;
    padding-right: 10px;
  }
  #rebrand .accent-products-promo .brochure-wrapper.desktop {
    display: none;
  }
}

/* ------------------------------------------------------------ *\
    #accent-products
\* ------------------------------------------------------------ */
#rebrand .shopping-cart {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1000;
}
#rebrand .shopping-cart.active {
  display: block;
}
#rebrand .shopping-cart__container {
  position: absolute;
  max-width: 529px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px 0 0 10px;
  background-color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .shopping-cart__container {
    height: 90%;
    top: unset;
    border-radius: 15px 15px 0 0;
  }
}
#rebrand .shopping-cart__button {
  max-width: 14px;
  padding: 0;
}
#rebrand .shopping-cart__head {
  padding: 16px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .shopping-cart__message {
  font-size: 12px;
  color: var(--color-gray-dk);
}
#rebrand .shopping-cart__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#rebrand .shopping-cart__title {
  font-size: 24px;
  font-weight: 600;
}
#rebrand .shopping-cart__list {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#rebrand .shopping-cart::-webkit-scrollbar {
  display: none;
}
#rebrand .shopping-cart__bottom {
  padding: 25px 16px;
  margin-top: auto;
  border-top: 1px solid var(--redesign-color-border);
}
#rebrand .shopping-cart__bottom-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
#rebrand .shopping-cart__bottom-price h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}
#rebrand .shopping-cart__bottom-price p {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--redesign-color-black-md);
}
#rebrand .shopping-cart__bottom-price p span {
  font-size: 16px;
}
#rebrand .shopping-cart__bottom-link {
  display: block;
  width: 100%;
  padding: 12px 0;
  color: var(--color-white);
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

#rebrand .product-counter {
  position: relative;
  max-width: 100%;
  border: 1px solid var(--redesign-color-border);
  border-radius: 100px;
}
#rebrand .product-counter--secondary .field-number {
  width: 160px;
}
#rebrand .product-counter--secondary .field-number > input {
  height: 30px;
}
#rebrand .product-counter .field-number {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 5px;
}
#rebrand .product-counter .field-number > input {
  border: 0;
  padding: 0;
  text-align: right;
}
#rebrand .product-counter .field-number > button {
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
#rebrand .product-counter .field-number .postfix {
  position: relative;
  top: unset;
  right: unset;
  transform: none;
  font-size: 12px;
  line-height: 13px;
  color: var(--color-gray-dk);
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-counter .field-number {
    width: 100%;
  }
}
#rebrand .product-counter .counter-minus {
  top: 50%;
  transform: translateX(-50%);
  left: 20px;
}
#rebrand .product-counter .counter-plus {
  top: 30%;
  transform: translateX(-35%);
  right: 10px;
}
#rebrand .product-counter .counter-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 17px;
  margin-bottom: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-counter .counter-container {
    grid-template-columns: 1fr;
    gap: 0;
    margin-bottom: 12px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-counter {
    max-width: 100%;
  }
}

#rebrand .shopping-cart-item {
  position: relative;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .shopping-cart-item.active .shopping-cart-item__wrapper {
  opacity: 0.3;
}
#rebrand .shopping-cart-item.active .shopping-cart-message {
  display: block;
}
#rebrand .shopping-cart-item__wrapper {
  display: flex;
  align-items: start;
  gap: 16px;
  padding: 16px;
}
#rebrand .shopping-cart-item__image {
  flex-basis: 20%;
}
#rebrand .shopping-cart-item__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
#rebrand .shopping-cart-item__container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content content" "price counter";
  align-items: center;
  min-height: 80px;
}
#rebrand .shopping-cart-item__button {
  max-width: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#rebrand .shopping-cart-item__content {
  grid-area: content;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .shopping-cart-item__content {
    margin-bottom: 18px;
  }
}
#rebrand .shopping-cart-item__title {
  max-width: 211px;
  width: 100%;
  font-size: 14px;
  line-height: normal;
  color: var(--redesign-color-black-md);
  text-align: left;
  text-transform: uppercase;
}
#rebrand .shopping-cart-item__price {
  grid-area: price;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  color: var(--redesign-color-black-md);
  text-align: left;
}
#rebrand .shopping-cart-item__price span {
  font-size: 18px;
}
#rebrand .shopping-cart-item__counter {
  grid-area: counter;
}
#rebrand .shopping-cart-item__counter .form-controls .field-number {
  display: flex;
  align-items: center;
  justify-content: right;
}
#rebrand .shopping-cart-item__counter .form-controls input {
  max-width: 50px;
  height: auto;
  padding: 0;
  border: none;
}
#rebrand .shopping-cart-item__counter .form-controls .postfix {
  position: relative;
  top: unset;
  right: unset;
  transform: none;
  font-size: 12px;
  line-height: 13px;
  color: var(--color-gray-dk);
  text-transform: uppercase;
}

#rebrand .shopping-cart-message {
  display: none;
  position: relative;
  background-color: #ffe8e5;
}
#rebrand .shopping-cart-message__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#rebrand .shopping-cart-message__content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
}
#rebrand .shopping-cart-message__info {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: var(--redesign-color-red);
}
#rebrand .shopping-cart-message__button {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  color: var(--redesign-color-red);
  text-transform: uppercase;
}
#rebrand .shopping-cart-message__loading {
  width: 100%;
  height: 3px;
  background-color: var(--redesign-color-border);
  position: relative;
  overflow: hidden;
}
#rebrand .shopping-cart-message__loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--redesign-color-red);
  animation: loading-line 5s linear infinite;
}
#rebrand .shopping-cart-message .btn {
  width: auto;
}
@keyframes loading-line {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

#rebrand .cart {
  position: relative;
  padding: 20px 0 80px;
  background-color: var(--color-white-md);
  border-bottom: 1px solid var(--redesign-color-border);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart .wrapper {
    padding: 0;
  }
}
#rebrand .cart__title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart__title {
    padding: 0 10px;
  }
}
#rebrand .cart__container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}
@media (--breakpoint-desktop) {
  #rebrand .cart__container {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}
#rebrand .cart__products {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 20px;
  border-right: 1px solid var(--redesign-color-border);
}
@media (--breakpoint-desktop) {
  #rebrand .cart__products {
    padding-right: 0;
    border-right: none;
  }
}
#rebrand .cart__summary-list {
  margin-bottom: 25px;
}
#rebrand .cart__summary-item, #rebrand .cart__summary-discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  color: var(--redesign-color-black-md);
}
#rebrand .cart__summary-item p, #rebrand .cart__summary-discount p {
  font-size: 16px;
  font-weight: 700;
}
#rebrand .cart__summary-item span, #rebrand .cart__summary-discount span {
  font-size: 20px;
  font-weight: 700;
}
#rebrand .cart__summary-discount {
  padding-top: 22px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .cart__summary-button {
  max-width: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#rebrand .cart__summary-select {
  padding: 22px 0;
  border-top: 1px solid var(--redesign-color-border);
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .cart__summary-select.active h3 {
  margin-bottom: 16px;
}
#rebrand .cart__summary-select.active h3::after {
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s ease-in;
}
#rebrand .cart__summary-select.active .cart__summary-form {
  display: block;
}
#rebrand .cart__summary-select h3 {
  position: relative;
  cursor: pointer;
}
#rebrand .cart__summary-select h3::after {
  content: url("../../../images/arrow-down.svg");
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in;
}
#rebrand .cart__summary-form {
  display: none;
}
#rebrand .cart__summary-form form {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart__summary-form form {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}
#rebrand .cart__summary-form button {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  color: var(--color-white);
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  text-transform: uppercase;
}
#rebrand .cart__summary-form button.filled {
  background-color: var(--color-gray-dk);
}
#rebrand .cart__summary-form .field input {
  max-width: 279px;
  width: 100%;
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .cart__summary-form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart__summary-form .field input {
    max-width: 100%;
  }
}
#rebrand .cart__summary-form .message {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--redesign-color-primary);
}
#rebrand .cart__summary-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
  color: var(--redesign-color-black-md);
}
#rebrand .cart__summary-price p {
  font-size: 20px;
  font-weight: 700;
}
#rebrand .cart__summary-price span {
  font-size: 24px;
  font-weight: 700;
}
#rebrand .cart__summary-content {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
}
#rebrand .cart__summary-content h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: var(--redesign-color-black-md);
}
#rebrand .cart__summary-content button {
  position: relative;
}
#rebrand .cart__summary-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#rebrand .cart__summary-buttons .complete,
#rebrand .cart__summary-buttons .continue {
  display: block;
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  border-radius: 100px;
  text-align: center;
  text-transform: uppercase;
}
#rebrand .cart__summary-buttons .complete {
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border: 1px solid transparent;
}
#rebrand .cart__summary-buttons .continue {
  color: var(--redesign-color-primary);
  border: 1px solid var(--redesign-color-primary);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart__summary {
    padding: 0 10px;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .cart__summary {
    max-width: 861px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart {
    padding-bottom: 50px;
  }
}

#rebrand .cart-item {
  position: relative;
  max-width: 861px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-white);
}
#rebrand .cart-item.hidden {
  display: none;
}
#rebrand .cart-item__wrapper {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 25px;
  padding: 20px;
}
#rebrand .cart-item__wrapper.counters .cart-item__body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "variations variations" "counter counter" "calc ." "info price";
  gap: 8px;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__wrapper.counters .cart-item__body {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "variations variations" "counter price";
    gap: 0;
    align-items: end;
    margin-top: auto;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__wrapper {
    grid-template-columns: 100px auto;
    grid-template-rows: auto auto;
    grid-template-areas: "elements content" "elements content";
    padding: 12px 16px;
    gap: 0;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__elements {
    grid-area: elements;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 16px;
  }
  #rebrand .cart-item__elements::after {
    content: "";
    position: absolute;
    top: -12px;
    right: 0;
    width: 1px;
    height: calc(100% + 24px);
    background-color: var(--redesign-color-border);
  }
}
#rebrand .cart-item__image {
  max-width: 150px;
  width: 100%;
}
#rebrand .cart-item__image img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__image {
    max-width: 75px;
    padding-bottom: 12px;
  }
}
#rebrand .cart-item__button {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__button {
    display: block;
    position: relative;
    margin-top: auto;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 700;
    color: var(--redesign-color-black-md);
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  #rebrand .cart-item__button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: var(--redesign-color-border);
  }
}
#rebrand .cart-item__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__container {
    grid-area: content;
    gap: 0;
    padding-left: 16px;
  }
}
#rebrand .cart-item__body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "variations variations" "counter price";
  align-items: end;
}
#rebrand .cart-item__body-variations {
  grid-area: variations;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 4px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__body-variations {
    flex-direction: column;
    align-items: start;
    gap: 0;
    margin-bottom: 0;
  }
}
#rebrand .cart-item__body-container {
  grid-area: counter;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__body {
    grid-template-columns: repeat(2, 1fr);
  }
}
#rebrand .cart-item__list {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 20px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__list {
    flex-direction: column-reverse;
    align-items: start;
    gap: 0;
  }
}
#rebrand .cart-item__item-price {
  font-size: 14px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
}
#rebrand .cart-item__item-price span {
  font-size: 18px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__item-price {
    display: none;
  }
}
#rebrand .cart-item__item-count {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__item-count {
    display: block;
  }
}
#rebrand .cart-item__item p {
  font-size: 16px;
  color: var(--color-black);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__item p {
    font-size: 12px;
  }
}
#rebrand .cart-item__item span {
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__item .product-counter {
    display: none;
  }
  #rebrand .cart-item__item-detail {
    display: none;
  }
}
#rebrand .cart-item__price {
  grid-area: price;
  font-size: 18px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
  text-align: right;
}
#rebrand .cart-item__price span,
#rebrand .cart-item__price small {
  font-size: 14px;
}
#rebrand .cart-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#rebrand .cart-item__content-button {
  max-width: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__content-button {
    display: none;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__content {
    margin-bottom: 8px;
  }
}
#rebrand .cart-item__title {
  max-width: 467px;
  width: 100%;
  font-size: 14px;
  line-height: normal;
  color: var(--redesign-color-black-md);
  text-align: left;
  text-transform: uppercase;
}
#rebrand .cart-item__price-info {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--redesign-color-black-md);
}
#rebrand .cart-item__price-info span,
#rebrand .cart-item__price-info small {
  font-size: 20px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item__price-info {
    font-size: 12px;
  }
  #rebrand .cart-item__price-info span {
    font-size: 16px;
  }
}
#rebrand .cart-item .product-calc-utils {
  grid-area: calc;
  padding: 7px 0;
  font-size: 12px;
  color: var(--color-gray-dk);
  background-color: var(--color-white-md);
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item .product-calc-utils {
    display: none;
  }
}
#rebrand .cart-item .product-calc-info {
  grid-area: info;
  font-size: 12px;
  color: var(--color-gray-dk);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item .product-calc-info {
    display: none;
  }
}
#rebrand .cart-item .color {
  display: flex;
  align-items: center;
  gap: 5px;
}
#rebrand .cart-item .color-box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-item .color-box {
    width: 14px;
    height: 14px;
  }
}

#rebrand .cart-popup {
  display: none;
  position: absolute;
  top: 30px;
  min-width: 397px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  border: 1px solid var(--redesign-color-border);
  background-color: var(--color-white);
  z-index: 10;
}
#rebrand .cart-popup.active {
  display: block;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-popup.active .cart-popup__close {
    display: flex;
    justify-content: end;
    margin-bottom: 30px;
  }
}
#rebrand .cart-popup__element {
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: var(--color-gray-dk);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
#rebrand .cart-popup__element::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 7px;
  height: 8px;
  background-color: var(--color-white);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-popup__element {
    display: none;
  }
}
#rebrand .cart-popup__close {
  display: none;
}
#rebrand .cart-popup__container {
  padding: 16px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-popup__container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px 16px 30px;
    border-radius: 15px 15px 0 0;
    background-color: var(--color-white);
  }
}
#rebrand .cart-popup__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
}
#rebrand .cart-popup__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#rebrand .cart-popup__item h4 {
  font-size: 14px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
#rebrand .cart-popup__item p {
  font-size: 16px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
}
#rebrand .cart-popup__item span {
  font-size: 12px;
  font-weight: 700;
}
#rebrand .cart-popup__content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid var(--redesign-color-border);
}
#rebrand .cart-popup__content > p {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray-dk);
  text-align: left;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .cart-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: none;
    background-color: rgba(0, 0, 0, 0.5);
    transform: none;
    overflow: hidden;
    z-index: 1000;
  }
}

#rebrand .cart-edit-modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: none;
  background-color: rgba(0, 0, 0, 0.5);
  transform: none;
  overflow: hidden;
  z-index: 1000;
}
#rebrand .cart-edit-modal.active {
  display: block;
}
#rebrand .cart-edit-modal__close {
  display: flex;
  justify-content: end;
  padding-right: 16px;
  cursor: pointer;
}
#rebrand .cart-edit-modal__container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  border-radius: 15px 15px 0 0;
  background-color: var(--color-white);
}
#rebrand .cart-edit-modal__title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
  text-align: center;
}
#rebrand .cart-edit-modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 16px;
}
#rebrand .cart-edit-modal__button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: var(--redesign-color-black-md);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#rebrand .cart-edit-modal__button:first-of-type {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .cart-edit-modal__button:last-of-type {
  padding-top: 16px;
}

#rebrand .cart-edit-count {
  display: none;
  z-index: 1000;
}
#rebrand .cart-edit-count.active {
  display: block;
}
#rebrand .cart-edit-count__close {
  display: flex;
  justify-content: end;
  padding-right: 16px;
  cursor: pointer;
}
#rebrand .cart-edit-count__container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
}
#rebrand .cart-edit-count__title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  color: #251f20;
  text-align: center;
}
#rebrand .cart-edit-count__wrapper {
  padding: 0 16px;
}
#rebrand .cart-edit-count__content {
  margin-bottom: 20px;
}
#rebrand .cart-edit-count__price {
  font-weight: 700;
  line-height: 20px;
  color: #251f20;
}
#rebrand .cart-edit-count__price p {
  font-size: 14px;
}
#rebrand .cart-edit-count__price span {
  font-size: 18px;
}
#rebrand .cart-edit-count .field-number input {
  height: 40px;
}
#rebrand .cart-edit-count__button {
  width: 100%;
  padding: 12px 0;
  border-radius: 100px;
  background-color: #99cf28;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
}
#rebrand .cart-edit-count__element {
  display: flex;
  justify-content: center;
}
#rebrand .cart-edit-count__item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 9px 0;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}
#rebrand .cart-edit-count__item span {
  display: block;
  font-size: 12px;
  color: #77797c;
}

#rebrand .accent-products {
  margin-bottom: 80px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
  #rebrand .accent-products ul::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--redesign-color-border);
    bottom: -5px;
    left: 0;
    right: 0;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products .wrapper {
    padding: 0;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products .tabs-content {
    padding-left: 10px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products {
    margin-bottom: 50px;
  }
}

#rebrand .accent-special-offers {
  position: relative;
  padding: 0;
  margin-bottom: 80px;
}
#rebrand .accent-special-offers__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-special-offers__container {
    grid-template-columns: 1fr;
    gap: 13px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-special-offers {
    margin-bottom: 50px;
  }
}

#rebrand .login {
  position: relative;
  padding: 35px 0 60px;
}
#rebrand .login__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 100px;
}
@media (--breakpoint-desktop) {
  #rebrand .login__container {
    grid-template-columns: 1fr;
  }
}
#rebrand .login__content {
  position: relative;
}
#rebrand .login__content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: var(--redesign-color-border);
}
@media (--breakpoint-desktop) {
  #rebrand .login__content::after {
    content: none;
  }
}
#rebrand .login__title {
  margin-bottom: 25px;
  font-size: 24px;
  color: var(--redesign-color-black-md);
}
#rebrand .login__form {
  max-width: 491px;
}
#rebrand .login__form .form-row {
  margin-bottom: 24px;
}
#rebrand .login__form .form-label {
  padding: 0;
}
#rebrand .login__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
}
#rebrand .login__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .login__form .password-reset {
  display: inline;
  padding: 24px 0;
  font-size: 12px;
  color: var(--redesign-color-black-md);
  text-align: left;
  text-transform: uppercase;
}
#rebrand .login__form-button {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 100px;
}
#rebrand .login__divider {
  position: relative;
  margin-bottom: 24px;
  font-size: 14px;
  color: var(--color-gray-dk);
  text-align: center;
}
#rebrand .login__divider::before, #rebrand .login__divider::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: 214px;
  width: 100%;
  height: 1px;
  background-color: var(--redesign-color-border);
}
@media (--breakpoint-mobile) {
  #rebrand .login__divider::before, #rebrand .login__divider::after {
    max-width: 155px;
  }
}
#rebrand .login__divider::before {
  left: 0;
}
#rebrand .login__divider::after {
  right: 0;
}
#rebrand .login__socials {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#rebrand .login .btn-socials-login {
  width: 100%;
  padding: 0;
  font-size: 14px;
  color: var(--redesign-color-black-md);
  background: transparent;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
  text-align: center;
  text-transform: none;
}
#rebrand .login .btn-socials-login > svg {
  margin-right: 8px;
}
#rebrand .login__info {
  display: block;
}
#rebrand .login__info-title {
  margin-bottom: 25px;
  font-size: 24px;
  color: var(--redesign-color-black-md);
}
#rebrand .login__info-list {
  margin-bottom: 38px;
}
#rebrand .login__info-list ul {
  list-style: none;
}
#rebrand .login__info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}
#rebrand .login__info-item:last-of-type {
  margin-bottom: 0;
}
#rebrand .login__info-item p {
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .login__info-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 491px;
  width: 100%;
  height: 40px;
  color: var(--redesign-color-primary);
  border-radius: 100px;
  border: 1px solid var(--redesign-color-primary);
  text-align: center;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .login__info {
    display: none;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .login {
    padding: 25px 0 30px;
  }
}

#rebrand .register {
  position: relative;
  padding: 35px 0 60px;
}
#rebrand .register__title {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
#rebrand .register__form {
  max-width: 491px;
  margin: 0 auto;
}
#rebrand .register__form-button {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 100px;
}
#rebrand .register__form .form-row {
  margin-bottom: 24px;
}
#rebrand .register__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
}
#rebrand .register__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .register__form .check {
  display: flex;
  align-items: center;
}
#rebrand .register__form .check a {
  color: var(--redesign-color-black-md);
  text-decoration: underline;
}
#rebrand .register__form .check input {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
#rebrand .register__form .check label::before, #rebrand .register__form .check label::after {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
#rebrand .register__form .check label::before {
  border: 1px solid var(--redesign-color-border);
  background: transparent;
  box-shadow: none;
}
#rebrand .register__form .check label::after {
  content: url("../../../images/check.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--redesign-color-primary);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .register {
    padding: 25px 0 30px;
  }
}

#rebrand .password-forget {
  position: relative;
  padding: 160px 0;
}
#rebrand .password-forget__title {
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-forget__title {
    text-align: left;
  }
}
#rebrand .password-forget__description {
  max-width: 348px;
  width: 100%;
  margin: 0 auto 25px;
  font-size: 14px;
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-forget__description {
    text-align: left;
  }
}
#rebrand .password-forget__form {
  max-width: 491px;
  margin: 0 auto;
}
#rebrand .password-forget__form-button button {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 100px;
}
#rebrand .password-forget__form .form-row {
  margin-bottom: 24px;
}
#rebrand .password-forget__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
}
#rebrand .password-forget__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-forget {
    padding: 25px 0 160px;
  }
}

#rebrand .successful-reset-password {
  position: relative;
  padding: 160px 0;
}
#rebrand .successful-reset-password__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#rebrand .successful-reset-password__image {
  max-width: 70px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  object-fit: cover;
}
#rebrand .successful-reset-password__title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #251f20;
}
#rebrand .successful-reset-password__description {
  max-width: 364px;
  width: 100%;
  font-size: 14px;
  color: #251f20;
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .successful-reset-password {
    padding: 140px 0;
  }
}

#rebrand .password-reset {
  position: relative;
  padding: 160px 0;
}
#rebrand .password-reset__title {
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-reset__title {
    text-align: left;
  }
}
#rebrand .password-reset__form {
  max-width: 491px;
  margin: 0 auto;
}
#rebrand .password-reset__form-button button {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 100px;
}
#rebrand .password-reset__form .form-row {
  margin-bottom: 24px;
}
#rebrand .password-reset__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
}
#rebrand .password-reset__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-reset {
    padding: 25px 0 160px;
  }
}

#rebrand .profile-view__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-view__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-view__container {
  max-width: 970px;
  width: 100%;
  padding-top: 30px;
  overflow: hidden;
}

#rebrand .profile-nav {
  position: sticky;
  top: 0;
  height: fit-content;
  padding: 50px 0;
  border-radius: 0px 10px 10px 0px;
  background-color: #f7f7f7;
  z-index: 10;
}
#rebrand .profile-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  z-index: -1;
}
#rebrand .profile-nav__info {
  margin-bottom: 74px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
}
#rebrand .profile-nav__element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-bottom: 12px;
  border-radius: 100%;
  font-size: 14px;
  color: #fff;
  background-color: #99cf28;
  text-transform: uppercase;
}
#rebrand .profile-nav__title {
  font-size: 16px;
  font-weight: 600;
  color: #251f20;
}
#rebrand .profile-nav__mail {
  font-size: 14px;
  color: #251f20;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__container {
    padding: 0 10px;
  }
}
#rebrand .profile-nav__list {
  display: flex;
  flex-direction: column;
  gap: 23px;
  list-style: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__list {
    flex-direction: row;
    gap: 12px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #rebrand .profile-nav__list::-webkit-scrollbar {
    display: none;
  }
}
#rebrand .profile-nav__item.active a {
  font-weight: 600;
  color: #99cf28;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__item.active a {
    border: 1px solid #99cf28;
  }
}
#rebrand .profile-nav__item.active svg {
  display: none;
}
#rebrand .profile-nav__item.active svg.active {
  display: block;
}
#rebrand .profile-nav__item.exit {
  padding-top: 140px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__item.exit {
    padding-top: 0;
  }
  #rebrand .profile-nav__item.exit a {
    border: 1px solid transparent;
    background-color: #fff;
  }
}
#rebrand .profile-nav__item > a {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 1.2;
  color: #251f20;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav__item > a {
    justify-content: center;
    padding: 8px 20px;
    width: 100%;
    border-radius: 100px;
    border: 1px solid #dbdbdb;
    white-space: nowrap;
    box-sizing: border-box;
  }
}
#rebrand .profile-nav__item svg.active {
  display: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-nav {
    position: relative;
    padding: 24px 0;
    margin: 0 -10px;
    border-radius: 0 0 15px 15px;
    overflow: hidden;
  }
  #rebrand .profile-nav::before {
    content: none;
  }
}

#rebrand .newsletter {
  position: relative;
  margin-bottom: 50px;
  border-radius: 10px;
  background-image: url("../../../images/banner-desktop.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#rebrand .newsletter.hidden {
  display: none;
}
#rebrand .newsletter__container {
  padding: 20px;
}
#rebrand .newsletter__content {
  max-width: 361px;
  width: 100%;
}
#rebrand .newsletter__close {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 12px;
  right: 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#rebrand .newsletter__title {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .newsletter__title {
    max-width: 207px;
    width: 100%;
    margin-bottom: 8px;
    font-size: 18px;
  }
}
#rebrand .newsletter__description {
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--color-white);
}
#rebrand .newsletter__buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}
#rebrand .newsletter__buttons-subscribe, #rebrand .newsletter__buttons-close {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 140px;
  width: 100%;
  padding: 9px 0;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: uppercase;
}
#rebrand .newsletter__buttons-subscribe:hover, #rebrand .newsletter__buttons-close:hover {
  text-decoration: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .newsletter__buttons-subscribe, #rebrand .newsletter__buttons-close {
    max-width: 100%;
  }
}
#rebrand .newsletter__buttons-subscribe {
  color: var(--redesign-color-primary);
  background-color: var(--color-white);
}
#rebrand .newsletter__buttons-close {
  color: var(--color-white);
  border: 1px solid var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .newsletter__buttons {
    gap: 8px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .newsletter {
    height: 320px;
    background-image: url("../../../images/banner-mobile.png");
  }
}

.faq-page {
  background-color: var(--color-white-md);
}
.faq-page__head {
  padding: 45px 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.2em;
}
.faq-page__container {
  padding-bottom: 80px;
}
.faq-page__content {
  padding-top: 30px;
  background-color: var(--color-white);
  border-radius: 40px;
}

#rebrand .faq {
  position: relative;
  margin-bottom: 80px;
}
#rebrand .faq__title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
  color: #251f20;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .faq__title {
    margin-bottom: 25px;
    font-size: 24px;
  }
}
#rebrand .faq__item {
  padding: 16px 0;
  border-bottom: 1px solid #dbdbdb;
}
#rebrand .faq__item:first-of-type {
  padding-top: 0;
}
#rebrand .faq__item.active .faq__item-info {
  display: block;
}
#rebrand .faq__item.active .faq__item-button {
  margin-bottom: 16px;
}
#rebrand .faq__item.active .faq__item-arrow {
  transform: none;
  transition: transform 0.3s ease-out;
}
#rebrand .faq__item-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
#rebrand .faq__item-list {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #251f20;
  text-align: left;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .faq__item-list {
    max-width: 300px;
  }
}
#rebrand .faq__item-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in;
}
#rebrand .faq__item-info {
  display: none;
}
#rebrand .faq__item-content {
  font-size: 14px;
  color: #251f20;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .faq {
    margin-bottom: 50px;
  }
}

#rebrand .success-message {
  position: absolute;
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 0;
  color: var(--redesign-color-primary);
  background-color: var(--redesign-color-white-md);
  border-radius: 8px;
  border-top: 1px solid var(--redesign-color-primary);
  border-bottom: 1px solid var(--redesign-color-primary);
  z-index: 20;
}
#rebrand .success-message__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
#rebrand .success-message__info {
  font-size: 18px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .success-message {
    border-radius: 0;
  }
}

#rebrand .profile-personal-info__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-info__wrapper {
    grid-template-columns: 1fr;
  }
}

#rebrand .profile-personal {
  position: relative;
  padding-top: 30px;
}
#rebrand .profile-personal__title {
  margin-bottom: 35px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .profile-personal__form {
  margin-bottom: 35px;
  border-bottom: 1px solid var(--redesign-color-border);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title button" "form form";
  row-gap: 20px;
}
#rebrand .profile-personal__form-title {
  grid-area: title;
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__form-title {
    margin-bottom: 16px;
  }
}
#rebrand .profile-personal__form-button {
  grid-area: button;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 9px 20px;
  border-radius: 100px;
  color: var(--redesign-color-primary);
  background-color: var(--redesign-color-white-md);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
#rebrand .profile-personal__form form {
  grid-area: form;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__form form {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
}
#rebrand .profile-personal__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 0;
  background-color: var(--color-white-md);
}
#rebrand .profile-personal__form .field input::placeholder {
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .profile-personal__form .form-label {
  padding-left: 12px;
  color: var(--color-gray-dk);
  text-transform: none;
}
#rebrand .profile-personal__form .form-row {
  padding-bottom: 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__form {
    grid-template-columns: 1fr;
    grid-template-areas: "title" "form" "button";
    row-gap: 0;
    margin-bottom: 30px;
  }
}
#rebrand .profile-personal__button button {
  max-width: 204px;
  width: 100%;
  padding: 9px 24px;
  font-size: 14px;
  font-weight: 700;
  color: var(--redesign-color-red);
  border-radius: 100px;
  border: 1px solid var(--redesign-color-red);
  background: transparent;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__button button {
    max-width: 100%;
  }
}
#rebrand .profile-personal__address {
  margin-bottom: 35px;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .profile-personal__address-head {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__address-head {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
#rebrand .profile-personal__address-title {
  font-size: 20px;
  font-weight: 600;
}
#rebrand .profile-personal__address-list {
  padding-top: 25px;
}
#rebrand .profile-personal__address-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: var(--color-white-md);
}
#rebrand .profile-personal__address-container .title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
#rebrand .profile-personal__address-container .btn {
  max-width: 149px;
  width: 100%;
  background-color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__address-container .btn {
    max-width: 100%;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal__address-container {
    flex-direction: column;
    gap: 25px;
  }
}
#rebrand .profile-personal .btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 9px 20px;
  color: var(--color-white);
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  font-size: 14px;
  text-transform: uppercase;
}
#rebrand .profile-personal .addresses-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 164px;
  width: 100%;
  padding: 9px 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal .addresses-btn {
    max-width: 100%;
  }
}

#rebrand .profile-personal-form__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-form__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-personal-form__container {
  padding-top: 35px;
}
#rebrand .profile-personal-form__link {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .profile-personal-form__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-form__title {
    font-size: 24px;
  }
}
#rebrand .profile-personal-form__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
#rebrand .profile-personal-form__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .profile-personal-form__form .field input::placeholder {
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .profile-personal-form__form .form-label {
  display: block;
  padding-left: 12px;
  margin-bottom: 5px;
  color: var(--color-gray-dk);
  text-transform: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-form__form {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-personal-form__button {
  max-width: 174px;
  width: 100%;
  padding: 12px 0;
  color: var(--color-white);
  border: none;
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-form__button {
    max-width: 100%;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-personal-form {
    margin-bottom: 50px;
  }
}

#rebrand .profile-address-form__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-address-form__container {
  padding-top: 35px;
}
#rebrand .profile-address-form__link {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .profile-address-form__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__title {
    font-size: 24px;
  }
}
#rebrand .profile-address-form__form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 28px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__form-wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-address-form__form-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__form-buttons {
    flex-direction: column;
    gap: 20px;
  }
}
#rebrand .profile-address-form__form-add-btn, #rebrand .profile-address-form__form-remove-btn {
  max-width: 207px;
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__form-add-btn, #rebrand .profile-address-form__form-remove-btn {
    max-width: 100%;
  }
}
#rebrand .profile-address-form__form-add-btn {
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border: 1px solid transparent;
}
#rebrand .profile-address-form__form-remove-btn {
  color: var(--redesign-color-red);
  border: 1px solid var(--redesign-color-red);
  background-color: transparent;
}
#rebrand .profile-address-form__form .form-row:last-child {
  grid-column: span 2;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form__form .form-row:last-child {
    grid-column: unset;
  }
}
#rebrand .profile-address-form__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
}
#rebrand .profile-address-form__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .profile-address-form__form .form-label {
  display: block;
  padding-left: 12px;
  margin-bottom: 5px;
  color: var(--color-gray-dk);
  text-transform: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-address-form {
    margin-bottom: 50px;
  }
}

#rebrand .profile-details-form__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-details-form__container {
  padding-top: 35px;
}
#rebrand .profile-details-form__link {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .profile-details-form__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form__title {
    font-size: 24px;
  }
}
#rebrand .profile-details-form__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
#rebrand .profile-details-form__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .profile-details-form__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .profile-details-form__form .form-label {
  display: block;
  padding-left: 12px;
  margin-bottom: 5px;
  color: var(--color-gray-dk);
  text-transform: none;
}
#rebrand .profile-details-form__form-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form__form-buttons {
    flex-direction: column;
    gap: 20px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form__form {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-details-form-add-button, #rebrand .profile-details-form-remove-button {
  max-width: 207px;
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form-add-button, #rebrand .profile-details-form-remove-button {
    max-width: 100%;
  }
}
#rebrand .profile-details-form-add-button {
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
  border: 1px solid transparent;
}
#rebrand .profile-details-form-remove-button {
  color: var(--redesign-color-red);
  border: 1px solid var(--redesign-color-red);
  background-color: transparent;
}
#rebrand .profile-details-form__button {
  max-width: 174px;
  width: 100%;
  padding: 12px 0;
  color: var(--color-white);
  border: none;
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form__button {
    max-width: 100%;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-details-form {
    margin-bottom: 50px;
  }
}

#rebrand .password-change__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-change__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .password-change__container {
  padding-top: 35px;
}
#rebrand .password-change__link {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .password-change__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-change__title {
    font-size: 24px;
  }
}
#rebrand .password-change__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
#rebrand .password-change__form .field input {
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .password-change__form .field input::placeholder {
  font-size: 14px;
  color: var(--color-gray-dk);
}
#rebrand .password-change__form .form-label {
  display: block;
  padding-left: 12px;
  margin-bottom: 5px;
  color: var(--color-gray-dk);
  text-transform: none;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-change__form {
    grid-template-columns: 1fr;
  }
}
#rebrand .password-change__button {
  grid-column: span 2;
}
#rebrand .password-change__button button {
  max-width: 174px;
  width: 100%;
  padding: 12px 0;
  color: var(--color-white);
  border-radius: 100px;
  background-color: var(--redesign-color-primary);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-change__button button {
    max-width: 100%;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .password-change__button {
    grid-column: unset;
  }
}

#rebrand .profile-orders__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-orders__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-orders__container {
  padding-top: 35px;
}
#rebrand .profile-orders__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: #251f20;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-orders__title {
    font-size: 24px;
  }
}

#rebrand .order-item {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: var(--color-white-md);
}
#rebrand .order-item.active .order-item__head {
  margin-bottom: 20px;
}
#rebrand .order-item.active .order-item__head::after {
  transform: rotate(0);
  transition: transform 0.3s ease-in;
}
#rebrand .order-item.active .order-item__body {
  display: grid;
  transition: display 0s linear 0s;
}
#rebrand .order-item__head {
  position: relative;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
}
#rebrand .order-item__head::after {
  content: url("../../../images/arrow.svg");
  position: absolute;
  top: 10px;
  right: -5%;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in;
}
@media (--breakpoint-desktop) {
  #rebrand .order-item__head {
    grid-template-rows: auto auto;
    align-items: start;
    gap: 10px;
  }
  #rebrand .order-item__head::after {
    top: 40%;
  }
}
#rebrand .order-item__content {
  width: 85%;
}
#rebrand .order-item__content-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-desktop) {
  #rebrand .order-item__content {
    grid-row: 2;
    width: 75%;
  }
}
#rebrand .order-item__status {
  position: relative;
}
#rebrand .order-item__status::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  transform: translateY(-50%);
}
#rebrand .order-item__status p {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
#rebrand .order-item__status--new {
  color: var(--redesign-color-green);
}
#rebrand .order-item__status--new::before {
  background-color: var(--redesign-color-green);
}
#rebrand .order-item__status--processed {
  color: var(--redesign-color-yellow);
}
#rebrand .order-item__status--processed::before {
  background-color: var(--redesign-color-yellow);
}
#rebrand .order-item__status--collected {
  color: var(--redesign-color-orange);
}
#rebrand .order-item__status--collected::before {
  background-color: var(--redesign-color-orange);
}
#rebrand .order-item__status--received {
  color: var(--redesign-color-green-md);
}
#rebrand .order-item__status--received::before {
  background-color: var(--redesign-color-green-md);
}
#rebrand .order-item__status--completed {
  color: var(--color-gray-dk);
}
#rebrand .order-item__status--completed::before {
  background-color: var(--color-gray-dk);
}
@media (--breakpoint-desktop) {
  #rebrand .order-item__status {
    grid-row: 1;
    padding-left: 12px;
  }
  #rebrand .order-item__status::before {
    left: 0;
  }
}
#rebrand .order-item__body {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  transition: display 0s linear 0.3s;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .order-item__body {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
#rebrand .order-item__wrapper {
  padding: 20px;
  border-radius: 4px;
  background-color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .order-item__wrapper {
    padding: 16px;
    border-radius: 10px;
  }
}
#rebrand .order-item__list {
  padding: 20px 0;
  border-bottom: 1px solid var(--redesign-color-border);
}
#rebrand .order-item__list:first-of-type {
  padding-top: 0;
}
#rebrand .order-item__list-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
#rebrand .order-item__list-title {
  color: var(--redesign-color-black-md);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
#rebrand .order-item__list-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#rebrand .order-item__list-content > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .order-item__list-content > p {
    font-size: 14px;
  }
}
#rebrand .order-item__list-content span {
  font-weight: 400;
  color: var(--color-gray-dk);
}
#rebrand .order-item__info {
  padding-top: 20px;
}
#rebrand .order-item__info-item, #rebrand .order-item__info-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--redesign-color-black-md);
}
#rebrand .order-item__info-item {
  padding-bottom: 10px;
}
#rebrand .order-item__info-item > h4 {
  font-size: 18px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .order-item__info-item > h4 {
    font-size: 16px;
  }
}
#rebrand .order-item__info-item > p {
  font-size: 18px;
  font-weight: 600;
}
#rebrand .order-item__info-item > p span {
  font-size: 14px;
}
#rebrand .order-item__info-price > h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}
#rebrand .order-item__info-price > p {
  font-size: 20px;
  font-weight: 700;
}
#rebrand .order-item__info-price > p span {
  font-size: 16px;
  font-weight: 700;
}
#rebrand .order-item__products {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .order-item {
    padding: 16px;
  }
}

#rebrand .product-order-item__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__wrapper {
    gap: 0;
  }
}
#rebrand .product-order-item__image {
  max-width: 156px;
  width: 100%;
}
#rebrand .product-order-item__image img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--redesign-color-border);
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__image img {
    height: 58px;
    border: 0;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__image {
    max-width: 73px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "title title" "list price";
    padding: 12px;
    border-left: 1px solid var(--redesign-color-border);
  }
}
#rebrand .product-order-item__title {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__title {
    grid-area: title;
    font-size: 14px;
  }
}
#rebrand .product-order-item__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
}
#rebrand .product-order-item__list-item p,
#rebrand .product-order-item__list-item span {
  font-size: 14px;
  font-weight: 400;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__list-item p,
  #rebrand .product-order-item__list-item span {
    font-size: 12px;
  }
}
#rebrand .product-order-item__list-item p {
  padding: 0 5px;
  color: #77797c;
  border-right: 1px solid var(--redesign-color-border);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__list-item p {
    border-right: 0;
  }
}
#rebrand .product-order-item__list-item span {
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__list {
    grid-area: list;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
  }
}
#rebrand .product-order-item__price p {
  font-size: 18px;
  font-weight: 700;
  color: var(--redesign-color-black-md);
}
#rebrand .product-order-item__price span {
  font-size: 14px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item__price {
    grid-area: price;
    margin-top: auto;
  }
}
#rebrand .product-order-item .color {
  display: flex;
  align-items: center;
  gap: 5px;
}
#rebrand .product-order-item .color-box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item .color-box {
    width: 14px;
    height: 14px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-order-item {
    border-radius: 10px;
    background-color: var(--color-white);
  }
}

#rebrand .no-order-item {
  position: relative;
  padding-top: 94px;
}
#rebrand .no-order-item__wrapper {
  max-width: 364px;
  margin: 0 auto;
  text-align: center;
}
#rebrand .no-order-item__image {
  max-width: 66px;
  width: 100%;
  margin: 0 auto 20px;
}
#rebrand .no-order-item__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--breakpoint-desktop) {
  #rebrand .no-order-item__image {
    max-width: 54px;
  }
}
#rebrand .no-order-item__title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}
#rebrand .no-order-item__description {
  margin-bottom: 20px;
  font-size: 14px;
  color: #000;
}
#rebrand .no-order-item__link {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 188px;
  width: 100%;
  margin: 0 auto;
  padding: 9px 0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border-radius: 100px;
  background-color: #99cf28;
  text-transform: uppercase;
}
@media (--breakpoint-desktop) {
  #rebrand .no-order-item {
    padding: 25px 0 50px;
  }
}

#rebrand .profile-newsletter__wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 30px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-newsletter__wrapper {
    grid-template-columns: 1fr;
  }
}
#rebrand .profile-newsletter__container {
  padding-top: 35px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-newsletter__container {
    padding: 30px 0;
  }
}
#rebrand .profile-newsletter__title {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-newsletter__title {
    font-size: 24px;
  }
}
#rebrand .profile-newsletter__description {
  max-width: 361px;
  width: 100%;
  margin-bottom: 30px;
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .profile-newsletter__form-subscribe, #rebrand .profile-newsletter__form-unsubscribe {
  max-width: 174px;
  width: 100%;
  padding: 12px 0;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .profile-newsletter__form-subscribe, #rebrand .profile-newsletter__form-unsubscribe {
    max-width: 100%;
  }
}
#rebrand .profile-newsletter__form-subscribe {
  color: var(--color-white);
  background-color: var(--redesign-color-primary);
}
#rebrand .profile-newsletter__form-unsubscribe {
  color: var(--redesign-color-primary);
  border: 1px solid var(--redesign-color-primary);
}
#rebrand .profile-newsletter__form .field {
  margin-bottom: 30px;
}
#rebrand .profile-newsletter__form .field input {
  max-width: 474px;
  height: 40px;
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
}
#rebrand .profile-newsletter__form .field input::placeholder {
  font-size: 14px;
  color: var(--redesign-color-black-md);
}
#rebrand .profile-newsletter__form .form-label {
  display: block;
  padding-left: 12px;
  margin-bottom: 5px;
  color: var(--color-gray-dk);
  text-transform: none;
}

#rebrand .delete-account-message {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1000;
}
#rebrand .delete-account-message.active {
  display: block;
}
#rebrand .delete-account-message__container {
  position: absolute;
  max-width: 356px;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .delete-account-message__container {
    max-width: 100%;
    top: unset;
    left: 0;
    bottom: 0;
    transform: none;
    border-radius: 15px 15px 0 0;
  }
}
#rebrand .delete-account-message__content {
  max-width: 288px;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .delete-account-message__content {
    max-width: 329px;
    padding: 30px 16px;
  }
}
#rebrand .delete-account-message__image {
  max-width: 40px;
  margin-bottom: 20px;
}
#rebrand .delete-account-message__image img {
  display: block;
  width: 100%;
  height: 100%;
}
#rebrand .delete-account-message__title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: var(--redesign-color-black-md);
  text-align: center;
}
#rebrand .delete-account-message__info {
  margin-bottom: 20px;
  color: var(--color-black);
  font-size: 14px;
  text-align: center;
}
#rebrand .delete-account-message__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
#rebrand .delete-account-message__confirm-button, #rebrand .delete-account-message__cancel-button {
  min-width: 139px;
  width: 100%;
  padding: 9px 0;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: uppercase;
}
#rebrand .delete-account-message__confirm-button {
  color: var(--redesign-color-red);
  border: 1px solid var(--redesign-color-red);
}
#rebrand .delete-account-message__cancel-button {
  color: var(--redesign-color-black-md);
  border: 1px solid var(--redesign-color-border);
}

#rebrand .products-listing {
  position: relative;
  background-color: var(--color-white-md);
}
#rebrand .products-listing__head {
  padding: 35px 0;
}
#rebrand .products-listing__content-title {
  font-size: 28px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing__content-title {
    font-size: 24px;
  }
}
#rebrand .products-listing__wrapper {
  padding-top: 50px;
  border-radius: 40px 40px 0 0;
  background-color: var(--color-white);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing__wrapper {
    padding-top: 25px;
    border-radius: 25px 25px 0 0;
  }
}
#rebrand .products-listing__mobile-menu {
  display: none;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
  padding: 0 16px;
}
#rebrand .products-listing__mobile-menu .nav-aside-head {
  padding: 0;
  background: transparent;
}
#rebrand .products-listing__mobile-menu .nav-aside-head::before, #rebrand .products-listing__mobile-menu .nav-aside-head::after {
  content: none;
}
@media (--breakpoint-desktop) {
  #rebrand .products-listing__mobile-menu .filters-aside-head {
    display: block;
  }
}
#rebrand .products-listing__mobile-menu .categories-menu,
#rebrand .products-listing__mobile-menu .filters-menu {
  max-width: 134px;
  width: 100%;
}
#rebrand .products-listing__mobile-menu .categories-menu button,
#rebrand .products-listing__mobile-menu .filters-menu button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 0;
  color: var(--redesign-color-black-md);
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid var(--redesign-color-border);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  cursor: pointer;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing__mobile-menu {
    display: flex;
    justify-content: center;
  }
}
@media (--breakpoint-tablet-sm-up) and (--breakpoint-desktop) {
  #rebrand .products-listing__mobile-menu {
    display: flex;
    justify-content: start;
  }
}
#rebrand .products-listing__sort-menu button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid var(--redesign-color-border);
  cursor: pointer;
}
#rebrand .products-listing__scroll {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50%;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 40px;
  cursor: pointer;
  z-index: 10;
}
#rebrand .products-listing__scroll::after {
  content: none;
}

#rebrand .products-listing-boxes__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    border-bottom: 1px solid var(--color-scrollbar-bg);
  }
}
#rebrand .products-listing-boxes__button {
  max-width: 194px;
  margin: 0 auto;
  padding: 60px 0 80px;
}
#rebrand .products-listing-boxes__button.hidden {
  display: none;
}
#rebrand .products-listing-boxes__button button {
  border-radius: 100px;
  font-size: 14px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes__button {
    max-width: 100%;
    padding: 40px 16px;
  }
}
#rebrand .products-listing-boxes .box-basic {
  max-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes .box-basic {
    min-width: 180px;
    border: 0;
  }
  #rebrand .products-listing-boxes .box-basic:nth-of-type(odd) {
    border-right: 1px solid var(--color-scrollbar-bg);
    border-radius: 0;
  }
}
#rebrand .products-listing-boxes .box-type-2 {
  display: flex;
  flex-direction: column;
  padding: 19px 25px 0;
}
#rebrand .products-listing-boxes .box-type-2 .content {
  margin-top: auto;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes .box-type-2 {
    position: relative;
    padding: 10px 10px 0;
  }
  #rebrand .products-listing-boxes .box-type-2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-scrollbar-bg);
  }
}
#rebrand .products-listing-boxes .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .products-listing-boxes .title {
  margin-bottom: 12px;
}
#rebrand .products-listing-boxes .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .products-listing-boxes .box-price-wrap {
  text-align: left;
}
#rebrand .products-listing-boxes .box-price {
  vertical-align: unset;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .products-listing-boxes {
    margin: 0 -10px;
  }
  #rebrand .products-listing-boxes .promo-price {
    font-size: 18px;
  }
  #rebrand .products-listing-boxes .promo-price span {
    font-size: 14px;
  }
  #rebrand .products-listing-boxes .old-price {
    font-size: 16px;
  }
  #rebrand .products-listing-boxes .old-price span {
    font-size: 12px;
  }
}

@media (--breakpoint-desktop) {
  #rebrand .filters-aside__wrapper {
    overflow: auto;
  }
}
#rebrand .filters-aside .filters-aside-body__button {
  display: none;
}
#rebrand .filters-aside .filters-aside-body__button button {
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: var(--color-white);
  background: var(--redesign-color-primary);
  border: 0;
  border-radius: 100px;
  text-transform: uppercase;
  cursor: pointer;
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-aside-body__button {
    display: block;
    margin-top: auto;
    padding: 30px 16px;
    border-top: 1px solid var(--redesign-color-border);
  }
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-aside-body__container {
    position: absolute;
    width: 100%;
    height: 95%;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
    border-radius: 15px 15px 0 0;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-aside-body {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
}
#rebrand .filters-aside .filters-aside-head::before, #rebrand .filters-aside .filters-aside-head::after {
  content: none;
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-aside-head {
    margin: 0 0 24px;
    padding: 30px 16px 0;
    border: 0;
  }
  #rebrand .filters-aside .filters-aside-head .title {
    margin: 0 auto;
    padding: 0;
    font-size: 18px;
    color: var(--redesign-color-black-md);
    text-align: center;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-aside-item {
    padding: 0;
    margin: 0 16px;
  }
  #rebrand .filters-aside .filters-aside-item.is-active {
    padding: 0 0 20px;
  }
  #rebrand .filters-aside .filters-aside-item.is-active .filters-aside-item-head {
    margin: 0;
  }
  #rebrand .filters-aside .filters-aside-item .filters-aside-item-head {
    padding: 0;
  }
  #rebrand .filters-aside .filters-aside-item .filters-aside-item-head .title {
    padding: 16px 0 0;
  }
}
#rebrand .filters-aside .filters-close {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}
#rebrand .filters-aside .filters-close .icon-svg {
  width: 13px;
  height: 13px;
  padding: 0;
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside .filters-close {
    display: block;
  }
}
#rebrand .filters-aside__btns {
  display: none;
  position: absolute;
  top: 35px;
  left: 16px;
}
#rebrand .filters-aside__btns button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  color: var(--redesign-color-primary);
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}
@media (--breakpoint-desktop) {
  #rebrand .filters-aside__btns {
    display: block;
  }
}

#rebrand .sorting {
  display: block;
}
#rebrand .sorting .field-select::before {
  content: url("../../../images/sort-icon.svg");
  width: 13px;
  height: 17px;
  left: 20px;
  margin-top: 0;
  transform: translateY(-50%);
  border-width: 0;
  border-style: none;
}
#rebrand .sorting .sorting-select {
  position: relative;
  padding: 9px 20px;
  font-size: 14px;
  border: 1px solid var(--redesign-color-border);
  border-radius: 100px;
  cursor: pointer;
}
#rebrand .sorting .sorting-select.open {
  border: 1px solid var(--redesign-color-primary);
}
#rebrand .sorting .sorting-select.open .sorting-select__options {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}
#rebrand .sorting .sorting-select__trigger {
  display: block;
  padding-left: 19px;
  color: var(--redesign-color-black-md);
  text-transform: uppercase;
}
#rebrand .sorting .sorting-select__options {
  display: none;
  position: absolute;
  width: 270px;
  top: 110%;
  right: 0;
  border: 1px solid var(--redesign-color-border);
  background-color: var(--color-white);
  border-radius: 8px;
  z-index: 10;
}
#rebrand .sorting .sorting-select__option {
  padding: 10px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
#rebrand .sorting .sorting-select__option:hover {
  background-color: var(--color-white-md);
}
#rebrand .sorting__modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#rebrand .sorting__modal-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40%;
  padding-top: 30px;
  background-color: var(--color-white);
  border-radius: 15px 15px 0 0;
}
#rebrand .sorting__modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}
#rebrand .sorting__modal-title {
  margin-bottom: 24px;
  font-size: 18px;
  text-align: center;
}
#rebrand .sorting__modal-options {
  padding: 0 16px;
  list-style: none;
}
#rebrand .sorting__modal-options li {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid var(--redesign-color-border);
  cursor: pointer;
}
#rebrand .sorting__modal-options li.selected::after {
  content: url("../../../images/check-dark.svg");
  position: absolute;
  width: 14px;
  height: 9px;
  right: 16px;
}
#rebrand .sorting__modal-options li:first-of-type {
  padding-top: 0;
}
@media (--breakpoint-desktop) {
  #rebrand .sorting__modal {
    display: none;
  }
}
@media (--breakpoint-desktop) {
  #rebrand .sorting {
    display: none;
  }
}

#rebrand .product-categories {
  position: relative;
  padding-top: 35px;
  overflow: hidden;
}
#rebrand .product-categories::after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);
  width: 10%;
  height: 100%;
  z-index: 10;
}
#rebrand .product-categories__wrapper {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
#rebrand .product-categories__wrapper::-webkit-scrollbar {
  height: 8px;
}
#rebrand .product-categories__wrapper::-webkit-scrollbar-track {
  width: 7px;
  border-radius: 100px;
  background: var(--color-scrollbar-bg);
}
#rebrand .product-categories__wrapper::-webkit-scrollbar-thumb {
  width: 7px;
  border-radius: 100px;
  background: var(--redesign-color-border);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories__wrapper {
    gap: 12px;
  }
}
#rebrand .product-categories__item {
  min-width: 125px;
  max-width: 125px;
  width: 100%;
  margin-bottom: 24px;
  background-color: var(--color-white);
  border-radius: 10px;
}
#rebrand .product-categories__item .box-categories {
  width: 100%;
}
#rebrand .product-categories__item .box-categories:hover img {
  transform: none;
}
#rebrand .product-categories__item .title {
  min-height: 60px;
  padding: 14px;
  margin-bottom: 0;
  font-size: 16px;
  color: var(--redesign-color-black-md);
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories__item .title {
    padding: 12px;
    font-size: 14px;
  }
}
#rebrand .product-categories__item figure {
  max-width: 170px;
  height: 105px;
}
#rebrand .product-categories__item figure > img {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
  object-fit: cover;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories__item {
    margin-bottom: 20px;
  }
}
@media (--breakpoint-tablet-sm) {
  #rebrand .product-categories {
    padding-top: 25px;
  }
  #rebrand .product-categories::after {
    content: none;
  }
}

#rebrand .accent-products-last-seen {
  position: relative;
  margin-bottom: 50px;
}
#rebrand .accent-products-last-seen__title {
  display: block;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-last-seen__title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 30px;
  }
}
#rebrand .accent-products-last-seen .swiper-button-prev,
#rebrand .accent-products-last-seen .swiper-button-next {
  top: 5%;
}
#rebrand .accent-products-last-seen .swiper-button-prev::after,
#rebrand .accent-products-last-seen .swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .accent-products-last-seen .swiper-button-prev,
  #rebrand .accent-products-last-seen .swiper-button-next {
    top: 3%;
  }
  #rebrand .accent-products-last-seen .swiper-button-prev::after,
  #rebrand .accent-products-last-seen .swiper-button-next::after {
    font-size: 16px;
  }
}
#rebrand .accent-products-last-seen .swiper-button-prev {
  right: 50px;
  left: auto;
}

#rebrand .box-basic {
  min-width: 310px;
  width: 100%;
  outline: none;
  border: 1px solid var(--redesign-color-border);
  border-radius: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .box-basic {
    min-width: 243px;
  }
}
#rebrand .box-type-2 {
  padding: 19px 12px 0;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .box-type-2 {
    padding: 12px 12px 0;
  }
}
#rebrand .ribbon {
  padding: 0;
  left: 10px;
}
@media (--breakpoint-tablet-sm) {
  #rebrand .ribbon {
    padding: 0;
    left: 0;
  }
}
#rebrand .title {
  margin-bottom: 12px;
}
#rebrand .box-price-wrap {
  border-color: var(--redesign-color-border);
}
#rebrand .box-price-wrap {
  text-align: left;
}
#rebrand .box-price {
  vertical-align: unset;
}