#rebrand {
  .filter-colors {
    position: relative;

    .color-variations {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-bottom: 0;

      @media (--breakpoint-tablet-sm) {
        font-size: 16px;
        color: var(--redesign-color-black-md);
      }
    }

    .color-variation {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;

      label {
        display: flex;
        width: 24px;
        height: 24px;
        background-color: var(--color-white);
        border: 1px solid var(--color-neutral-mid);
        border-radius: 3px;

        &::before {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          background: currentColor;
          transform-origin: center center;
          transition: all .15s ease-in;
        }
      }

      input:checked + label:before {
        content: url("../../../images/check.svg");
        display: flex;
        align-items: center;
        justify-content: center;
        transform: none;
      }

      input:checked + label[style*="white"]:before {
        content: url("../../../images/check-dark.svg");
      }
    }
  }
}
