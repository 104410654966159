@import './_chat-popup.css';
@import './_custom-checkbox.css';
@import './_menu-mobile.scss';
@import './_menu-desktop.scss';
@import './_search-dropdown.scss';
@import './_contacts-page.scss';
@import './_input-fields.scss';
@import './_form.scss';
@import './_privacy-policy.scss';
@import './_info-box.scss';
@import './_news-view.scss';
@import './_image-slider.scss';
@import './_video-slider.scss';
@import './_terms-and-conditions.scss';
@import './_news-view.scss';
@import './_image-slider.scss';
@import './_video-slider.scss';
@import './_btns.scss';
@import './_datepicker.scss';
@import './_fields.scss';
@import './_no-results.scss';
@import './_checkout-authentication.scss';
@import './_shopping-cart-preview.scss';
@import './_shopping-cart-preview-product.scss';
@import './_register-modal.scss';
@import './_benefit-list.scss';
@import './_expandable-option.scss';
@import './_custom-radio.scss';
@import './_section-types.scss';
@import './_order-details.scss';
@import './_delivery-option-slider.scss';
@import './_custom-dropdown.scss';
@import './_alert.scss';
@import './_optional-detail-fields.scss';
@import './_checkout-progression.scss';
@import './_order-review.scss';
@import './_order-finish.scss';
@import './_news-list.scss';
@import './_expandable-list.scss';
@import './_extra-info-banner.scss';
@import './_faq.scss';
@import './_redesign-modal.scss';
@import './_fast-order-modal.scss';
@import './_enquiry-modal.scss';
@import './_stores.scss';
@import './_promo-timer.scss';
@import './_promo-popup.scss';
@import './_login-popup.scss';
@import './_error-page.scss';
/* ------------------------------------------------------------ *\
	#icons
\* ------------------------------------------------------------ */
.icon { display: inline-block; vertical-align: top; transition: all 0.3s; }

.icon-search { width: 18px; height: 18px; fill: var(--color-secondary); }
.icon-cart { width: 28px; height: 26px; fill: #fff; }
.icon-close { width: 24px; height: 24px; fill: #fff; }
.icon-search { width: 24px; height: 24px; fill: #fff; }
.icon-arrow-down { width: 16px; height: 9px; fill: var(--color-secondary); }
.icon-remove { width: 18px; height: 18px; fill: var(--color-neutral-dk); }
.icon-arrow-left { width: 0; height: 0; border-width: 5px; border-style: solid; border-color: transparent var(--color-tertiary) transparent transparent; }

/* ------------------------------------------------------------ *\
	#btns
\* ------------------------------------------------------------ */
.btns { font-size: 0; line-height: 0; }
.btn { position: relative; box-sizing: border-box; display: inline-block; vertical-align: top; max-width: 100%; background-color: transparent; border: 0; cursor: pointer; appearance: none; transition: all 0.3s; }
.btn.hidden { display: none; }
.btn:hover { text-decoration: none;	}
.btns-products { padding: 25px 0;  text-align: center; }

.btn-primary { min-width: 194px; height: 44px; border-radius: 100px; padding: 0 10px; background: var(--color-primary); font-family: var(--font-secondary); font-size: 13px; line-height: 44px; color: #fff; text-transform: uppercase; text-align: center; }
.btn-primary:focus,
.btn-primary:hover { background: var(--color-secondary); }
.btn-cardbox { position: relative; background: #00b5b2; }
.btn-cardbox:after { content: ''; position: absolute; display: block; top: 0; right: 0; bottom: 0; left: 0; border: 1px solid #00b5b2; border-radius: 5px; }
.btn-cardbox:hover { background: var(--color-primary);  }
.btn-cardbox:hover:after { top: -5px; right: -5px; bottom: -5px; left: -5px; opacity: 0; transition: .4s top, .4s right, .4s bottom, .4s left, .8s opacity; border: 1px solid var(--color-primary); }

.btn-secondary { height: 44px; border-radius: 5px; padding: 0 20px; background: var(--color-white-md); font-family: var(--font-secondary); font-size: 13px; line-height: 44px; color: #000; text-align: center; text-transform: uppercase; box-shadow: var(--shadow-default); }
.btn-secondary:focus,
.btn-secondary:hover { background: var(--color-secondary); color: #fff; text-decoration: none; }

.btn-link { font-size: 14px; line-height: 18px; text-decoration: underline; font-weight: bold; }
.btn-link:hover { text-decoration: none; }

.btn-article { height: 37px; border: 1px solid var(--color-neutral-mid); border-radius: 5px; padding: 0 10px; background: var(--color-neutral-lt); font-family: var(--font-secondary); font-size: 12px; line-height: 35px; color: var(--color-tertiary); text-transform: uppercase; text-align: center; }
.btn-article .icon { vertical-align: middle; margin: -2px 10px 0 0; }
.btn-article:focus,
.btn-article:hover { border-color: var(--color-secondary); background: var(--color-secondary); color: #fff; }
.btn-article:focus .icon,
.btn-article:hover .icon { border-right-color: #fff; }
.btn-stores,
.btn-search,
.btn-login,
.btn-contacts { position: relative; width: 68px; height: 68px; border-left: 1px solid var(--color-neutral-mid); background: #fff; }
.btn-stores .icon-svg,
.btn-search .icon-svg,
.btn-login .icon-svg,
.btn-contacts .icon-svg { position: absolute; top: 50%; left: 50%; height: 30px; width: 100%; transform: translate(-50%, -50%); fill: var(--color-secondary); }
.btn-search .icon-close,
.btn-stores .icon-close,
.btn-login .icon-close,
.btn-contacts .icon-close { opacity: 0; visibility: hidden; transition: none; }

.btn-socials-login { min-width: 200px; height: 42px; border: 1px solid var(--color-neutral-mid); border-radius: 5px; padding: 0 10px; background: var(--color-white-md); font-family: var(--font-secondary); font-size: 12px; line-height: 42px; color: var(--color-tertiary); text-transform: uppercase; text-align: left; }
.btn-socials-login .icon-svg { width: 24px; height: 24px; margin-right: 20px; vertical-align: middle; }
.btn-socials-login:hover { color: #fff; }
.btn-socials-login:hover .icon-svg { fill: #fff; }
.btn-socials-login.facebook:hover { background: #3b5998; }
.btn-socials-login.google:hover { background: #dd4b39; }

.btn-remove { border-radius: 5px; padding: 15px; }
.btn-remove:hover { background: var(--error); }
.btn-remove:hover .icon-svg { fill: #fff; }

.btn-menu { display: inline-block; overflow: visible; padding: 20px 15px 20px 0; background-color: transparent; transition-property: opacity, filter; transition-duration: 0.15s; transition-timing-function: linear; cursor: pointer; }
.btn-menu-box { position: relative; display: inline-block; vertical-align: top; width: 36px; height: 24px; }
.btn-menu-text { font-size: 16px; line-height: 1.2; font-family: var(--font-secondary); padding-left: 10px; vertical-align: middle; }
.btn-menu-inner { display: block; top: 50%; margin-top: -2px; }
.btn-menu-inner,
.btn-menu-inner::before,
.btn-menu-inner::after { position: absolute; width: 30px; height: 3px; border-radius: 4px; background-color: var(--color-secondary); transition-property: transform; transition-duration: 0.15s; transition-timing-function: ease; }
.btn-menu-inner::before,
.btn-menu-inner::after { content: ''; display: block; }
.btn-menu-inner::before { top: -8px; }
.btn-menu-inner::after { bottom: -8px; }
.btn-go-to-reg { padding: 10px 40px; background: #333333; font-family: var(--font-secondary); font-weight: 700; line-height: 1.2; color: #a9d000; border-radius: 5px; }


.btn-scroll-top { position: absolute; right: -100px; bottom: 0; display: none; text-align: center; width: 75px; height: 75px; background: #F5F5F5; border-radius: 4px; }
.btn-scroll-top.active { display: block; }
.btn-scroll-top:hover { background: #f0f0f0; }
.btn-scroll-top:after { content: '\e909'; font-family: 'gs-iconfont'; line-height: 75px; font-size: 16px; color: #000; }
.btn-container {
	display: flex;
	flex-direction: column;
	align-self: flex-start;

	@media(--breakpoint-tablet-sm-up) {
		flex-direction: row;
		gap: 17px;
	}
}

.btn.btn-secondary.copy-btn {
	display: flex;
	align-items: center;
	align-self: flex-start;
	height: 34px;
	font-size: 12px;
	border: 1px solid var(--color-gray-light);
	margin-bottom: 20px;
}

.btn.btn-secondary.copy-btn:hover .copy-btn__svg{
	fill: #fff;
}

.btn.btn-secondary.print-btn {
	display: flex;
	align-items: center;
	align-self: flex-start;
	justify-content: center;
	gap: 5px;
	height: 34px;
	font-size: 12px;
	border: 1px solid var(--color-gray-light);
	margin-bottom: 20px;
}

.print-btn .print-btn_svg {
	fill: #fff;
}

.btn.btn-secondary.low-availability-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	max-width: 210px;
	height: 34px;
	font-size: 12px;
	border: 1px solid var(--color-gray-light);
	margin-bottom: 20px;
}

.btn.btn-secondary.low-availability-btn:hover .low-availability-btn_svg {
	fill: #fff;
}

/* ------------------------------------------------------------ *\
	#nav
\* ------------------------------------------------------------ */
nav a { transition: all 0.3s; }
nav a:hover { text-decoration: none; }
nav ul { list-style: none; font-size: 0; line-height: 0; }

/* nav-primary */
.nav-primary { display: none; position: absolute; left: 0; width: 100%; z-index: 69; }
.nav-primary.is-active { display: flex; }
.nav-primary:after { @apply --clearfix; }
.nav-primary a { display: block; color: #000; box-sizing: border-box; }
.nav-primary ul { font-size: 16px; line-height: 1.31; }
.nav-primary ul li { font-size: 0; line-height: 0; color: #000000; direction: rtl; }
.nav-primary ul::-webkit-scrollbar { width: 0; }
.nav-primary ul::-webkit-scrollbar-track { background: #ffffff; }
.nav-primary ul::-webkit-scrollbar-thumb { width: 10px; background: var(--color-primary); border-radius: 60px; pointer-events: none; }
.nav-primary ul li a { padding: 15px 5px; font-family: var(--font-secondary); text-transform: uppercase; background: #ffffff; color: inherit; text-align: left; font-size: 16px; line-height: 1.31; }
.nav-primary > ul > .selected > a,
.nav-primary > ul > li:hover > a { color: inherit; }
.nav-primary > [class*="level-"] { display: none; flex: 0 0 25%; background: #ffffff; pointer-events: none; }
.nav-primary > [class*="level-"] > li[data-sup-id] { display: none; opacity: 0; pointer-events: none; }
.nav-primary > [class*="level-"] > li.active { display: block; opacity: 1; pointer-events: auto; transform: translateY(0); }
.nav-primary > [class*="level-"].is-active { display: block; pointer-events: auto; }
.nav-primary > [class*="level-"] [class*="gs-ico"] { width: 40px; display: inline-block; vertical-align: middle; font-size: 16px; line-height: 1.31; text-align: center; cursor: pointer; color: inherit; }
.nav-primary > [class*="level-"] [class*="gs-ico"] + a { display: inline-block; width: calc(100% - 40px); }
.nav-primary > [class*="level-1"] { display: block; pointer-events: auto; }
.nav-primary>[class*=level-].limit>li.active:nth-child(n+8) { display: none; }
.nav-primary> .see-more.hide { display: none; }
/* nav-mobile */
.nav-mobile,
.nav-mobile.is-active { position: absolute; left: 0; width: 100%; display: none; z-index: 69; background: #ffffff; }
.nav-mobile ul { font-size: 16px; line-height: 1.31; }
.nav-mobile ul li { position: relative; font-size: 0; line-height: 0; color: #000000; }
.nav-mobile ul li > ul { display: none; }
.nav-mobile ul.level-1 > li { position: relative; background: var(--color-white-md); }
.nav-mobile ul > li.has-sub > a { display: inline-block; width: calc(100% - 50px); }
.nav-mobile ul.level-1 > li > a {  padding: 10px 5px; line-height: 1.5; border-left: 5px solid transparent; }
.nav-mobile ul > li.has-sub:before { content: '\e906'; position: absolute;
right: 0; display: block; width: 50px; font-family: 'gs-iconfont'; font-size: 12px; line-height: 1.5; text-align: center; top: 10px; transition: .3s all; }
.nav-mobile ul > li.has-sub.is-active:before { transform: scaleY(-1); }
.nav-mobile ul.level-1 > li.is-active > a { border-color: inherit; }
.nav-mobile ul li.is-active > ul { display: block; padding-left: 10px; background: #fff; }
.nav-mobile ul li.is-active > ul > li { animation: fadeIn 0.75s forwards; }
.nav-mobile ul li.is-active > ul > li.is-active,
.nav-mobile ul li.is-active > ul > li:hover { color: inherit; }
.nav-mobile ul li.is-active > ul > li > a { font-family: var(--font-primary); }
.nav-mobile ul li a { display: block; padding: 10px 0; font-family: var(--font-secondary); text-transform: uppercase; color: inherit; text-align: left; font-size: 16px; line-height: 1.31; }

[data-color="1"]:hover,
[data-color="1"].selected { color: var(--color-category-1); border-color: var(--color-category-1); }
[data-color="2"]:hover,
[data-color="2"].selected { color: var(--color-category-2); border-color: var(--color-category-2); }
[data-color="3"]:hover,
[data-color="3"].selected { color: var(--color-category-3); border-color: var(--color-category-3); }
[data-color="4"]:hover,
[data-color="4"].selected { color: var(--color-category-4); border-color: var(--color-category-4); }
[data-color="5"]:hover,
[data-color="5"].selected { color: var(--color-category-5); border-color: var(--color-category-5); }
[data-color="6"]:hover,
[data-color="6"].selected { color: var(--color-category-6); border-color: var(--color-category-6); }
[data-color="7"]:hover,
[data-color="7"].selected { color: var(--color-category-7); border-color: var(--color-category-7); }
[data-color="8"]:hover,
[data-color="8"].selected { color: var(--color-category-8); border-color: var(--color-category-8); }
[data-color="9"]:hover,
[data-color="9"].selected { color: var(--color-category-9); border-color: var(--color-category-9); }
[data-color="10"]:hover,
[data-color="10"].selected { color: var(--color-category-10); border-color: var(--color-category-10); }
[data-color="11"]:hover,
[data-color="11"].selected { color: var(--color-category-11); border-color: var(--color-category-11); }
[data-color="12"]:hover,
[data-color="12"].selected { color: var(--color-category-12); border-color: var(--color-category-12); }



/* brand colors */
.nav-primary .color-category-1.selected > a,
.nav-primary .color-category-1:hover > a { background: var(--color-category-1); color: #fff; }
.nav-primary .color-category-1 .btn-dropdown { color: var(--color-category-1); }
.nav-primary .color-category-1 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-1 .dropdown-head { border-color: var(--color-category-1); }
.nav-primary .color-category-1 .dropdown-head .icon-svg { fill: var(--color-category-1); }
.nav-primary .color-category-1 .dropdown-head .title { color: var(--color-category-1); }
.nav-primary .color-category-2.selected > a,
.nav-primary .color-category-2:hover > a { background: var(--color-category-2); color: #fff; }
.nav-primary .color-category-2 .btn-dropdown { color: var(--color-category-2); }
.nav-primary .color-category-2 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-2 .dropdown-head { border-color: var(--color-category-2); }
.nav-primary .color-category-2 .dropdown-head .icon-svg { fill: var(--color-category-2); }
.nav-primary .color-category-2 .dropdown-head .title { color: var(--color-category-2); }
.nav-primary .color-category-3.selected > a,
.nav-primary .color-category-3:hover > a { background: var(--color-category-3); color: #fff; }
.nav-primary .color-category-3 .btn-dropdown { color: var(--color-category-3); }
.nav-primary .color-category-3 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-3 .dropdown-head { border-color: var(--color-category-3); }
.nav-primary .color-category-3 .dropdown-head .icon-svg { fill: var(--color-category-3); }
.nav-primary .color-category-3 .dropdown-head .title { color: var(--color-category-3); }
.nav-primary .color-category-4.selected > a,
.nav-primary .color-category-4:hover > a { background: var(--color-category-4); }
.nav-primary .color-category-4 .btn-dropdown { color: var(--color-category-4); }
.nav-primary .color-category-4 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-4 .dropdown-head { border-color: var(--color-category-4); }
.nav-primary .color-category-4 .dropdown-head .icon-svg { fill: var(--color-category-4); }
.nav-primary .color-category-4 .dropdown-head .title { color: var(--color-category-4); }
.nav-primary .color-category-5.selected > a,
.nav-primary .color-category-5:hover > a { background: var(--color-category-5); color: #fff; }
.nav-primary .color-category-5 .btn-dropdown { color: var(--color-category-5); }
.nav-primary .color-category-5 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-5 .dropdown-head { border-color: var(--color-category-5); }
.nav-primary .color-category-5 .dropdown-head .icon-svg { fill: var(--color-category-5); }
.nav-primary .color-category-5 .dropdown-head .title { color: var(--color-category-5); }
.nav-primary .color-category-6.selected > a,
.nav-primary .color-category-6:hover > a { background: var(--color-category-6); color: #fff; }
.nav-primary .color-category-6 .btn-dropdown { color: var(--color-category-6); }
.nav-primary .color-category-6 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-6 .dropdown-head { border-color: var(--color-category-6); }
.nav-primary .color-category-6 .dropdown-head .icon-svg { fill: var(--color-category-6); }
.nav-primary .color-category-6 .dropdown-head .title { color: var(--color-category-6); }
.nav-primary .color-category-7.selected > a,
.nav-primary .color-category-7:hover > a { background: var(--color-category-7); color: #fff; }
.nav-primary .color-category-7 .btn-dropdown { color: var(--color-category-7); }
.nav-primary .color-category-7 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-7 .dropdown-head { border-color: var(--color-category-7); }
.nav-primary .color-category-7 .dropdown-head .icon-svg { fill: var(--color-category-7); }
.nav-primary .color-category-7 .dropdown-head .title { color: var(--color-category-7); }
.nav-primary .color-category-8.selected > a,
.nav-primary .color-category-8:hover > a { background: var(--color-category-8); color: #fff; }
.nav-primary .color-category-8 .btn-dropdown { color: var(--color-category-8); }
.nav-primary .color-category-8 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-8 .dropdown-head { border-color: var(--color-category-8); }
.nav-primary .color-category-8 .dropdown-head .icon-svg { fill: var(--color-category-8); }
.nav-primary .color-category-8 .dropdown-head .title { color: var(--color-category-8); }
.nav-primary .color-category-9.selected > a,
.nav-primary .color-category-9:hover > a { background: var(--color-category-9); color: #fff; }
.nav-primary .color-category-9 .btn-dropdown { color: var(--color-category-9); }
.nav-primary .color-category-9 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-9 .dropdown-head { border-color: var(--color-category-9); }
.nav-primary .color-category-9 .dropdown-head .icon-svg { fill: var(--color-category-9); }
.nav-primary .color-category-9 .dropdown-head .title { color: var(--color-category-9); }
.nav-primary .color-category-10.selected > a,
.nav-primary .color-category-10:hover > a { background: var(--color-category-10); color: #fff; }
.nav-primary .color-category-10 .btn-dropdown { color: var(--color-category-10); }
.nav-primary .color-category-10 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-10 .dropdown-head { border-color: var(--color-category-10); }
.nav-primary .color-category-10 .dropdown-head .icon-svg { fill: var(--color-category-10); }
.nav-primary .color-category-10 .dropdown-head .title { color: var(--color-category-10); }
.nav-primary .color-category-11.selected > a,
.nav-primary .color-category-11:hover > a { background: var(--color-category-11); color: #fff; }
.nav-primary .color-category-11 .btn-dropdown { color: var(--color-category-11); }
.nav-primary .color-category-11 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-11 .dropdown-head { border-color: var(--color-category-11); }
.nav-primary .color-category-11 .dropdown-head .icon-svg { fill: var(--color-category-11); }
.nav-primary .color-category-11 .dropdown-head .title { color: var(--color-category-11); }
.nav-primary .color-category-12.selected > a,
.nav-primary .color-category-12:hover > a { background: var(--color-category-12); color: #fff; }
.nav-primary .color-category-12 .btn-dropdown { color: var(--color-category-12); }
.nav-primary .color-category-12 .btn-dropdown:hover { color: #000; }
.nav-primary .color-category-12 .dropdown-head { border-color: var(--color-category-12); }
.nav-primary .color-category-12 .dropdown-head .icon-svg { fill: var(--color-category-12); }
.nav-primary .color-category-12 .dropdown-head .title { color: var(--color-category-12); }
.header-bottom .btn-login,
.header-bottom .nav-cart { display: inline-block; background: none; vertical-align: middle; }

/* nav-cart */
.nav-cart { position: relative; }
.nav-cart-head { position: relative; padding: 20px; display: block; }
.nav-cart-head .icon { fill: #000; }
.nav-cart-head sup { position: absolute; top: 10px; right: 15px; font-family: var(--font-primary); font-size: 12px; line-height: 1.3; font-weight: 300; color: #000; font-style: normal; }
.nav-cart-head.is-disabled { cursor: default; }
.nav-cart-body { position: absolute; top: 0; right: 0; z-index: 1; max-width: 100vw; min-height: 300px; width: 479px; visibility: hidden; }
.nav-cart-body:before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: var(--color-primary); }
.nav-cart-summary { position: relative; padding: 27px; color: #fff; font-size: 13px; line-height: 1.2; }
.nav-cart-summary .title { margin: 0 50px; font-family: var(--font-secondary); font-size: 24px; line-height: 1.3; color: #fff; text-transform: uppercase; }
.nav-cart-summary .btn-close { position: absolute; top: 20px; right: 17px; display: block; padding: 10px; }
.nav-cart-summary .btn-primary { background: #fff; color: var(--color-secondary); }
.nav-cart-summary .btn-primary.not-active { background-color: #6f6666; pointer-events: none; }
.nav-cart-summary .btn-link { line-height: 44px; color: #fff; }
.nav-cart-summary .table-cart tfoot td:last-child { color: #fff; }
.nav-cart-summary-bottom { overflow: hidden; padding: 20px 0 3px; }
.nav-cart-summary-bottom .msg { text-align: left; }
.nav-cart-summary-bottom .btn-link { float: left; }
.nav-cart-summary-bottom .btn-primary { float: right; }
.nav-cart-summary .nav-cart-head { position: absolute; padding: 0;  }
.nav-cart-summary .nav-cart-head .icon-cart,
.nav-cart-summary .nav-cart-head sup { fill: #fff; color: #fff; }
.nav-cart-summary .nav-cart-head sup { top: -.75em; right: -0.5em; }


/* nav-cart slide in effect */
.nav-cart-head {
	backface-visibility: hidden;
	transition: none;
}
.nav-cart-head.is-active .icon-cart,
.nav-cart-head.is-active sup {
 	color: #fff;
	fill: #fff;
}
.nav-cart-body { overflow: hidden; pointer-events: none; }
.nav-cart-body.is-active { visibility: visible; pointer-events: auto; }
.nav-cart-body:before {
	backface-visibility: hidden;
	transition: transform 0.6s cubic-bezier(0.2, 1, 0.3, 1);
	transform: translate(100%, -100%) translate(-68px, 68px);
	transform: translate3d(100%, -100%, 0) translate3d(-68px, 68px, 0);
}
.nav-cart-body.is-active:before {
	transform: translate(0);
	transform: translate3d(0, 0, 0);
}
.nav-cart-body .nav-cart-summary { opacity: 0; }
.nav-cart-body.is-active .nav-cart-summary {
	opacity: 1;
	transition: opacity 0.6s 0.3s cubic-bezier(0.2, 1, 0.3, 1);
}

/* nav-secondary */
.nav-secondary { overflow: hidden; padding: 0 8px; }
.nav-secondary ul { float: left; }
.nav-secondary ul li { display: inline-block; vertical-align: top; }
.nav-secondary ul li a { display: block; padding: 11px 14px; font-size: 13px; line-height: 18px; color: #939393; }
.nav-secondary ul .selected a,
.nav-secondary ul li a:hover { color: #000; }

.nav-lang ul li { display: inline-block; vertical-align: top; }
.nav-lang ul li a { display: block; padding: 11px 6px; font-size: 13px; line-height: 18px; color: #939393; }
.nav-lang ul li:last-child a { padding-right: 0; }
.nav-lang ul .selected a,
.nav-lang ul li a:hover { color: #000; }


/* nav-aside */
.nav-aside { position: relative; margin-right: 15px; margin-bottom: 10px; line-height: 1.2; }
.nav-aside.has-flag { padding-bottom: 10px; }
.nav-aside:before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 1px solid #e6e6e6; border-radius: 4px; z-index: -1; pointer-events: none; }
.nav-aside > ul { margin-top: -2px; padding-bottom: 10px; border: 1px solid var(--color-neutral-mid); border-top: none; }
.nav-aside-head { position: relative; display: block; background: var(--color-neutral-mid); border: 0; border-radius: 5px; padding: 5px; }
.nav-aside-head .title { padding: 5px 10px; font-size: 14px; font-weight: 700; color: #000; }
.nav-aside-head:before,
.nav-aside-head:after { content: ''; position: absolute; top: calc(50% - 1px); right: 10px; background: #000; }
.nav-aside-head:before { width: 18px; height: 2px; }
.nav-aside-head:after { width: 2px; height: 18px; top: calc(50% - 9px); right: 18px; opacity: 1; transition: .25s all;}
.nav-aside.has-flag-mobile .nav-aside-head:after,
.nav-aside.has-flag .nav-aside-head:after { opacity: 0; transform: rotate(-90deg); }
.nav-aside .nav-aside-body { display: none; padding-left: 15px; }
.nav-aside.has-flag .nav-aside-body { display: block; padding-top: 5px; }
.nav-aside li { color: var(--color-text); padding-left: 15px; }
.nav-aside li.dropdown { padding-left: 0; }
.nav-aside li > a { padding: 10px 0; font-size: 14px; line-height: 1.75; font-weight: 700; color: inherit; vertical-align: middle; }
.nav-aside li > [class*="level-"] { display: none; padding-left: 15px; }
.nav-aside li.dropdown:before { content:''; display: inline-block; margin-right: 5px; font-size: 12px; line-height: 1; border-width: 0.4em; border-bottom-width: 0; border-style: solid; border-color: var(--color-text) transparent transparent transparent; transition: 0.4s all; vertical-align: middle; }
.nav-aside li.selected { color: var(--color-link); }
.nav-aside li.dropdown.selected:before { border-bottom-width: 0.4em; border-top-width: 0; border-color: transparent transparent var(--color-link) transparent; }
.nav-aside li.selected > [class*="level-"] { display: block; }




/* nav-content */
.nav-content { display: none; border-bottom: 1px solid #dbdbdb; }
.nav-content ul li { position: relative; display: inline-block; vertical-align: top; }
.nav-content ul li a { display: block; padding: 0 18px; font-family: var(--font-secondary); font-size: 13px; line-height: 48px; color: var(--color-neutral-dk); text-transform: uppercase; }
.nav-content ul li a:before { content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1.5px; background: #251F20; transition: all 0.3s; opacity: 0; visibility: hidden; transform: scaleX(0.7); }
.nav-content ul li.selected a,
.nav-content ul li a:hover { color: #000; }
.nav-content ul li.selected a:before,
.nav-content ul li a:hover:before { opacity: 1; visibility: visible; transform: scaleX(1); }
.nav-content ul { display: grid; grid-template-columns: repeat(2, 1fr); }

/* menu */
.menu { display: none; background: var(--color-primary); box-shadow: 0 0 0 2px #fff; }
.menu.is-active { position: fixed; width: 100%; max-height: 92.5vh; overflow: auto; display: block; animation: fadeIn 0.3s ease-in-out 1; }
@media (min-width: 1200px) {
	/*.menu.is-active { display: none; }*/
}
.menu nav a { display: block; padding: 12px 10px; font-size: 14px; line-height: 18px; color: #fff; }
.menu .header-greet { padding: 10px; background: var(--color-tertiary); color: #fff; }
.menu .header-greet a { display: inline; padding: 0; color: var(--color-neutral-mid); }

/* nav-primary-mobile */
.nav-primary-mobile li { position: relative; }
.nav-primary-mobile .has-dropdown { padding-right: 40px; /*background: var(--color-secondary);*/ }
.nav-primary-mobile .has-dropdown > .dropdown,
.nav-primary-mobile .has-dropdown > ul { display: none; margin-right: -40px; }
.nav-primary-mobile .has-dropdown:before,
.nav-primary-mobile .has-dropdown:after { content: ''; position: absolute; top: 19px; right: 14px; background: #fff; }
.nav-primary-mobile .has-dropdown:before { width: 10px; height: 2px; margin-top: 4px; }
.nav-primary-mobile .has-dropdown:after { width: 2px; height: 10px; margin-right: 4px; }
.nav-primary-mobile .has-dropdown.is-active > .dropdown,
.nav-primary-mobile .has-dropdown.is-active > ul { display: block; }
.nav-primary-mobile .has-dropdown.is-active:after { opacity: 0; visibility: hidden; }
.nav-primary-mobile a { background: var(--color-primary); text-transform: uppercase; }
.nav-primary-mobile .dropdown-head { display: none; }
.nav-primary-mobile ul ul { padding-left: 10px; }
.nav-primary-mobile .level-2 a { background: #fff; color: #000; }
.nav-primary-mobile .level-3 { display: none; }
.nav-primary-mobile .level-3 a { background: var(--color-secondary); color: #fff; }
.nav-primary-mobile .level-1 .selected > a { background: var(--color-secondary); color: #fff; }
.nav-primary-mobile .level-2 .selected > a { background: var(--color-tertiary); }
.nav-primary-mobile .level-3 .selected > a { background: var(--color-tertiary); }

/* nav-secondary-mobile */
.nav-secondary-mobile ul + ul { display: none; }
.nav-secondary-mobile a { text-transform: uppercase; }
.nav-secondary-mobile .nav-phone { display: none; }
.nav-secondary-mobile .nav-phone .icon-phone { height: 20px; width: 15px; fill: #fff;}

/* ------------------------------------------------------------ *\
	#article
\* ------------------------------------------------------------ */
.article h1,
.article h2,
.article h3,
.article h4,
.article h5,
.article h6 { margin-bottom: 10px; font-family: var(--font-secondary); line-height: 1.3; color: var(--color-titles); font-weight: normal; text-transform: uppercase; }

.article h1 { font-size: 24px; line-height: 1.2; }
.article h2 { font-size: 22px; line-height: 1.2; }
.article h3 { font-size: 18px; }
.article h4 { font-size: 17px; }
.article h5 { font-size: 16px; }
.article h6 { font-size: 15px; }

.article:after { @apply --clearfix; }

.article { font-size: var(--font-size-primary); line-height: 18px; color: var(--color-text); }
.article * { max-width: 100%; }
.article hr { display: block; border: none; width: 100%; height: 1px; margin: 10px 0; background: var(--color-text); }
.article a:not(.btn-article):not(.btn-primary) { position: relative; color: var(--color-link); text-decoration: underline; transition: all 0.3s; }
.article a:hover { text-decoration: none; }

.article strong { color: #000; }
.article p { padding-bottom: 10px; }
.article p + ul,
.article p + ol { margin-top: -10px; }
.article ul { list-style: none; padding-bottom: 10px; }
.article ul	li { position: relative; padding: 0 0 5px 20px; }
.article ul	li:before { content: '•'; position: absolute; top: 0; left: 0; font-size: 24px; line-height: inherit; }
.article ul ul { padding-top: 10px; padding-bottom: 0; }
.article ol { list-style-position: inside; padding-bottom: 10px; }
.article ol li { padding: 0 0 5px 0; }
.article blockquote { position: relative; z-index: 1; display: block; margin: 60px 0 40px; padding: 0 80px; font-size: 24px; line-height: 30px; color: var(--color-text); }
.article blockquote:before { content: '"'; position: absolute; top: -76px; left: -23px; z-index: -1; font-size: 200px; line-height: 1; color: var(--color-secondary); }

.article table { margin-bottom: 10px; }

.table-basic { width: 100%; margin-bottom: 20px; font-size: 14px; line-height: 18px; color: #000; }
.table-basic th,
.table-basic td { padding: 10px; color: #000; text-align: center; }
.table-basic th { border-bottom: 2px solid #000; font-size: 18px; }
.table-basic td { border-bottom: 1px solid #cfcfcf; }
.table-basic th:first-child,
.table-basic td:first-child { text-align: left; }
.table-basic tr:hover td { background: #f5f5f5; }

.table-grid,
.table-grid thead,
.table-grid tbody,
.table-grid tr,
.table-grid th,
.table-grid td { box-sizing: border-box;	}
.table-grid th,
.table-grid td { vertical-align: top; }

.article-404 { text-align: center; }

/* ------------------------------------------------------------ *\
	#breadcrumbs
\* ------------------------------------------------------------ */
.breadcrumbs ul li:before,
.breadcrumbs ul li:after,
.breadcrumbs ul li a,
.breadcrumbs ul li { transition: all 0.3s; }
.breadcrumbs ul li:last-child { display: block; font-family: var(--font-secondary); text-transform: uppercase; color: #fff; font-size: 24px; line-height: 1.35; }
.breadcrumbs ul li:last-child:before { content: none; }

.breadcrumbs ul { padding: 4px 0; }
.breadcrumbs ul li { display: inline; font-size: 12px; line-height: 14px; color: #fff; }
.breadcrumbs ul li a { color: inherit; }

/* ------------------------------------------------------------ *\
	#paging
\* ------------------------------------------------------------ */
.paging li,
.paging a { transition: all 0.3s; }

.paging ul li { display: inline-block; vertical-align: top; font-size: 14px; line-height: 1; color: #a9a9a9; font-weight: 500; }
.paging ul li span,
.paging ul li a { display: block; border-radius: 5px; padding: 8px; }
.paging ul li a { color: inherit; }
.paging ul .selected a { color: #000; }
.paging ul li a:hover { background: var(--color-primary); color: #fff; }
.paging .prev a,
.paging .next a { position: relative; padding: 10px; }
.paging .prev a:before,
.paging .next a:before { content: ''; display: block; width: 0; height: 0; border-width: 5px; border-style: solid; }
.paging .prev a:before { border-color: transparent #000 transparent transparent; }
.paging .prev a:hover:before { border-color: transparent #fff transparent transparent; }
.paging .next a:before { border-color: transparent transparent transparent #000; }
.paging .next a:hover:before { border-color: transparent transparent transparent #fff; }

/* ------------------------------------------------------------ *\
	#socials
\* ------------------------------------------------------------ */
.socials { font-size: 0; line-height: 0; }
.socials-item { position: relative; display: inline-block; vertical-align: top; margin: 5px; transition: all 0.3s; }
.socials-item span { color: inherit; }
.socials-item .icon-svg { position: relative; z-index: 1; width: 24px; height: 24px; fill: var(--color-neutral-lt); backface-visibility: hidden; }

.facebook { color: #3b5998; }
.facebook .icon-svg { fill: #3b5998; }
.google { color: #dd4b39; }
.google .icon-svg { fill: #dd4b39; }
.twitter { color: #55acee; }
.twitter .icon-svg { fill: #55acee; }
.linkedin { color: #007bb5; }
.linkedin .icon-svg { fill: #007bb5; }
.youtube { color: #bb0000; }
.youtube .icon-svg { fill: #bb0000; }
.instagram { color: #125688; }
.instagram .icon-svg { fill: #125688; }

.socials-footer { margin: 0 -10px; }
.socials-footer .socials-item { margin: 10px; }
.socials-footer .socials-item:before { content: ''; position: absolute; top: 1px; left: 1px; z-index: -1; width: 22px; height: 22px; border-radius: 50%; background: #fff; transition: all 0.3s; }
.socials-footer .socials-item:hover .icon-svg { fill: #fff; }
.socials-footer .socials-item span { display: inline-block; vertical-align: top; padding: 0 5px; font-size: 14px; line-height: 24px; font-weight: bold; }
.socials-footer .socials-item:hover { color: #fff; }
.socials-footer .socials-item:hover:before { opacity: 0; visibility: hidden; }

.socials-share > span { display: inline-block; vertical-align: top; margin: 5px 0; font-size: 14px; line-height: 24px; color: #6c6c6c; }
.socials-share .socials-item:hover { transform: translateY(-3px); }

/* ------------------------------------------------------------ *\
	#tables
\* ------------------------------------------------------------ */
.table { width: 100%; border-collapse: collapse; border-spacing: 0; }

.table-cart-quick tr { display: grid; grid-template-columns: 2fr 0.25fr 1fr; border-bottom: 1px solid #d4ddb4; }
.table-cart-quick td { vertical-align: top; padding: 5px 0; text-align: right; font-family: var(--font-secondary); }
.table-cart-quick tfoot tr:last-child td:last-child { font-family: var(--font-secondary); font-size: 24px; }
.table-cart-quick tfoot tr:first-child td:last-child { font-size: 16px; }
.table-cart-quick thead tr { padding: 20px 0.25rem 10px; }
.table-cart-quick tbody tr { padding: 0.25rem; }
.table-cart-quick tfoot tr { border: none; padding: 0.25rem; }
.table-cart-quick tfoot tr:first-child { padding: 1rem 0.25rem 0; }
.table-cart-quick th,
.table-cart-quick tbody td { display: block; }
.table-cart-quick th:last-child,
.table-cart-quick td:last-child { min-width: 100px; text-align: right; }
.table-cart-quick th:first-child,
.table-cart-quick td:first-child { text-align: left; font-size: 14px;}
.table-cart-quick thead th,
.table-cart-quick tfoot td { color: #fff; font-weight: normal; }
.table-cart-quick tbody td:nth-child(3) ~ td:nth-child(even) { grid-column: 1 / 3; text-align: left; }
.table-cart-quick tbody td:nth-child(3) ~ td:nth-child(odd) { grid-column: 3 / 3; text-align: right; }
.table-cart-quick tfoot td:nth-child(even) { grid-column: 3 / 3; text-align: right; }
.table-cart-quick tfoot td:nth-child(odd) { grid-column: 1 / 3; text-align: left; }

.table-cart th { background: var(--color-neutral-dk); color: #fff; font-weight: normal; }
.table-cart th,
.table-cart td { vertical-align: top; border-bottom: 1px solid var(--color-neutral-mid); padding: 15px; font-size: 14px; line-height: 18px; text-align: left; }
.table-cart tr:nth-child(2n) td { background: var(--color-white-md); }
.table-cart .picture { position: relative; float: left; display: block; border: 1px solid var(--color-neutral-mid); margin-right: 10px; }
.table-cart .picture .ribbon-promo { position: absolute; top: 5px; left: 5px; }
.table-cart .picture .ribbon-promo .icon-svg { width: 20px; height: 20px; }
.table-cart .title { overflow: hidden; padding: 15px 0; font-family: var(--font-primary); font-size: 12px; line-height: 18px; color: #000;}
.table-cart .title a { color: inherit; text-decoration: none;  text-transform: uppercase; font-weight: 700; }
.table-cart .msg { overflow: hidden; }
.table-cart .price { padding: 15px 0; font-size: 12px; line-height: 18px; color: #777; white-space: nowrap; }
.table-cart .price strong { font-family: var(--font-secondary); font-size: 12px; line-height: 18px; color: #000; text-transform: uppercase; font-weight: normal; }
.table-cart .field-number { max-width: 120px; }
.table-cart .note { padding: 10px 0; }



/* ------------------------------------------------------------ *\
	#forms
\* ------------------------------------------------------------ */
.related-target { display: none; }
.related-target.is-active { display: block; }

.form .form-label { display: block; padding-bottom: 5px; font-size: 14px; line-height: 1.3; }
.form .form-label.required:after { content: '*'; }
.form hr { display: block; border: 0; height: 1px; margin: 15px 0; background: var(--color-neutral-dk); }
.form .form-row + hr { margin-top: 0; }
.form .captcha { position: relative; height: 78px; }
@media (max-width: 360px) {
	.form .captcha img,
	.form .captcha > div { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.9); transform: translate3d(-50%, -50%, 0) scale3d(0.9, 0.9, 1); }
}
.form .form-title { margin-bottom: 15px; font-family: var(--font-secondary); font-size: 18px; line-height: 1.3; color: var(--color-titles); text-transform: uppercase; }
.form .form-hint { font-size: 12px; line-height: 18px; color: var(--color-text); }
.form .form-hint--red {color: var(--color-category-7); padding-left: 10px;}
.form .form-hint--hide { display: none; }
.form .form-group { padding: 15px 0; }
.form .choose-group { padding: 15px 0 5px; }
.form .choose-group-stripped .choose { border-bottom: 1px solid var(--color-neutral-dk); padding-bottom: 15px; margin-bottom: 15px; }
.form .choose-group-stripped .form-hint { float: right; }
.form .choose-group-stripped .badge-payment { float: right; margin-top: -5px; }

.field-number input { padding-right: 60px; text-align: center; }
.field-number .postfix { position: absolute; top: 50%; right: 28%; transform: translate3d(0, -50%, 0) translate3d(0, 1px, 0); font-size: 12px; line-height: 13px; color: #77797C; text-transform: uppercase; }

.field.field-spinner { width: 84px; }
.field.field-spinner .btn { display: block; width: 22px; height: 21px; border: 0; border-radius: 0; padding: 0; margin: 0; font-size: 20px; line-height: 1; color: #000; font-weight: bold; text-align: center; }
.field.field-spinner .btn i { display: none; }
.field.field-spinner .btn:hover { background: var(--color-primary); color: #fff; }
.field.field-spinner { position: relative; }
.field.field-spinner input { border-radius: 5px; padding-left: 15px; font-weight: bold; color: #000; }
.field.field-spinner .input-group-btn-vertical { position: absolute; top: 1px; right: 1px; bottom: 1px; width: auto; display: block; }
.bootstrap-touchspin-up:before { content: '+'; }
.bootstrap-touchspin-down:before { content: '–'; }
.bootstrap-touchspin-postfix { position: absolute; top: 50%; right: 24px; margin-top: 1px; transform: translateY(-50%); }

.form .choose .form-toggle { display: none; }
.form .choose .form-toggle > .pad-basic { margin-top: 0; }
.form .choose input:checked ~ .form-toggle { display: block; }
.form .choose input:checked ~ .form-toggle > .pad-basic { background: var(--color-primary); }
.form .choose input:checked ~ .form-toggle > .pad-basic .text { color: #fff; }
.form .choose #office_address { padding-left: 20px; }
.form .choose #office_address:placeholder-shown { font-style: italic;}

.form .office-wrapper {display: flex;}
.form .office-wrapper .choose { margin-right: 20px; }
.form .office-fields { padding-top: 2px; }

.form .choose .form-toggle { display: none; }
.form .choose .form-toggle.is-active { display: block; }
.form .choose .form-toggle.is-active > .pad-basic { margin-top: 0; }
.form .choose .form-toggle.is-active > .pad-basic { background: var(--color-primary); }
.form .choose .form-toggle.is-active > .pad-basic .text { color: #fff; }

.form-basic { padding: 15px; }
.form-basic .form-title { text-align: center; }
.form-basic .form-row { padding-bottom: 10px; }
.form-basic .form-bottom { overflow: hidden; padding: 10px 0; }
.form-basic .captcha { text-align: center; margin-bottom: 20px; }
.form-basic .btns { text-align: center; }
.form-basic .form-row + .btns { padding-top: 20px; text-align: center; }

.form-profile .form-label { font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.form-profile .form-row { padding-bottom: 15px; }

#js-form-filter .sidebar { position: sticky; top: 1rem; }
.filters-aside { margin-right: 15px; vertical-align: top; }
.filters-aside .btn-secondary { width: 100%; box-shadow: none; background: #d8d8d8; font-weight: 400; font-family: var(--font-primary); font-size: 16px; text-transform: none; }
.filters-aside .btn-secondary:hover { background: #a0a0a0; color: #fff; }
.filters-aside-head { display: none; }
.filters-aside-head .title { display: inline-block; width: calc(100% - 30px); vertical-align: middle; box-sizing: border-box; }
.filters-aside-head .icon-svg { width: 16px; height: 17px; padding: 0 5px;  display: inline-block; vertical-align: middle; }
.form-filters-aside { padding: 10px 0; }
.form-filters-aside .choose label { font-weight: 500; }
.form-filters-aside .choose .qty { color: #b6b6b6; }
.filters-aside-item { position: relative; padding: 16px 0; border-bottom: 1px solid #DBDBDB; line-height: 1.2; }
.filters-aside-item.filter-categories { border-top: 1px solid #dbdbdb; }
.filters-aside-item.price .filters-aside-item-body { height: auto; max-height: 175px; }
.filters-aside-item-head { position: relative; cursor: pointer; }
.filters-aside-item-head .title { font-size: 16px; font-weight: 600; color: #251F20; text-transform: uppercase; }
.filters-aside-item-head::after {
	content: url("../../../images/arrow-down.svg");
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transition: transform 0.3s ease-in-out;
}
.filters-aside-item.is-active .filters-aside-item-head:after { transform: translateY(-50%) rotate(-180deg); }
.filters-aside-item-body { display: none; position: relative; overflow: auto; max-width: calc(100% - 5px); }
.filters-aside-item.is-active .filters-aside-item-body { display: block; }
/* .filters-aside-item.is-active .filters-aside-item-body.hidden { display: none; } */
.filters-aside-item.is-active .filters-aside-item-body-special_offers-choose { display: block; }
.filters-aside-item.is-active .filters-aside-item-body-special_offers-choose.hidden { display: none; }
.filters-aside-item-body.hidden { display: none; }
.filters-aside-item-body::-webkit-scrollbar { width: 4px; border-radius: 36px; }
.filters-aside-item-body::-webkit-scrollbar-track { box-shadow: 0 4px 1px 2px var(--color-neutral-mid); background: var(--color-neutral-mid); }
.filters-aside-item-body::-webkit-scrollbar-thumb { max-height: 5%; background: #6e6e6e; border-radius: 60px; }
.filters-aside-item-body::-webkit-scrollbar-track-piece:start { margin-top: 5px; }
.filters-aside-item-body::-webkit-scrollbar-track-piece:end { margin-bottom: 5px; }
.filters-aside-item.has-flag .filters-aside-item-head:after { opacity: 0; }
.filter-price { padding-top: 25px; }
.filter-price .noUi-target{ margin-bottom: 17px; }
.filter-price .filter-price-fields { display: grid; grid-template-columns: repeat(2, 50%); font-size: 0; line-height: 0; }
.filter-price .field-price { display: flex; flex-direction: column; width: 100%; font-size: 0; line-height: 0;  text-align: center; }
.filter-price .field-price input,
.filter-price .field-price label { display: inline-block; max-width: 50%; font-size: 14px; line-height: 1.2; text-align: left;}
.filter-price .field-price label { margin-bottom: 4px; }
.filter-price .field-price input { padding: 5px; border-radius: 100px; border: 1px solid #DBDBDB;  text-align: center; }

#filter-total_1 { display: none;}
.filter-total p { padding: 10px 0; display: inline-block; }
.filter-total .filter-svg { padding-right: 10px; }
.filter-total__element { display: flex; align-items: center; }
.filter-total__element .filter-total__element__content { display:inline-flex; align-items: center; margin-right: 5px; border-radius: 100px; padding: 7px; font-size: 14px; line-height: 1.25; color: #251F20; background-color: #F7F7F7; white-space: nowrap; }
.filter-total__element .filter-total__element__content:after { content: url("../../../images/filters-close.svg"); display: inline-block; vertical-align: middle; width: 20px; height: 20px; margin-left: 0.5em; }
.filter-total__element img { padding: 2px 4px; display:inline-block; }
.filter-total__element p { display: inline-block; padding: 0; }
.filter-total__clear-button { display: inline-block; border: none; background: none; color: #a6ce39; padding: 10px; font-size: 14px; }

#filter-total_1 { display: none;}
.filter-total p { padding: 10px 0; display: inline-block; }
.filter-total .filter-svg { padding-right: 10px; }
.filter-total__element img { padding: 2px 4px; display:inline-block; }
.filter-total__element p { display: inline-block; padding: 0; }
.filter-total__clear-button { display: inline-block; border: none; background: none; color: #a6ce39; padding: 10px; font-size: 14px; cursor: pointer; }

.form-products-add .field { position: relative; }
.form-products-add .field.field-spinner { width: auto; }
.form-products-add .btn-primary { width: 100%; }
.form-products-add .btn-primary.not-active { pointer-events: none; background-color: #6f6666; color: #000; }

.form-login { position: relative; }
.form-login:before { content: ''; position: absolute; top: 0; right: -15px; bottom: 0; width: 1px; background: var(--color-neutral-lt); }
.form-login .form-title { margin-bottom: 20px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.form-login .form-row { padding-bottom: 15px; }
.form-login .form-label { padding-bottom: 8px; }
.form-login .btns { overflow: hidden; }
.form-login .btn-link { float: left; font-family: var(--font-secondary); font-size: 12px; line-height: 44px; color: var(--color-tertiary); text-transform: uppercase; text-decoration: none; }
.form-login .btn-link:hover { text-decoration: none; }
.form-login .btn-primary { float: right; min-width: 93px; }

.form-newsletter { margin-bottom: 40px; }
.form-newsletter .form-note { margin-bottom: 25px; }
.form-newsletter .form-title + .form-note { margin-top: -10px; }
.form-newsletter .pad-basic { max-width: 598px; padding: 18px 20px 28px; }
.form-newsletter .form-controls { display: flex; }
.form-newsletter .field { flex: 1; }
.form-newsletter .btn { min-width: 146px; margin-left: 8px; }
.form-newsletter .form-label { padding-bottom: 15px; }

.form-personal { padding-bottom: 30px; }
.form-personal .pad-basic { padding: 15px; }
.form-personal .btns { padding: 30px 0 15px; text-align: center; }

.form-legal { padding-bottom: 30px; }
.form-legal .pad-basic { padding: 15px; }
.form-legal .btns { padding: 30px 0 15px; text-align: center; }

.form-password-change .pad { padding: 18px 20px 28px; }
.form-password-change .btns { padding: 50px 0; text-align: center; }
.form-password-change .btn-primary { min-width: 224px; }

.steps { font-size: 0; line-height: 0; margin: 0 -15px; text-align: center }
.steps .steps-item { display: inline-block; vertical-align: top; width: 126px; margin: 15px; text-align: center; text-decoration: none; }
.steps .steps-item .number { display: inline-block; vertical-align: top; width: 38px; height: 38px; border-radius: 50%; border: 1px solid var(--color-neutral-dk); font-size: 18px; line-height: 38px; color: var(--color-neutral-dk); font-weight: 500; text-align: center; }
.steps .steps-item .label { display: block; font-size: 14px; line-height: 18px; color: var(--color-neutral-dk); }
.steps .steps-item.is-completed .number { border-color: transparent; background: var(--color-primary); color: #fff; }
.steps .steps-item.is-completed .label { color: var(--color-primary); }
.steps .steps-item.is-selected .number { border-color: transparent; background: #000; color: #fff; }
.steps .steps-item.is-selected .label { color: #000; }

.form-checkout fieldset { padding: 15px 0 0; }
.form-checkout legend { margin-bottom: 15px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.form-checkout .form-row { padding-bottom: 15px; }
.form-checkout form > .btns { overflow: hidden; padding: 20px 0; }
.form-checkout form > .btns .btn-article { float: left; margin-top: 3px; border-color: transparent; background-color: transparent; }
.form-checkout form > .btns .btn-article:focus,
.form-checkout form > .btns .btn-article:hover { color: var(--color-primary); }
.form-checkout form > .btns .btn-article:focus .icon,
.form-checkout form > .btns .btn-article:hover .icon { border-right-color: var(--color-primary); }
.form-checkout form > .btns .btn-primary { float: right; }

.form-discount { position: relative; padding-bottom: 30px; }
.form-discount .form-row { padding-bottom: 15px; }
.form-discount .btns .btn { display: block; width: 100%; }



/* ------------------------------------------------------------ *\
	#notifications
\* ------------------------------------------------------------ */
.toast-success { background-color: var(--success); }
.toast-error { background-color: var(--error); }
.toast-info { background-color: var(--info); }
.toast-warning { background-color: var(--warning); }

#toast-container > div { box-shadow: none; opacity: 1; }
#toast-container > div:hover { box-shadow: none; opacity: .9; }
#toast-container .drawer-line { width: 100%}
.toast-progress { opacity: .6; height: 3px; background-color: #fff; }

.msg { position: relative; border: 1px solid var(--color-neutral-lt); border-radius: 5px; padding: 20px; margin: 5px 0 25px; background-color: var(--color-neutral-lt); background-repeat: no-repeat; background-position: 20px 20px; font-size: var(--font-size-primary); line-height: 1.2; color: var(--color-text); font-weight: normal; }
.msg .title { margin-bottom: 10px; font-size: 18px; font-weight: bold; }
.msg p { margin-bottom: 10px; }
.msg p:last-child { margin-bottom: 0; }
.msg-success { border-color: var(--success); color: var(--success); }
.msg-error { border-color: var(--error); color: var(--error); }
.msg-info { border-color: var(--info); color: var(--info); }
.msg-warning { border-color: var(--warning); color: var(--warning); }

p.msg { padding: 10px; }

.popup-discount-offer { display: none; position: fixed; top: 50%; left: 50%; background: #95cc25; z-index: 101; box-sizing: border-box; transition: 0.5s all; transform: translate(-50%, -50%); }
.popup-discount-offer.offer { padding: 30px 15px 0; max-width: 550px; min-width: 400px; }
.popup-discount-offer.timer { flex-direction: column; align-items: center; justify-content: center; height: 250px; width: 320px; max-width: 350px; }
.popup-discount-offer.offer.active { display: block; }
.popup-discount-offer.timer.active { display: flex; }
.popup-discount-offer.timer .offer-content,
.popup-discount-offer.offer .timer-content { display: none; }
.popup-discount-offer .expire-msg { display: none; font-size: 24px; line-height: 1.2; font-family: var(--font-secondary); font-weight: 700; color: #000; }
.popup-discount-offer .background { position: absolute; left: 0; top: 0;  width: 100%; height: 100%; object-fit: cover; opacity: 0.1; }
.popup-discount-offer .content { padding: 15px; border: 3px solid #fff; transition: 0.4s all; }
.popup-discount-offer .message { margin-bottom: 15px; font-size: 14px; line-height: 1.2; text-align: center; color: #ffffff; font-family: var(--font-primary); font-weight: 600; }
.popup-discount-offer .message .discount { line-height: 1.2; color: var(--color-secondary); font-style: normal; font-family: var(--font-secondary); }
.popup-discount-offer .message .discount em { display: block; font-size: 56px; font-weight: 700; font-style: normal; }
.popup-discount-offer .message .discount span { font-size: 28px; font-weight: 500; text-transform: uppercase; }
.popup-discount-offer .message hr { border-style: solid; border-color: #fff; margin: 10px 0; }
.popup-discount-offer .btns { text-align: center; }
.popup-discount-offer .btn-primary { margin-bottom: 10px; background-color: var(--color-secondary); color: var(--color-primary); }
.popup-discount-offer .btn-decline { display: block; font-size: 14px; padding: 10px 0; text-align: center; line-height: 1.2; color: var(--color-secondary); text-transform: uppercase; text-decoration: underline; font-weight: 700; font-family: var(--font-secondary); }
.popup-discount-offer .timer-content { font-size: 0; }
.popup-discount-offer .timer-content em { font-size: 86px; line-height: 0.9; font-weight: bold; color: #000; font-style: normal; font-family: var(--font-secondary); transition: 0.2s all; }
.popup-discount-offer .time-formats span { display: inline-block; width: 50%;  font-size: 12px; line-height: 1.2; color: #000; text-align: center; font-family: var(--font-secondary);  }
.popup-discount-offer .timer-content .btn-decline { position: absolute; bottom: 0; left: 15px; display: block; font-size: 14px; padding: 10px 0; text-align: center; line-height: 1.2; color: #fff; }
.popup-discount-offer.bottom { top: auto; bottom: 60px; transform: translate(-50%, 0); }
.popup-discount-offer.shrink { padding: 10px 25px; height: 40px; width: 120px; bottom: 0; top: auto; transition: .3s all ease-out; transition-delay: 0.1s; }
.popup-discount-offer.shrink .timer-content em { font-size: 20px; }
.popup-discount-offer.shrink .time-formats,
.popup-discount-offer.shrink .btn-decline { display: none; }
.popup-discount-offer.expired .timer-content,
.popup-discount-offer.expired .offer-content { display: none; }
.popup-discount-offer.expired .expire-msg { display: block; }
.popup-discount-offer.shrink.expired .expire-msg { font-size: 12px; }

.popup-container { position: fixed; bottom: 20px; left: 20px; background: #9acd00; z-index: 101; box-shadow: 0 0 100px 0px rgba(0, 0, 0, 0.7); }
.popup-container.has-flag { display: none; }
.popup-container figure { position: relative; padding: 20px 20px 0; }
.popup-container figure img { position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; opacity: .1; object-fit: cover;}
.popup-container figure .reg-popup { position: relative; text-align: center; }
.popup-container figure .reg-popup .discount-container { width: 540px; max-width: 100%; box-sizing: border-box; padding: 15px; border: 4px solid #ffffff; }
.popup-container .discount-container .discount-top { margin-bottom: 10px; border-bottom: 2px solid #ffffff; }
.popup-container .discount-container .discount-top span:first-child { margin-bottom: 15px; display: block; font-family: var(--font-primary); font-weight: 700; line-height: 1.2; font-size: 18px; color: #ffffff; }
.popup-container .discount-container .discount-top .discount-persent { margin-bottom: 10px; display: block; font-family: var(--font-secondary); font-weight: 700; line-height: 1.2; font-size: 55px; color: #333333; }
.popup-container .discount-container .discount-top span:last-child { margin-bottom: 10px; display: block; font-family: var(--font-secondary); font-weight: 700; line-height: 1.2; font-size: 20px; text-transform: uppercase; color: #333333; }
.popup-container .discount-container .discount-bottom .offer-description {margin-bottom: 10px; font-family: var(--font-primary); font-weight: 700; line-height: 1.2; font-size: 15px; color: #ffffff;  }
.popup-container figure .reg-popup .btn-decline { padding: 15px 0; font-family: var(--font-secondary); font-weight: 700; line-height: 1.2; font-size: 12px; color: #333333; background: none; border: none; cursor: pointer; text-transform: uppercase; text-decoration: underline; }
.popup-container figure .reg-popup .btn-decline:hover { text-decoration: none; }

/* ------------------------------------------------------------ *\
	#sliders
\* ------------------------------------------------------------ */


/* -- #swiper -- */
.banners-grid .swiper-pagination,
.boxes-special-offers .swiper-pagination,
.boxes-products-full .swiper-pagination,
.boxes-products-promo .swiper-pagination { display: none; }
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper { position: relative; width: 100%; height: 100%; z-index: 1; display: -ms-block; display: block; transition-property: transform; box-sizing: border-box; }
.boxes-categories { margin: 0 -15px; }
.boxes-categories .swiper-navigation { display: none; }
.boxes-categories .cols { margin: 0; }

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets { bottom: 0; }
.swiper-pagination { position: relative; width: 100%; padding: 10px 0; }
.swiper-pagination-bullet { margin: 0 5px; width: 14px;height: 14px; background: none; border-radius: 36px; border-color: #707070; border-style: solid; border-width: 1px; transition: all .3s; }
.swiper-pagination-bullet-active { background: var(--color-primary);  border-color: transparent; }

.swiper-button-next, .swiper-button-prev { color: #000; }
.swiper-button-next:after, .swiper-button-prev:after { font-size: 32px; }

/* -- #slick -- */
.slider-primary { position: relative; z-index: 10; overflow: hidden; width: 100%; }
.slider-primary .slide { background-repeat: no-repeat; background-position: center; background-size: cover; }

.slider-related-products .slider-head { overflow: hidden; }
.slider-related-products .slider-head .title { float: left; margin-bottom: 10px; font-family: var(--font-secondary); font-size: 14px; line-height: 1; color: var(--color-titles); text-transform: uppercase; }
.slider-related-products .slider-head .slider-arrows { float: right; }
.slider-related-products .slider-head .slider-arrow { position: relative; display: inline-block; vertical-align: top; width: 20px; height: 20px; border-radius: 5px; cursor: pointer; }
.slider-related-products .slider-head .slider-arrow:hover { background: var(--color-primary); }
.slider-related-products .slider-head .slider-arrow-prev:hover:before { border-right-color: #fff; }
.slider-related-products .slider-head .slider-arrow-next:hover:before { border-left-color: #fff; }
.slider-related-products .slider-head .slider-arrow:before { position: absolute; top: 50%; left: 50%; content: ''; display: block; width: 0; height: 0; border-width: 5px; border-style: solid; border-color: transparent; transform: translate(-50%, -50%); }
.slider-related-products .slider-head .slider-arrow-prev:before { border-left-width: 0; border-right-color: #000; }
.slider-related-products .slider-head .slider-arrow-next:before { border-right-width: 0; border-left-color: #000; }
.slider-related-products .slides { overflow: hidden; height: 98px; }
.slider-related-products .slides .slide { width: 233px; height: 76px; border: 1px solid var(--color-neutral-mid); padding: 10px; text-decoration: none; }
.slider-related-products .slides .slide:hover { border-color: #000; }
.slider-related-products .slides .slide .picture { float: left; width: 76px; margin-right: 10px; }
.slider-related-products .slides .slide .content { overflow: hidden; }
.slider-related-products .slides .slide .content .title { overflow: hidden; height: 42px; margin: 5px 0 7px; font-family: var(--font-secondary); font-size: 12px; line-height: 14px; color: #000; }
.slider-related-products .slides .slide .content .slider-price { font-size: 12px; line-height: 1; color: #777; }
.slider-related-products .slides .slide .content .slider-price strong { font-family: var(--font-secondary); font-size: 16px; line-height: 1; color: #000; }

.slider-timeline { position: relative; }
.slider-timeline-top { position: relative; z-index: 1; }
.slider-timeline-top .slides { overflow: hidden; height: 675px; }
.slider-timeline-top .slide { position: relative; width: 100%; height: 675px; background-color: #000; background-position: center center; background-repeat: no-repeat; background-size: cover; }
.slider-timeline-top .slide:before { content: ''; position: absolute; top: 0; left: 0; z-index: 1; width: 100%; height: 100%; background: #000; opacity: .7; }
.slider-timeline-top .description { position: absolute; top: 50%; left: 50%; z-index: 2; transform: translate(-50%, -50%); color: #fff; text-align: center; }
.slider-timeline-top .description .title { margin: 20px 0; font-family: var(--font-secondary); font-size: 72px; line-height: 1.2; color: var(--color-primary); }
.slider-timeline-top .description .text { font-size: 14px; line-height: 24px; color: var(--color-white-md); }
.slider-timeline-nav { position: absolute; right: 20px; bottom: 44px; left: 20px; z-index: 2; }
.slider-timeline-nav .slides { overflow: hidden; height: 71px; border-bottom: 1px solid #fff; }
.slider-timeline-nav .slide { float: left; height: 71px; text-decoration: none; text-align: center; cursor: pointer; }
.slider-timeline-nav .slide span { position: relative; display: block; padding: 10px 15px 30px; font-size: 24px; line-height: 1.3; color: var(--color-white-md); transition: all 0.3s; }
.slider-timeline-nav .slide span:before { content: ''; position: absolute; bottom: 0; left: 50%; width: 1px; height: 20px; background: #fff; }
.slider-timeline-nav .slide.slick-current span,
.slider-timeline-nav .slide:hover span { color: var(--color-primary); }
.slider-timeline-nav .slide.slick-current span { font-weight: bold; }


.boxes-products .slick-arrow { z-index: 2; opacity: 1; width: auto; height: auto;  }
.boxes-products .slick-arrow::before { content:''; font-size: 0; }
.boxes-products .slick-prev { left: 0; }
.boxes-products .slick-next { right: 0; }
.boxes-products .slick-next::before,
.boxes-products .slick-prev::before { content:'\e907'; padding: 0 10px;  font-family: 'gs-iconfont'; font-size: 28px; line-height: 1.2; color: #000000; font-weight: 300; }
.boxes-products .slick-next::before { content: '\e908'; }

/* ------------------------------------------------------------ *\
	#boxes
\* ------------------------------------------------------------ */
.boxes { font-size: 0; line-height: 0; }
.box { position: relative; z-index: 1; display: inline-block; vertical-align: top; box-sizing: border-box; transition: all 0.3s; }
.box:hover { text-decoration: none; }
.box img { width: 100%; height: auto; }

/*.boxes-basic { display: flex; flex-wrap: wrap; }
.box-basic { flex: 1; outline: 1px solid var(--color-neutral-mid); }*/

.box-price-old strong { text-decoration: line-through; }

.box-basic { min-width: 260px; width: 20%; outline: 1px solid var(--color-neutral-mid); }

.boxes-type-1 { display: flex; flex-wrap: wrap; }
.box-type-1 { overflow: hidden; flex: 1; outline: 1px solid var(--color-neutral-mid); padding: 10px; }
.box-type-1:hover { background: #eee; }
.box-type-1 .picture { float: left; display: block; width: 76px; height: 74px; margin: 15px 25px; }
.box-type-1 .content { overflow: hidden; padding: 7px 0; }
.box-type-1 .content .title { margin-bottom: 7px; font-family: var(--font-secondary); font-size: 18px; line-height: 1.3; color: #000; text-transform: uppercase; }
.box-type-1 .content .text { font-size: 14px; line-height: 1.3; color: #555; }

.accent .box-more { text-align: center; }
.accent .box-more .icon-svg { width: 76px; height: 74px; margin: 20px 0; fill: #fff; }
.accent .box-more .title { margin: 20px 0; font-family: var(--font-secondary); font-size: 30px; line-height: 1.3; color: #fff; text-transform: uppercase; }
.accent .box-more .btns { padding: 20px 0; }
.accent .box-more .btn-primary { min-width: 136px; background: #fff; color: #000; }
.accent .box-more .btn-primary:hover { background: var(--color-secondary); color: #fff; }



/*related products*/
.boxes-related { display: flex; padding: 25px; box-sizing: border-box; flex-wrap: wrap; }
.boxes-related:after { content:''; display: block; clear: both; }
.box-related.box-type-2 { background: #fff; padding: 15px 15px 0; margin: 10px; flex-basis: 250px; border: 1px solid; border-color: #d8d8d8; border-radius: 5px; color: #000; }
.box-related.box-type-2 .picture { margin-bottom: 10px; }
.box-related .related-content { overflow: hidden; background: #fff; }
.box-related .related-content .title { color: #000000; font-weight: 400; }
.related-content .related-price { vertical-align: middle; }
.related-content .related-price strong { color: inherit; }
.related-check { display: inline-block; font-size: 14px; vertical-align: middle; color: var(--color-text); }
.related-check { position: relative; }
.related-check > label { width: 20px; height: 20px; line-height: 20px; vertical-align: top; float: left; }
.related-check > label:before,
.related-check > label:after { content: ''; position: absolute; left: 0; width: 20px; height: 20px; border-radius: 3px; display: inline-block; clear: both;  }
.related-check > label:before { border: 1px solid var(--color-primary); }
.related-check input[type="checkbox"] { float: left; margin-right: 5px; opacity: 0; width: 20px; height: 20px; display: none; }
.related-check > input[type="checkbox"]:checked + label:before { background: var(--color-primary); }
.related-check > input[type="checkbox"]:checked + label:after { background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='12'><polygon fill='white' points='5.6,11.6 0,6.3 1.7,4.5 5.6,8.1 13.3,0.4 15,2.1'/></svg>") no-repeat; background-position: center; background-size: 50%; }


.added-products { display: inline-block; margin: 15px 15px 15px auto; box-sizing: border-box; text-align: left; background: none; text-align: center; background-color: var(--color-white-md); border: 1px solid transparent; border-radius: 4px; }
.added-products .box-added-products { text-align: center;  }
.added-products .content { padding: 25px 15px; font-size: 20px; }
.added-products .added-title { max-width: 95%; margin: 0 auto; font-size: 24px; line-height: 1.5; margin-bottom: 30px; font-weight: 700; color: #000; }
.added-products .added-products-count { font-size: 20px; line-height: 1; padding: 0 15px 30px; margin-bottom: 30px; border-bottom: 1px solid #d8d8d8; color: #000; font-weight: 500; }
.added-products .total-price { margin-bottom: 30px; line-height: 1; color: var(--color-primary); font-family: var(--font-primary); }
.added-products .total-price strong { font-size: 24px; color: var(--color-primary); font-weight: 700; }
.added-products .btn-primary { min-width: 100px; }

.boxes-type-2 { color: #000; border-color: #000; }
.box-type-2 { padding: 15px 15px 0; color: inherit; border-color: inherit; }
.box-type-2.promo { color: var(--color-promo); }
.box-type-2 .ribbon-promo { background: var(--color-promo); font-size: 14px; line-height: 1.2; text-transform: uppercase; padding: 5px 10px; color: #fff; }
.box-type-2 .ribbon-promo .icon-svg { width: 30px; height: 30px; }
.box-type-2 .picture { position: relative; display: block; margin-bottom: 5px; }
.box-type-2:hover .picture { overflow: hidden; }
.box-type-2:hover .picture img { transform: scale3D(1.1, 1.1, 1.1); transform-style: preserve-3d; }
.box-type-2 .ribbon { position: absolute; top: 0; left: 0; }
.box-type-2 .content { color: inherit; }
.box-type-2 .brand { overflow: hidden; height: 16px; margin-bottom: 5px; font-size: 12px; line-height: 1.2; color: #7c7c7c; font-weight: 500; text-transform: uppercase; text-align: left; }
.box-type-2 .title { overflow: hidden; height: 50px; margin-bottom: 5px; font-family: var(--font-secondary); font-size: 14px; line-height: 1.285; text-align: left; color: #000; text-transform: uppercase; text-align: left; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; text-size-adjust: none; word-wrap: break-word;}
.box-type-2 .box-price-wrap { padding: 10px 0; overflow: hidden; font-size: 0; line-height: 0; font-weight: 500; border-top: 1px solid; border-color: inherit; color: inherit; vertical-align: bottom; text-align: justify; }
.box-type-2 .box-price-wrap:after { content: ''; display: inline-block; width: 100%; }
.box-type-2 .box-price,
.box-type-2 .box-price-new,
.box-type-2 .box-price-old { display: inline-block; font-size: 22px; line-height: 1.2; letter-spacing: -0.75px; vertical-align: bottom; }
.box-type-2 .box-price { color: #000 }
.box-type-2 .box-price-new { color: #ed1847; }
.box-type-2 .box-price-old { color: #000; }
.box-type-2 .box-price strong,
.box-type-2 .box-price-new strong { font-size: 28px; line-height: 1.2; }
.box-type-2 .box-price-old strong { font-size: 18px; text-decoration: line-through; font-weight: 700; }

.accent-products-promo .pad-primary { box-shadow: 0 3px 6px rgba(0,0,0,0.20); }
.accent-products-promo .pad-primary:after { @apply --clearfix; }
/* .accent .boxes-products { float: left; max-width: calc(100% - 260px); } */
.accent .boxes-products-full { max-width: 100%; }
.accent-products-promo .box-more { float: left; width: 260px; height: 330px; }
.accent-products-promo .box-type-2.box-more { display: none !important; padding: 0; }
.accent-products-promo .box-more .content,
.accent-products-promo .box-more .picture { height: 100%;  }
.accent-products-promo .box-more .picture img { height: 100%; max-width: 100%; }
.accent-products-new .box-more { float:left; background: #6ebe47; }
.accent-products-promo .box-type-2,
.accent-products-seen .box-type-2,
.accent-products-popular .box-type-2,
.accent-products-new .box-type-2 { max-width: 260px; }
.accent-products-seen .accent-title { margin:30px 0; }
.boxes-products-promo .ribbon-promo .icon-svg { fill: var(--color-category-7); }
.boxes-products-promo { color: var(--color-promo); }
.boxes-products-new .ribbon-promo .icon-svg { fill: var(--color-category-3); }
.boxes-products-new .box-price { background: var(--color-category-3); color: #fff; }
.boxes-products-list .box-basic { min-width: 250px; width: 20%; }
.boxes-products-list .box-basic img { max-height: 185px; object-fit: contain; }

.boxes-type-3 { margin: 0 -8px; }
.boxes-type-3.categories { margin: 0 -10px; padding-bottom: 20px; }
.box-type-3 { overflow: hidden; width: 642px; border-radius: 5px; margin: 8px; }
.box-type-3.categories { width: 310px; margin: 0 15px 15px; }
.box-type-3 .picture img { transform-origin: center top; }
.box-type-3 .picture:hover img { transform: scale(1.05); transform: scale3D(1.05, 1.05, 1); }
.box-type-3 .overlay { padding: 10px; background: var(--color-secondary); font-size: 0; line-height: 0; transition: all 0.3s; box-sizing: border-box; }
.box-type-3 .overlay .icon-svg { display: inline-block; width: 45px; height: 45px; margin-right: 15px; fill: #fff; vertical-align: middle; }
.box-type-3 .overlay .content { display: inline-block; max-width: calc(100% - 60px); overflow: hidden; color: #fff; vertical-align: middle; }
.box-type-3 .overlay .content .title { height: 45px; font-family: var(--font-secondary); font-size: 14px; line-height: 1.4; text-transform: uppercase; vertical-align: middle; }
.box-type-3.categories .overlay .content .title { font-size: 16px; line-height: 1.4; }
.box-type-3 .overlay .content .text { font-size: 14px; line-height: 18px; }

.color-category-1.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-1); }
.color-category-2.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-2); }
.color-category-3.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-3); }
.color-category-4.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-4); }
.color-category-5.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-5); }
.color-category-6.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-6); }
.color-category-7.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-7); }
.color-category-8.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-8); }
.color-category-9.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-9); }
.color-category-10.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-10); }
.color-category-11.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-11); }
.color-category-12.boxes-type-3 .box-type-3 .overlay { background: var(--color-category-12); }

.box-type-4 { overflow: hidden; min-width: 433px; width: 33.3%; padding: 15px; }
.box-type-4 .picture { position: relative; float: left; overflow: hidden; width: 166px; height: 162px; margin-right: 10px; }
.box-type-4 .picture .icon-svg { position: absolute; top: 0; left: 0; width: 100%; height: 100%; fill: var(--color-white-md); }
.box-type-4:hover .picture img { transform: scale(1.1); transform: scale3D(1.1, 1.1, 1); }
.box-type-4 .content { overflow: hidden; padding: 15px; }
.box-type-4 .content .title { overflow: hidden; height: 60px; margin-bottom: 30px; font-size: 16px; line-height: 20px; color: #000; font-weight: bold; transition: all 0.3s; }
.box-type-4:hover .content .title { color: var(--color-primary); }
.box-type-4 .box-date strong { display: block; padding-bottom: 2px; font-size: 36px; line-height: 1; color: #000; }
.box-type-4 .box-date small { display: block; font-size: 10px; line-height: 1; color: #585858; }
.boxes-advices .content { line-height: 132px; }
.boxes-advices .content .title { display: inline-block; vertical-align: middle; max-height: 100px; height: auto; margin-bottom: 0; }

.box-type-5 { width: 130px; outline: 1px solid var(--color-neutral-mid); padding: 5px 10px; }
.box-type-5 .title { position: absolute; right: 10px; bottom: 20px; left: 10px; overflow: hidden; max-height: 40px; font-family: var(--font-secondary); font-size: 14px; line-height: 20px; color: var(--color-titles); text-transform: uppercase; text-align: center; }
.box-type-5:hover img { transform: scale(0.9); transform: scale3D(0.9, 0.9, 0.9); }

.boxes-partners-list .box-type-5 { width: 260px; padding-bottom: 60px; background: #fff; }
.boxes-partners-list .box-type-5 .title { border-radius: 5px; padding: 5px; background: rgba(0, 0, 0, .6); color: #fff; }

.box-type-6 { width: calc(20% - 30px);  border-radius: 5px; padding: 5px; margin: 15px; }
.box-type-6 .icon-svg { width: 48px; height: 48px; margin: 10px 0; fill: #000; }
.box-type-6 .title { margin-bottom: 10px; font-family: var(--font-secondary); font-size: 18px; line-height: 20px; color: #000; transition: all 0.3s; }
.box-type-6 .text { font-size: 14px; line-height: 18px; color: #000; transition: all 0.3s; }
.box-type-6:hover { background: var(--color-primary); }
.box-type-6:hover .title { color: #fff; }
.box-type-6:hover .text { color: #fff; }
.box-type-6:hover .icon-svg { fill: #fff; }
.boxes-type-6.boxes-download .box-type-6 { text-align: center; }

.boxes-type-7 { display: flex; flex-wrap: wrap; margin: 0 -1px; }
.box-type-7 { flex: 1; min-width: 260px; border: 1px solid var(--color-neutral-mid); padding: 15px; margin: 0 -1px -1px 0; }
.box-type-7 .icon-svg { width: 79px; height: 79px; margin-bottom: 10px; }
.box-type-7 .title { position: relative; padding-right: 40px; font-family: var(--font-secondary); font-size: 18px; line-height: 24px; color: #000; text-transform: uppercase; }
.box-type-7 .title a { color: inherit; }
.box-type-7 .title a:hover { color: var(--color-primary); text-decoration: none; }
.box-type-7 .title:before { content: attr(data-count); position: absolute; top: 50%; right: 0; width: 33px; height: 33px; border-radius: 50%; background: var(--color-secondary); font-size: 12px; line-height: 33px; color: #fff; text-align: center; transform: translateY(-50%); }
.box-type-7 ul { padding: 20px 0; font-size: 14px; line-height: 18px; color: var(--color-text); list-style: none outside none;  }
.box-type-7 ul li { padding-bottom: 10px; }
.box-type-7 ul a { color: inherit; }
.box-type-7 ul a:hover { color: var(--color-primary); text-decoration: none; }

.color-category-1.box-type-7 .icon-svg { fill: var(--color-category-1); }
.color-category-2.box-type-7 .icon-svg { fill: var(--color-category-2); }
.color-category-3.box-type-7 .icon-svg { fill: var(--color-category-3); }
.color-category-4.box-type-7 .icon-svg { fill: var(--color-category-4); }
.color-category-5.box-type-7 .icon-svg { fill: var(--color-category-5); }
.color-category-6.box-type-7 .icon-svg { fill: var(--color-category-6); }
.color-category-7.box-type-7 .icon-svg { fill: var(--color-category-7); }
.color-category-8.box-type-7 .icon-svg { fill: var(--color-category-8); }
.color-category-9.box-type-7 .icon-svg { fill: var(--color-category-9); }
.color-category-10.box-type-7 .icon-svg { fill: var(--color-category-10); }
.color-category-11.box-type-7 .icon-svg { fill: var(--color-category-11); }
.color-category-12.box-type-7 .icon-svg { fill: var(--color-category-12); }

.box-type-8 { display: block; overflow: hidden; margin-bottom: 15px; }
.box-type-8 .picture { overflow: hidden; float: left; width: 97px; border: 1px solid var(--color-neutral-mid); border-radius: 5px; }
.box-type-8 .content { overflow: hidden; width: 350px; padding: 5px 15px; }
.box-type-8 .content .title { overflow: hidden; height: 54px; margin-bottom: 10px; font-family: var(--font-secondary); font-size: 12px; line-height: 18px; color: #111; text-transform: uppercase; }
.box-type-8 .content .price { font-size: 14px; line-height: 18px; color: var(--color-text); white-space: nowrap; }
.box-type-8 .content .price strong { color: #000; }
.boxes-orders-histyory .box-type-8:nth-child(3) ~ .box-type-8 { display: none; }
.boxes-orders-histyory.is-active .box-type-8:nth-child(3) ~ .box-type-8 { display: block; }

.box-type-9 { display: block; overflow: hidden; padding-bottom: 10px; }
.box-type-9 .picture { position: relative; float: left; width: 97px; height: auto; border: 1px solid var(--color-neutral-mid); border-radius: 5px; margin-right: 30px; }
.box-type-9 .picture .ribbon-qty { position: absolute; top: 8px; right: -15px; width: 30px; height: 30px; border-radius: 50%; background: var(--color-primary); font-family: var(--font-secondary); font-size: 12px; line-height: 30px; color: #fff; text-transform: uppercase; text-align: center; }
.box-type-9 .content { overflow: hidden; padding: 15px 0; }
.box-type-9 .content .title { margin-bottom: 20px; font-family: var(--font-secondary); font-size: 12px; line-height: 18px; color: #000; text-transform: uppercase; }
.box-type-9 .content .box-price-wrap { font-size: 0; line-height: 0; }
.box-type-9 .content .box-price { display: inline-block; vertical-align: top; width: 50%; font-size: 14px; line-height: 18px; color: var(--color-text); }
.box-type-9 .content .box-price strong { color: #000; }
.box-type-9 .content .box-price:last-child { text-align: right; }

.boxes-type-10 { padding: 2px; margin: auto;  max-width: 400px; }
.box-type-10 { width: 25%; border: 1px solid var(--color-neutral-lt); }

.boxes-special-offers { margin: 0 -20px; }

.box-special-offer { overflow: hidden; border-radius: 5px; }
.box-special-offer .picture img { height: 370px; object-fit: cover; transform-origin: center top; }
.box-special-offer .picture:hover img { transform: scale(1.05); transform: scale3D(1.05, 1.05, 1); }
.box-special-offer .overlay { position: absolute; right: 0; bottom: 0; left: 0;  padding: 0 20px; transition: all 0.3s; }
.box-special-offer .overlay .overlay-image img { display: block; width: 40px; height: 40px; object-fit: cover; }
.box-special-offer:hover .overlay { padding: 15px; }
.box-special-offer .overlay .icon-svg { width: 56px; height: 54px; margin-right: 30px; fill: #fff; }
.box-special-offer .overlay .overlay-wrapper .text { max-width: 431px; font-size: 20px; line-height: 1.2; font-weight: 600; color: #fff; }
.box-special-offer .overlay .overlay-wrapper { display: flex; align-items: center; justify-content: space-between; padding-bottom: 20px; }

.color-category-1.box-special-offer .overlay { background: var(--color-category-1); }
.color-category-2.box-special-offer .overlay { background: var(--color-category-2); }
.color-category-3.box-special-offer .overlay { background: var(--color-category-3); }
.color-category-4.box-special-offer .overlay { background: var(--color-category-4); }
.color-category-5.box-special-offer .overlay { background: var(--color-category-5); }
.color-category-6.box-special-offer .overlay { background: var(--color-category-6); }
.color-category-7.box-special-offer .overlay { background: var(--color-category-7); }
.color-category-8.box-special-offer .overlay { background: var(--color-category-8); }
.color-category-9.box-special-offer .overlay { background: var(--color-category-9); }
.color-category-10.box-special-offer .overlay { background: var(--color-category-10); }
.color-category-11.box-special-offer .overlay { background: var(--color-category-11); }
.color-category-12.box-special-offer .overlay { background: var(--color-category-12); }

.boxes-categories + .toolbar.toolbar-products.toolbar-products-top { padding-top: 25px; border-top: 1px solid #d8d8d8; }
.boxes-categories .box-categories { display: block; margin-bottom: 25px; }
.boxes-categories .box-categories .title { color: #ffffff; border-radius: 4px 4px 0 0; padding: 2.5px 10px; background: #cc5b54; text-transform: uppercase; font-size: 11px; min-height: 40px; font-weight: 700; line-height: 20px; font-family: var(--font-primary); font-weight: 700; }
.boxes-categories figure { border-width: 0px 1px 1px 1px; border-style: solid; border-color: #d8d8d8; border-radius: 0 0 5px 5px; overflow: hidden; }
.boxes-categories img { max-height: 205px; min-height: 165px; }
.box-categories:hover img { transform: scale3d(1.075, 1.075, 1.075)}


.color-category-1.boxes-categories .box-categories .title { background: var(--color-category-1); }
.color-category-2.boxes-categories .box-categories .title { background: var(--color-category-2); }
.color-category-3.boxes-categories .box-categories .title { background: var(--color-category-3); }
.color-category-4.boxes-categories .box-categories .title { background: var(--color-category-4); }
.color-category-5.boxes-categories .box-categories .title { background: var(--color-category-5); }
.color-category-6.boxes-categories .box-categories .title { background: var(--color-category-6); }
.color-category-7.boxes-categories .box-categories .title { background: var(--color-category-7); }
.color-category-8.boxes-categories .box-categories .title { background: var(--color-category-8); }
.color-category-9.boxes-categories .box-categories .title { background: var(--color-category-9); }
.color-category-10.boxes-categories .box-categories .title { background: var(--color-category-10); }
.color-category-11.boxes-categories .box-categories .title { background: var(--color-category-11); }
.color-category-12.boxes-categories .box-categories .title { background: var(--color-category-12); }
/* ------------------------------------------------------------ *\
	#accents
\* ------------------------------------------------------------ */
.accents { overflow: hidden; width: 100%; background: #fff; transition: all 0.3s; }
.accents-inner { padding-bottom: 40px; }
.accent { margin-bottom: 20px; }
.accent-title { margin-bottom: 4px; font-family: var(--font-secondary); font-size: 24px; line-height: 1.3; color: #000; text-transform: uppercase; text-align: center; margin: 0 30px;}

.accent-special-offers { margin: 0; padding: 20px 0; }
.accent-special-offers .accent-title { margin-bottom: 30px; text-align: center; font-size: 32px; line-height: 1.2; }

.accent-news { padding: 20px 0; margin-bottom: 0; background: #fff; border-bottom: none;  }
.accent-news .boxes { padding-bottom: 35px;}
.accent-news .box-type-4 { width: 100%; }
.accent-news .accent-title { margin-bottom: 30px; font-size: 32px; line-height: 1.2; text-align: center; }
.accent-news .picture .icon-svg { fill: #fff; }

.accent-banners { font-size: 0; line-height: 0; margin-bottom: 25px; }
.banners { position: relative; }
.banners:after { @apply --clearfix; }
.banners-grid .col { padding: 0; margin: 0; }
.banners .banner .picture img { min-width: 100%; height: 100%; transform: scale3d(1.1, 1.1, 1.1); transition: .7s transform; object-fit: contain; }
.banners .banner .picture { position: relative; overflow: hidden; max-height: 475px; width: 100%; z-index: -1; background-color: #fff  }
.banners-grid .banner .picture img { height: 450px; object-fit: cover; min-width: 100%; transform:scale3d(1.02, 1.02, 1.02); }

.banner { position: relative; display: block; width: 100%; transition: none; }
.banner .info { position: absolute; top: 15px; left: 0; max-width: 100%; padding: 5px 10px; text-align: left; font-size: 18px; line-height: 1.2; z-index: 1; color: #000000; }
.banner .info p { position: relative; word-break: break-word; white-space: pre-wrap; }
.banner .info:before { @apply --transparent-bg; }
.banner .prices { position: absolute; bottom: 45px; right: 0; max-width: 100%; z-index: 1; font-size: 16px; line-height: 25px; color: #ffffff; font-weight: 500; }
.banner .price { padding: 5px 15px; background: var(--color-promo); text-align: center; border: 1px solid #fff; border-radius: 4px 0 0 4px; border-width: 1px 0 1px 1px;  font-weight: 700; }
.banner .price strong { font-size: 1.5em; }
.banner .price-crossed { position: relative; margin-bottom: 10px; padding: 5px 15px; color: #000000; border-radius: 4px 0 0 4px; overflow: hidden; }
.banner .price-crossed strong { text-decoration: line-through; font-size: 1.35em; }
.banner .price-crossed:before { @apply --transparent-bg; }
.banner:hover .picture img { transform: scale3d(1.2, 1.2, 1.2); }


.accent-products .tabs-content .tab { display: none; }
.accent-products .tabs-content .tab.is-active { display: block; animation: fadeIn .75s forwards; }
.accent-products .tabs-nav { position: relative; margin-bottom: 40px; }
.accent-products .tabs-nav ul { display: flex; gap: 30px; font-size: 0; line-height: 0; }
.accent-products .tabs-nav li { position: relative; display: inline-block; text-align: center; font-size: 16px; line-height: 1.2; color: #77797C; cursor: pointer; user-select: none; transition: .25s all; }
.accent-products .tabs-nav { position: relative; margin-bottom: 40px; }
.accent-products .tabs-nav ul { display: flex; gap: 30px; font-size: 0; line-height: 0; }
.accent-products .tabs-nav li { position: relative; display: inline-block; text-align: center; font-size: 16px; line-height: 1.2; color: #77797C; cursor: pointer; user-select: none; transition: .25s all; }
.accent-products .tabs-nav li:last-child { border-width: 0 1px 1px 1px; }
.accent-products .tabs-nav li.promo.is-active { color: var(--color-promo); font-weight: 600; }
.accent-products .tabs-nav li.is-active { color: #251F20; font-weight: 600; }
.accent-products .tabs-nav li.promo.is-active { color: var(--color-promo); font-weight: 600; }
.accent-products .tabs-nav li.is-active { color: #251F20; font-weight: 600; }
.accent-products .tabs-nav li:after { color: var(--color-primary); }
.accent-products .tabs-nav li.promo:after { color: var(--color-promo); }
.accent-products .tabs-nav li.is-active::after { content: ""; position: absolute; width: 100%; height: 1px; bottom: -5px; left: 0; right: 0; background-color: #251F20; z-index: 1; }
.accent-products .tabs-nav li.is-active::after { content: ""; position: absolute; width: 100%; height: 1px; bottom: -5px; left: 0; right: 0; background-color: #251F20; z-index: 1; }



.accent-parallax { padding: 30px 0; margin: 0; background: #fff; }
.accent-parallax .parallax-container { z-index: 1; height: 325px; }
.accent-parallax .parallax img { transition: none; }
.accent-parallax .banner { position: relative; overflow: hidden; border-radius: 5px; background-repeat: no-repeat; background-position: left center; background-size: cover; }
.accent-parallax .overlay { position: absolute; top: 0; bottom: 0; left: 0; z-index: 2; width: 470px; padding: 10px 60px; background: linear-gradient(to right, #000 0%, transparent 100%); color: #fff; }
.accent-parallax .banner.cardbox .overlay { width: 675px; }
.accent-parallax .overlay .title { overflow: hidden; max-height: 96px; margin: 40px 0 20px; font-size: 48px; line-height: 48px; color: inherit; font-weight: bold; }
.accent-parallax .overlay .text { overflow: hidden; max-height: 40px; margin-bottom: 30px; font-size: 14px; line-height: 20px; color: inherit; }
.accent-parallax .overlay .btn-primary { min-width: 136px; }
.accent-parallax .banner-price { position: absolute; right: 0; bottom: 0; z-index: 3; color: #fff; padding: 40px 10px 40px 40px; }
.accent-parallax .banner-price strong { font-size: 48px; line-height: 1; letter-spacing: -1px; }
.accent-parallax .banner-price sup { vertical-align: top; margin-left: -10px; font-size: 28px; line-height: 1; }
.accent-parallax .banner-price span { font-size: 14px; line-height: 1; font-weight: 500; }
.accent-parallax .banner-price .icon-svg { position: absolute; top: -27px; left: 6px; z-index: -1; width: 206px; height: 236px; fill: var(--color-category-7); }

.accent-products-categories { padding: 30px 0 13px; }



.accent-partners { padding: 35px 0 115px; margin-bottom: 0; background: #fff; }
.accent-partners .accent-title { margin-bottom: 20px; }

.accent-article { padding: 30px 0; margin-bottom: 0; background: #fff; }

.accent-related { padding-top: 20px; }
.accent-related .accent-title { margin-bottom: 30px; text-align: center; }

/* ------------------------------------------------------------ *\
	#blocks
\* ------------------------------------------------------------ */
.block { padding-bottom: 20px; }
.block-products-view .block-top { padding: 0 15px 15px; }
.block-products-view .block-top .block-preview { width: 490px; margin-right: 15px; }
/* .block-products-view .block-top .block-content { overflow: hidden; } */
/* .block-products-view .block-content-top { overflow: hidden; } */
.block-products-view .title-wrap { float: left; max-width: 80%; padding: 24px 0; }
.block-products-view .logo-brand { float: right; }
.block-products-view .block-title { font-family: var(--font-secondary); font-size: 18px; line-height: 1.2; color: var(--color-titles); }
.block-products-view .products-code { font-size: 11px; line-height: 18px; color: #a2a2a2; font-weight: 500; }
.block-products-view .rich-text { margin-bottom: 15px; overflow: auto; }
.block-products-view .rich-text p { overflow-wrap: break-word; }
.block-products-view .col:first-child .btn-article { display: block; }

.block-products-view .block-content { display: grid; grid-gap: 1rem; grid-template-columns: 1fr 1fr 2fr;
	grid-template-areas: "preview title title"
	"preview description aside"
	"variations variations utils"; }
.block-products-view .block-preview { grid-area: preview; }
.block-products-view .block-content-top { grid-area: title; }
.block-products-view .block-content-middle { grid-area: description; }
.block-products-view .block-products-price { grid-area: aside; }

.product-variations { grid-area: variations; }
.products-utilities { grid-area: utils; }

.block-products-view .block-products-price { margin-bottom: 18px; background: var(--color-neutral-lt); }
.block-products-view .block-products-price > .cols:nth-child(2) { display:flex; }
.block-products-view .block-products-price > .cols:nth-child(2) > .col:nth-child(2) { display:flex; flex-direction: column; justify-content: space-between;}
.block-products-view .block-products-price > .cols { margin-bottom: 0; padding: 22px 0; }
.block-products-view .block-products-price .cols + .cols { border-top: 1px solid var(--color-neutral-mid); padding-top: 24px; }
.block-products-view .block-products-price .product-calc-utils { border-radius: 5px; padding: 5px; margin: 15px 0 0; background: var(--color-secondary); font-size: 14px; line-height: 20px; color: #fff; text-align: center; }
.block-products-view .block-products-price .price-primary { font-size: 14px; line-height: 18px; }
.block-products-view .block-products-price .price-primary > strong { font-family: var(--font-secondary); font-size: 29px; line-height: 1; font-weight: normal; color: var(--color-primary); }
.block-products-view .block-products-price .price-primary span:first-child { display: block; margin-bottom: 4px; }
.block-products-view .block-products-price .price-primary.price-old > strong { font-size: 18px; color: #000; text-decoration: line-through; }
.block-products-view .block-products-price .price-primary.price-old span:first-child  { margin-bottom: 15px; }
.block-products-view .block-products-price small.price-primary { display: block; margin-top: 5px; }
.block-products-view .block-products-price small.price-primary > strong { font-size: 16px; }
.block-products-view .block-products-price small.price-primary span { font-size: 12px; }
.block-products-view .block-products-price .price-total { margin-bottom: 15px; }
.block-products-view .block-products-price .products-status { overflow: hidden; }
.block-products-view .block-products-price .products-status .icon-svg { float: left; width: 25px; height: 25px; margin-right: 10px; }
.block-products-view .block-products-price .products-status.success .icon-svg { fill: var(--color-primary); }
.block-products-view .block-products-price .products-status.error .icon-svg { fill: var(--error); }
.block-products-view .block-products-price .products-status strong,
.block-products-view .block-products-price .products-status a { display: block; overflow: hidden; }
.block-products-view .block-products-price .products-status strong { font-size: 14px; line-height: 18px; }
.block-products-view .block-products-price .products-status.success strong { color: var(--color-primary); }
.block-products-view .block-products-price .products-status.error strong { color: var(--error); }
.block-products-view .block-products-price .products-status a { font-size: 12px; line-height: 18px; color: #555; text-decoration: underline; }
.block-products-view .block-products-price .products-status a:hover { text-decoration: none; }
.block-products-view .block-products-price .msg { margin: 15px 0 0; }
.block-products-view .block-products-price .btns.btns-link { display:flex; align-items:center; height: 44px; }
.block-products-view .block-products-price .btns.btns-link .btn-link { color:var(--color-category-7); }
.block-products-view .products-utilities .rich-text { overflow: hidden; }
.block-products-view .products-utilities .btns { float: left; width: 234px; }
.block-products-view .products-utilities .socials-share { float: right; }
.block-products-view .block-content-bottom { padding: 24px 0; }

.block-gallery { padding-bottom: 0; }
.block-gallery .block-aside { float: left; width: 400px; margin-right: 15px; }
.block-gallery .block-aside .toolbar-date { padding: 15px; }
.block-gallery .block-aside .toolbar-date .date { float: none; border-width: 1px 0; border-style: solid; border-color: var(--color-neutral-mid); padding: 10px 0; margin-bottom: 30px; color: #000; text-align: center; }
.block-gallery .block-aside .toolbar-date .btns { float: none; }
.block-gallery .block-aside .toolbar-date .btn { display: block; width: 100%; }
.block-gallery .gallery-news { position: relative; margin-bottom: 15px; }
.block-gallery .gallery-news img { width: 100%; height: auto; }
.block-gallery .block-content { overflow: hidden; padding: 15px 15px 0; }
.block-gallery .block-scroll { position: relative; margin: 10px 0 15px; }
.block-gallery .block-scroll .article { padding-right: 20px; }

.block-contacts .article { padding: 10px 0; }
.block-contacts .pad-primary { position: relative; }
.block-contacts .block-content { float: right; width: 750px; padding: 15px; }

.block-stores .pad-primary { position: relative; }
.block-stores .block-content { position: relative; overflow: auto; float: left; width: 520px; height: 640px; }
.block-stores .map-stores { position: absolute; top: 0; right: 0; bottom: 0; width: 780px; height: 640px;}
.block-stores .stores-item { display: block; overflow: hidden; min-height: 130px; padding: 15px; color: var(--color-text); }
.block-stores .stores-item .title { margin: 5px 0 15px; font-family: var(--font-secondary); font-size: 18px; line-height: 20px; color: var(--color-titles); text-transform: uppercase; }
.block-stores .stores-item .text { font-size: 14px; line-height: 18px; color: var(--color-text); }
.block-stores .stores-item .text a { color: #000000; }
.block-stores .stores-item.selected,
.block-stores .stores-item:hover { background: var(--color-primary); text-decoration: none; }
.block-stores .stores-item.selected .title,
.block-stores .stores-item:hover .title,
.block-stores .stores-item.selected .text,
.block-stores .stores-item:hover .text,
.block-stores .stores-item:hover .text a { color: #fff; }
.block-contacts .map-contacts { position: absolute; top: 0; bottom: 0; left: 0; width: 505px; height: 905px; }

.block-small .pad { max-width: 780px; margin: 0 auto; }
.block-small .form-basic { max-width: 450px; padding: 35px 15px; margin: 0 auto; }

.block-entrance .block-content { padding: 30px 15px; }
.block-entrance .block-title { margin-bottom: 20px; font-family: var(--font-secondary); font-size: 18px; line-height: 24px; text-align: center; text-transform: uppercase; }

.block-timeline .pad { position: relative; z-index: 1; }

.block-partners-view .block-top { padding: 15px; }
.block-partners-view .block-middle { border-top: 1px solid var(--color-neutral-mid); padding: 15px; }

.block-404 .article { padding: 50px 15px; }

.block-profile .block-title { margin-bottom: 15px; font-family: var(--font-secondary); font-size: 18px; line-height: 24px; color: var(--color-titles); text-transform: uppercase; }
.block-profile .block-content { padding: 35px 15px 15px; }

.block-profile-history .orders-item { border: 1px solid var(--color-neutral-mid); padding: 15px 0; margin-bottom: 15px; background: var(--color-white-md); }
.block-profile-history .orders-item:last-child { margin-bottom: 0; }
.block-profile-history .orders-item .orders-item-text { font-size: 14px; line-height: 18px; color: #111; }
.block-profile-history .orders-item .orders-item-text p { padding-bottom: 10px; }
.block-profile-history .orders-item .orders-item-text .order-status { font-weight: bold; }
.block-profile-history .orders-item .orders-item-text .order-status.success { color: var(--success); }
.block-profile-history .orders-item .orders-item-text .order-status.error { color: var(--error); }
.block-profile-history .orders-item .btns { padding: 20px 0; }
.block-profile-history .preview-info { padding: 20px; }
.block-profile-history .boxes { padding-bottom: 30px; }
.block-profile-history .summary { padding: 10px 0; border-top: 1px solid var(--color-neutral-dk); }
.block-profile-history .summary-row { display: flex; padding: 5px 0; }
.block-profile-history .summary-row p { font-size: 14px; line-height: 24px; color: #000; }
.block-profile-history .summary-row .label { flex: 1; padding-right: 5px; }
.block-profile-history .summary-row .label span { display: block; font-size: 12px; line-height: 18px; color: var(--color-text); }
.block-profile-history .summary-row .price strong { font-family: var(--font-secondary); font-size: 18px; font-weight: normal; }
.block-profile-history .summary-row:last-child { border-top: 1px solid var(--color-neutral-dk); padding: 20px 0; margin-top: 15px; }
.block-profile-history .summary-row:last-child p { line-height: 28px; }
.block-profile-history .summary-row:last-child .label { font-size: 16px; font-weight: 500; }
.block-profile-history .summary-row:last-child .price strong { font-size: 28px; color: var(--color-primary); }

.block-profile-personal .block-note { margin-bottom: 10px; }
.block-profile-personal .block-title + .block-note { margin-top: -10px; }
.block-profile-personal .addresses { font-size: 0; line-height: 0; margin: 0 -15px; }
.block-profile-personal .addresses-item { box-sizing: border-box; position: relative; display: inline-block; vertical-align: top; width: 50%; padding: 15px; }
.block-profile-personal .addresses-item .pad-basic { position: relative; padding: 15px; }
.block-profile-personal .addresses-item .content { padding-right: 200px; }
.block-profile-personal .addresses-item .title { margin-bottom: 15px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.block-profile-personal .addresses-item .text { font-size: 14px; line-height: 18px; color: #000; }
.block-profile-personal .addresses-item .text p { margin-bottom: 10px; }
.block-profile-personal .addresses-item .text p:last-child { margin-bottom: 0; }
.block-profile-personal .addresses-item .btn-remove { position: absolute; top: 15px; right: 15px; padding: 10px; }
.block-profile-personal .addresses-item .btn-article { position: absolute; right: 15px; bottom: 15px; }
.block-profile-personal .block-content > .btns { padding: 40px 0 25px; text-align: center; }

.block-cart .cart { position: relative; overflow: hidden; }
.block-cart .cart-content { float: left; width: 1024px; }
.block-cart .cart-content > .msg { margin: 0 15px 15px; }
.block-cart .cart-content > .btns { padding: 15px; }
.block-cart .cart-aside { float: left; width: 246px; padding: 15px; }
.block-cart .cart-aside:before { content: ''; position: absolute; top: 0; right: 0; bottom: 0; width: 276px; border-left: 1px solid var(--color-neutral-dk); background: #e6e6e6; }
.block-cart .cart-aside .cart-summary { position: relative; }
.block-cart .cart-summary .summary { margin-bottom: 15px; }
.block-cart .cart-summary .summary-row { display: flex; padding: 5px 0; }
.block-cart .cart-summary .summary-row p { font-size: 14px; line-height: 24px; color: #000; }
.block-cart .cart-summary .summary-row .label { flex: 1; padding-right: 5px; }
.block-cart .cart-summary .summary-row .price strong { font-family: var(--font-secondary); font-size: 18px; font-weight: normal; }
.block-cart .cart-summary .summary-row.total { border-top: 1px solid var(--color-neutral-dk); padding: 10px 0; }
.block-cart .cart-summary .summary-row.total p { line-height: 28px; }
.block-cart .cart-summary .summary-row.total .label { font-size: 16px; font-weight: 500; }
.block-cart .cart-summary .summary-row.total .price strong { font-size: 28px; color: var(--color-primary); }
.block-cart .cart-summary .btns .btn-primary { display: block; }

.block-advices .media { box-sizing: border-box; max-width: 100%; width: 400px; border: 1px solid var(--color-neutral-dk); border-radius: 5px; padding: 5px; margin: 0 auto 30px; background: var(--color-neutral-lt); }
.block-advices .media img { width: 100%; height: auto; border-radius: 5px; }
.block-advices .article { width: 80%; margin: 0 auto; }

/* ------------------------------------------------------------ *\
	#components
\* ------------------------------------------------------------ */

/* tabs */
.component-tabs .tabs-nav { background: var(--color-white-md); }
.component-tabs .tabs-nav ul li { display: inline-block; vertical-align: top; }
.component-tabs .tabs-nav ul li a { position: relative; display: block; padding: 20px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: var(--color-neutral-dk); text-transform: uppercase; }
.component-tabs .tabs-nav ul li a.is-active,
.component-tabs .tabs-nav ul li a:hover { color: #000; }
.component-tabs .tabs-nav ul li a:before { content: ''; position: absolute; right: 20px; bottom: 0; left: 20px; height: 2px; background: #000; transition: all 0.3s; opacity: 0; visibility: hidden; transform: scaleX(0.6); }
.component-tabs .tabs-nav ul li a.is-active:before,
.component-tabs .tabs-nav ul li a:hover:before { opacity: 1; visibility: visible; transform: scaleX(1); }
.component-tabs .tabs-content { background: #e7e6e6; }
.component-tabs .tab-head { display: none; padding: 15px; background: var(--color-neutral-lt); font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: var(--color-neutral-dk); text-transform: uppercase; }
.component-tabs .tab-head:hover { text-decoration: none; }
.component-tabs .tabs-body { display: none; background: #ffffff; border: 1px solid #e6e6e6; border-top-color: transparent; }
.component-tabs .is-active .tabs-body { display: block; animation: slideInDown 1s; }
.component-tabs .tabs-body .article { padding: 15px; }
.component-tabs .tabs-body .msg { margin: 15px; }

/* accordion */
.accordion-item + .accordion-item { border-top: 1px solid var(--color-neutral-mid); }
.accordion-item-head { position: relative; padding: 24px 60px 24px 24px; cursor: pointer; }
.accordion-item-head .title { font-family: var(--font-secondary); font-size: 18px; line-height: 24px; color: var(--color-titles); text-transform: uppercase; }
.accordion-item-head .icon-arrow-down { position: absolute; top: 50%; right: 24px; margin-top: -5px; }
.accordion-item-head .icon-arrow-down.icon-bg { top: calc(50% - 15px); margin:0; padding: 10px; right: 14px; border-radius: 5px; background: var(--color-primary); fill:var(--color-white-md); }
.accordion-item-body { display: none; }
.accordion-item-body .article { padding: 0 24px 24px; }
.accordion-item-body .btns { padding: 24px; }
.accordion-item-body .article + .btns { padding: 24px; margin-top: -24px; }

.accordion-item.is-active { background: var(--color-neutral-mid); }
.accordion-item + .accordion-item.is-active { border-color: #fff; }
.accordion-item-head.is-active .icon-arrow-down { transform: rotate(90deg); transform: rotate3d(0, 0, 1, 180deg); }
.accordion-item-head.is-active .icon-arrow-down.icon-bg { transform: scaleY(-1); }
.accordion-item-body.is-active { display: block; animation: slideInDown 1s; }

/* checkout step 2 */
.component-accordion-step2 .accordion-item.is-active { background: none; }
.component-accordion-step2 .accordion-item-head { padding: 10px 24px 10px 0; }
.component-accordion-step2 .accordion-item-head:not(.is-active) { border-top:1px solid #acacac; border-bottom: 1px solid #acacac; }
.component-accordion-step2 > .accordion-item:not(.is-active) + .accordion-item .accordion-item-head { border-top: none; }
.component-accordion-step2 .accordion-item + .accordion-item { border: none; }
.component-accordion-step2 .accordion-item-head legend { margin: 0; }
.component-accordion-step2 .accordion-item-head .title { font-size:13px; }
.form-checkout .component-accordion-step2 .choose-group-stripped .choose { border: none; }
.component-accordion-step2 .form .choose input:checked ~ .form-toggle,
.form-checkout .component-accordion-step2 .choose .form-toggle { display: block; }

/* lists */
.list-files li { display: block; }
.list-files a { display: block; overflow: hidden; padding: 10px; color: var(--color-text); }
.list-files a img { float: left; margin-right: 20px; }
.list-files a span { display: block; padding: 5px 0; font-size: 14px; line-height: 1.3; }
.list-files li:nth-child(2n) a { background: var(--color-secondary); color: #fff; }
.list-files a:hover { text-decoration: none; }
.list-files a:hover img { transform: translate3d(10px, 0, 0); }

/* widgets */
.component-gallery { max-width: none; font-size: 0; line-height: 0; }
.component-gallery-item { position: relative; overflow: hidden; display: inline-block; vertical-align: top; width: 18%; margin: 1%; background: var(--color-secondary); transition: all 0.3s; }
.component-gallery-item img { position: relative; z-index: 1; width: 100%; height: auto; }
.component-gallery-item:hover img { transform: scale(0.95); transform: scale3e(0.95, 0.95, 1); }

.component-gallery.photo .component-gallery-item:before,
.component-gallery.video .component-gallery-item:before { content: ''; position: absolute; bottom: 0; right: 0; z-index: 2; width: 50px; height: 50px; background: var(--color-secondary); background-repeat: no-repeat; background-position: center center; background-size: 40px 40px; }
.component-gallery.photo .component-gallery-item:before { background-image: url('../../../images/icon-photo.svg'); }
.component-gallery.video .component-gallery-item:before { background-image: url('../../../images/icon-video.svg'); }

#products-slider .slick-next { right: auto; top: unset; left: 50%; width: 15px; height: 15px; border-right: 1px solid #77797C; border-top: 1px solid #77797C; transform: translateX(-50%) rotate(135deg); }
#products-slider .slick-prev { left: 50%; top: 5px; right: auto; width: 15px; height: 15px; border-left: 1px solid #77797C; border-top: 1px solid #77797C; transform: translateX(-50%) rotate(45deg); }
#products-slider .slick-next:before { content: " "; }
#products-slider .slick-prev:before { content: " "; }
/*#js_products_container { position: relative; }*/
/*#js_products_container:after { content: ''; background: url('../../../images/gs_loader.svg') no-repeat; position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; pointer-events: none; z-index: 0; opacity: 0; }*/
/*#js_products_container[data-loading="true"]:after { opacity: 1; z-index: 10; }*/

/* filters range slider */
.filters-aside-item-body .noUi-target { width: calc(100% - 20px); border: none; box-shadow: none; background: var(--color-neutral-mid); }
.filters-aside-item-body .noUi-horizontal { height: 7px;  }
.filters-aside-item-body .noUi-handle { top: -8px; border-radius: 50%; left: 0; width: 20px; height: 20px; background: var(--color-primary); box-shadow: none; border: none; cursor: pointer; }
.filters-aside-item-body .noUi-connect { background: var(--color-primary); box-shadow: none; }
.filters-aside-item-body .noUi-handle:before,
.filters-aside-item-body .noUi-handle:after { content: none; }

.js-loader { position: relative; overflow: hidden; pointer-events: none; }
.js-loader:before,
.js-loader:after { content: ''; display: block; position: absolute; }
.js-loader:before { background: #fff; opacity: 0.75; top: 0; left: 0; width: 100%; height: 100%; z-index: 2;  }
.js-loader:after { background: url(../../../images/gs_loader.svg) no-repeat center,center; width: 200px; height: 200px; top: 150px; left: 50%; transform: translate(-50%, 0); z-index: 2; }
.btn.js-loader { background: var(--color-neutral-dk); }
.btn.js-loader:after { background-size: 115%; min-width: 50px; width: auto; height: 100%; top: 0; left: 50%; }

/* messenger icon */
#fb-root { position: relative; z-index: 20; }

/* fast delivery  */
.fast-delivery-popconfirm .form { padding:0 20px; }
.fast-delivery-popconfirm .message-container { padding-left:15px; margin:30px 0px 40px; }
.fast-delivery-popconfirm .fast-delivery-msg-container { display:flex; align-items:center; margin-bottom:30px; }
.fast-delivery-popconfirm .fast-delivery-msg-container > * { display: inline-block; }
.fast-delivery-popconfirm .fast-delivery-msg-container > *:first-child { margin-right:30px; color:var(--color-secondary); font-size:16px; font-weight: bold; }
.fast-delivery-popconfirm .cols-inputs { margin-bottom: 30px; }
.fast-delivery-popconfirm .col-btns { display:flex; align-items: center; margin-bottom:25px; }
.fast-delivery-popconfirm .col-btns > :first-child { display:flex; justify-content: flex-end; }
.fast-delivery-popconfirm .col-btns > :nth-child(2) .btns { padding-left: 30px; }
.fast-delivery-popconfirm .col-btns .btns .btn { text-transform: uppercase; }
.fast-delivery-popconfirm .btns .btn-link { color:var(--color-neutral-dk); text-decoration: none; }

/* buy now product popconfirm */
.buy-now-product-popconfirm .added-product-container .added-product-msg { display:flex; justify-content: center; align-items: center; margin-bottom:25px; padding:8px; background:var(--color-primary); color:#fff; font-weight: bold; }
.buy-now-product-popconfirm .added-product-container .added-product-msg .icon-svg { margin-right:20px; width:17px; height:17px; }
.buy-now-product-popconfirm .product-container-information { display:flex;	padding:0 15px 20px; }
.buy-now-product-popconfirm .product-container-information .product-data .image-heading-container-re { display:none; }
.buy-now-product-popconfirm .product-container-information .product-image { flex:1; padding:15px 10px 0 0; height:80px; }
.buy-now-product-popconfirm .product-container-information .product-image > img { height: 100%; object-fit: fill; }
.buy-now-product-popconfirm .product-container-information .product-data { flex:4; }
.buy-now-product-popconfirm .product-container-information .product-data .product-heading { flex: 4; margin-bottom: 50px; }
.buy-now-product-popconfirm .product-container-information .product-data .product-heading .product-title { text-transform: uppercase; }
.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices { margin-bottom: 60px; }
.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices > * { margin-bottom: 10px; }
.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices .quantity,
.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices .price-container,
.buy-now-product-popconfirm .product-container-information .product-data .btns-container { display:flex; justify-content: space-between; align-items: center;}
.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices .price-container .price { font-size: 20px; font-weight: bold; color: var(--color-primary); }
.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btns .btn { text-transform: uppercase; }
.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btn-link { padding-left: 30px; color: var(--color-neutral-dk); text-decoration: none; }

/* successful order popconfirm - toast message */
.successful-order-toast { display:flex; flex-direction: column; align-items: center; }
.successful-order-toast  .drawer-line { margin-bottom:40px; }
.successful-order-toast .icon-svg { margin-bottom:30px; width:40px; height:40px; fill:var(--color-primary); }
.successful-order-toast > .message { margin-bottom:40px; font-weight: bold; font-size:16px; text-align:center; }
.successful-order-toast > .message .outstanding-word { color:var(--color-primary); text-transform: uppercase; }
.successful-order-toast .btns { margin-bottom:60px; }
.successful-order-toast .toast-progress { width:100%; background:red; }

/* popconfirm  */
.popconfirm { display:none; position: fixed; top:0; left:0; width: 100%; height:100%; background:rgba(0,0,0,0.2); z-index:32; }
.popconfirm.show { display: block; }
.popconfirm > *:first-child { position:fixed; top:50%; left:50%; transform:translate(-50%,-50%); width:640px; background:#fff; z-index:22;  }

/* drawer line */
.drawer-line { margin-bottom:20px; width:100%; height:10px; background:var(--color-primary); }

/* related categories */
.related_categories { text-align: center; border-radius: 5px; }
.related_categories h3 { padding: 7px 0; color: white; }
.boxes-categories { padding-top:15px; margin: 0 0; }
.related_categories-container { background-color: #f7f7f7; border-radius: 5px; margin-bottom: 20px; }
.boxes-categories .box-categories { margin-bottom: 15px; }


.color-marker {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	vertical-align: middle;
	box-sizing: border-box;
}
.color-marker > span {
	color: var(--color-secondary);
}
.color-marker:before {
	content: '';
	display: block;
	width: 22px;
	height: 22px;
	margin: 0 6px;
	border: 2px solid #fff;
	border-radius: 6px;
	background: currentColor;
	outline: 1px solid #E6E6E6;
}

/* unsubscribe page */

.unsubscribe-container{
	height: 100vh;
	transform: translate(0, 25%);
}
  .unsubscribe-container .unsubscribe-left {
	width:50%;
	float: left;
	margin:30px 0;
  }
  .unsubscribe-container .unsubscribe-left img {
	width: 215px;
	margin-bottom: 50px;
  }
  .unsubscribe-container .unsubscribe-left h1 {
	margin-bottom: 20px;
	font-size:38px;
  }
  .unsubscribe-container .unsubscribe-left p {
	width: 47%;
	margin-bottom: 45px;
	font-size: 16px;
	color:#898989;
  }
  .unsubscribe-container .unsubscribe-right {
	width:50%;
	float: right;
  }
  .unsubscribe-container .unsubscribe-right img {
	display: block;
	margin:auto;
  }

  /* variations */
  .product-variations {
	display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
  }
  .color-variations {
	  margin-bottom: 1rem;
  }
  .color-variations ul {
	  background: #F7F7F7;
	  list-style-type: none;
	  font-size: 0;
	  border-radius: 3px;
  }
  .color-variation {
	  position: relative;
	  display: inline-block;
	  margin: 6px;
  }
  .color-variation input {
	  position: absolute;
	  border: none;
	  appearance: none;
	  outline: none;
  }
  .color-variation > label {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
  }
  .color-variation > input:checked + label:before {
	  border-radius: 3px;
	  transform: scale3d(1.275, 1.275, 1.275);
  }
  .size-variations .field-select select {
	  border-radius: 3px;
	  line-height: 36px;
	  height: auto;
  }

  .products-locations-text {
	color: #251F20;
	font-family: var(--font-primary);
	font-size: 14px;
	margin-right: 19px;
  }

  .products-locations {
	grid-area: locations;
	position: relative;
	background-color: var(--color-white-md);
	margin-bottom: 36px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 18px 14px;
	border-radius: 5px;
  }

  .products-locations.no-locations {
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 12px;
  }

  .products-locations .products-locations-info {
	max-width: 542px;
	font-size: 14px;
	color: #251F20;
  }

  .products-locations-content {
	display: flex;
	align-items: center;
	gap: 10px;
  }

  .products-locations-list {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
  }

  .products-locations-city {
	color: var(--swiper-pagination-color);
	margin-right: 5px;
	font-size: 14px;
	font-family: var(--font-secondary);
  }

  .products-locations-city:hover {
	text-decoration: none;
  }

  .products-locations-city:hover {
	color: var(--success);
  }

/* Product detailed */
.header-bottom-admin {
	background: #F7F7F7;;
}

.logout-btn {
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 7px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	color: #000;
}

.info-module-logout-btn {
	float: none;
	display: inline-block;
	vertical-align: middle;
	padding-left: 40px;
	text-align: left;
}

.product-detailed-main-head {
	padding-top: 15px;
}

.fields-wrapper {
	display: flex;
	flex-direction: row;
	gap: 24px;
}

.fields-wrapper .nav-search {
	max-width: 454px;
	width: 100%;
	margin: 0;
}

.btn-scanner-wrapper {
	min-width: 280px;
	padding: 15px 0;
}

.btn-scanner-wrapper button {
	height: 35px;
}

.btn-scanner-wrapper .btn-primary {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.block-content-top .product-detailed-title {
	padding: 0;
}

.block-content-top .block-title {
	word-wrap: break-word;
}

.product-detailed-title .block-title {
	word-wrap: break-word;
	line-height: 1.68;
	margin-bottom: 25px;
}

.products-code-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.product-detailed-title-wrapper {
	display: flex;
	flex-direction: column;
}

.product-detailed-title-wrapper .products-code {
	display: flex;
	gap: 5px;
}

.products-manufacturer {
	color: var(--color-silver);
}

.product-detailed-title-wrapper .products-code p {
	font-size: 13px;
}

.product-detailed-title-wrapper .products-code:nth-of-type(1) span {
	color: #A7CD46;
}

.tabs-product-detailed .tab-head {
	position: relative;
	display: block;
	color: #3C3C3C;
	line-height: 19.5px;
}

.tabs-product-detailed .tab-head:before,
.tabs-product-detailed .tab-head:after { content: ''; position: absolute; top: calc(50% - 1px); right: 15px; background: #000; }
.tabs-product-detailed .tab-head:before { width: 18px; height: 2px; }
.tabs-product-detailed .tab-head:after { width: 2px; height: 18px; top: calc(50% - 9px); right: 23px; opacity: 1; transition: .25s all;}
.tabs-product-detailed .tab-head.is-active:after { opacity: 0; transform: rotate(-90deg); }

.tabs-product-detailed .open .tab-head {
	pointer-events: none;
}

.tabs-product-detailed .open .tab-head:before,
.tabs-product-detailed .open .tab-head:after {
	content: none;
}

.tabs-product-detailed .open .tabs-body {
	display: block;
}

.tabs-product-detailed .tab {
	margin-bottom: 15px;
	background-color: #f7f7f7;
}

.tabs-product-detailed-button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	max-width: 310px;
}

.tabs-product-detailed-wrapper {
	padding: 15px;
}

.tabs-product-detailed-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.boxes-product-detailed .swiper-wrapper .swiper-slide .picture img {
	width: 100%;
}

.product-detailed-barcodes {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.product-detailed-barcodes-image {
	max-width: 100%;
}

.tabs-product-detailed-price-wrapper {
	display: flex;
	flex-direction: column;
}

.tabs-product-detailed-price {
	display: flex;
	gap: 40px;
	margin-bottom: 12px;
}

.tabs-product-detailed-price span {
	font-weight: 500;
}

.tabs-product-detailed-price .price-old,
.table-price-warehouses-content .price-old {
	font-weight: 700;
	color: #EB1F4B;
	text-decoration: line-through;
}

.tabs-product-detailed-price .price-new,
.table-price-warehouses-content .price-new,
.table-price-warehouses-elements .price-new {
	font-weight: 700;
	color: #89A73C;
}

.tabs-product-detailed-info pre {
	display: block;
	font-family: var(--font-primary);
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	color: #6C6C6C;
}

.tabs-product-detailed-content span {
	display: block;
	margin-bottom: 10px;
	font-weight: 500;
	color: #A2A2A2;
}

.tabs-product-detailed-container {
	padding-top: 15px;
	border-top: 1px solid #E6E6E6;
}

.tabs-product-detailed-container .tabs-product-detailed-article {
	padding: 0;
}

.tabs-product-detailed-article .table-warehouses tr {
	border-bottom: 1px solid #E6E6E6;
}

.tabs-product-detailed-article .table-warehouses tr:last-of-type {
	border-bottom: 0;
}

.tabs-product-detailed-article .table-basic td {
	font-weight: 500;
}

.tabs-product-detailed-article {
	margin-bottom: 0;
}

.tabs-product-detailed-container .tabs-product-detailed-download {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
}

.tabs-product-detailed-container .rich-text pre {
	font-family: var(--font-primary);
	white-space: pre-wrap;
}

.tabs-product-detailed-download .download-btn {
	min-width: 229px;
	margin: 0;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
}

/* Product detailed calculator */
.product-detailed-calculator {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .7);
	z-index: 32;
}

.product-detailed-calculator .show {
	display: block;
}

.product-detailed-picture img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product-detailed-calculator-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 459px;
	width: 100%;
	padding: 50px 15px 15px;
	border-radius: 4px;
	background-color: #F7F7F7;
}

.product-detailed-calculator-button {
	position: absolute;
	top: 15px;
	right: 15px;
}

.product-detailed-calculator-fields {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin-bottom: 15px;
}

.product-detailed-calculator-product-calc {
	padding: 5px;
	margin-bottom: 10px;
	font-weight: 700;
	color: #fff;
	background-color: #333;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.product-detailed-calculator .price-primary {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 15px;
}

.product-detailed-calculator .price-primary span {
	font-weight: 500;
	color: #6c6c6c;
}

.product-detailed-calculator .price-primary strong {
	font-family: var(--font-secondary);
	font-size: 29px;
	line-height: 2.68;
	font-weight: normal;
	color: var(--color-primary);
}

.product-detailed-calculator .msg-warning {
	text-align: center;
}

/* Product detailed warehouses */
.back-btn {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #E6E6E6;;
}

.back-btn a {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 500;
	color: #000;
	text-transform: uppercase;
}

.tabs-product-detailed-tables {
	padding: 20px 25px 0;
}

.table-warehouses {
	width: 100%;
}

.table-warehouses thead {
	border-bottom: 1px solid #A2A2A2;
}

.table-warehouses th {
	width: calc(100% / 3);
	padding-bottom: 10px;
	font-weight: 500;
	color: #A2A2A2;
}

.table-warehouses td {
	width: calc(100% / 3);
	padding: 12px 0;
	color: #3c323c;
}

.table-warehouses td:nth-of-type(1),
.table-price-warehouses td:nth-of-type(1) {
	font-weight: 700;
	text-transform: uppercase;
}

.table-warehouses td:nth-of-type(1),
 th:nth-of-type(1) {
	text-align: left;
}

.table-warehouses td:nth-of-type(2),
 th:nth-of-type(2){
	text-align: center;
}

.table-warehouses td:nth-of-type(3),
 th:nth-of-type(3) {
	text-align: right;
}

.tabs-product-detailed-warehouses-wrapper {
	padding: 0 25px 15px 25px;
}

.tabs-product-detailed-warehouses-head {
	display: flex;
	align-items: center;
	text-align: center;
}

.tabs-product-detailed-warehouses-head span:first-of-type {
	flex-basis: 30%;
}

.tabs-product-detailed-warehouses-container {
	flex-grow: 1;
	display: grid;
	grid-template-areas: "title title" "text info";
	grid-template-columns: repeat(2, 1fr);
	border-left: 1px solid #DFDFDF;
}

.tabs-product-detailed-warehouses-container--single {
	grid-template-columns: repeat(1, 1fr);
}

.tabs-product-detailed-warehouses-container span {
	padding: 8px 0;
}

.tabs-product-detailed-warehouses-container span:first-of-type {
	grid-area: title;
	text-align: center;
	border-bottom: 1px solid #DFDFDF;
}

.tabs-product-detailed-warehouses-container span:nth-of-type(2) {
	border-right: 1px solid #DFDFDF;
}

.table-price-warehouses-content {
	display: flex;
	align-items: center;
}

.table-price-warehouses-elements {
	display: flex;
	align-items: center;
	text-align: center;
	border-bottom: 1px solid #e6e6e6;
}

.table-price-warehouses-elements:last-of-type {
	border-bottom: 0;
}

.table-price-warehouses-elements span:nth-of-type(1) {
	flex-basis: 30%;
}

.table-price-warehouses-elements-prices {
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.table-price-warehouses-elements-prices--single {
	grid-template-columns: repeat(1, 1fr);
}


.table-price-warehouses-elements-prices span:first-of-type {
	border-left: 1px solid var(--color-neutral-mid);
	border-right: 1px solid var(--color-neutral-mid);
}

.tabs-product-detailed-warehouses-head {
	border-bottom: 1px solid #DFDFDF;
}

.tabs-product-detailed-warehouses-head span {
	font-weight: 500;
	color: #A2A2A2;
}

.table-price-warehouses-content div:nth-of-type(1) {
	padding: 14px 0;
	text-align: left;
}

.table-price-warehouses-content div:nth-of-type(4) {
	padding-right: 20px;
	text-align: right;
}

.table-price-warehouses-content div:nth-of-type(1) {
	flex-basis: 30%;
	display: flex;
	gap: 10px;
	font-weight: 700;
	line-height: 1.31;
	color: #3C3C3C;
	text-transform: uppercase;
}

.table-price-warehouses-content {
	border-bottom: 1px solid var(--color-neutral-mid);
}

.table-price-warehouses .table-price-warehouses-content:last-of-type {
	border-bottom: 0;
}

.table-price-warehouses.active .table-price-warehouses-content {
	border-bottom: 0;
}

.table-price-warehouses.active .table-price-warehouses-container {
	display: block;
    animation: d 1s;
}

.table-price-warehouses.active .table-price-warehouses-button {
    transform: rotate(180deg);
}

.table-price-warehouses-content span {
	display: block;
}

.table-price-warehouses-container {
	display: none;
	border-top: 1px solid var(--color-neutral-mid);
	border-bottom: 1px solid var(--color-neutral-mid);
}

.table-price-warehouses-button {
	transition: transform 0.5s ease;
}

.table-price-warehouses-elements-prices span {
	font-weight: 600;
	color: #3c3c3c;
	padding: 8px 0;
}

.table-price-warehouses-elements-title {
	font-weight: 500;
	color: #AEAEAE;
	text-transform: uppercase;
}

.price-promo-wrapper {
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	text-align: center;
}

.price-promo-wrapper--single {
	grid-template-columns: repeat(1, 1fr);
}

.price-promo-wrapper span {
	display: block;
	padding: 14px 0;
}

.price-promo-wrapper span:first-of-type {
	border-right: 1px solid #DFDFDF;
	border-left: 1px solid #DFDFDF;
}

/* Info module search */
.info-module-search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 140px 0;
}

.info-module-search-title {
	font-size: 32px;
    font-weight: 700;
    line-height: 3rem;
    margin-bottom: 70px;
	color: #5f79ce;
	text-align: center;
}

.info-module-search-image {
	max-width: 336px;
	height: 328px;
}

.info-module-search-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.box-type-2 .ribbon-special-offer,
.box-type-2 .ribbon-offer {
	padding: 5px 10px;
	font-size: 14px;
	line-height: 15.5px;
	text-transform: uppercase;
	text-align: center;
}

.box-type-2 .ribbon-special-offer {
	max-width: 67px;
	color: #fff;
	background-color: #000;
}

.box-type-2 .ribbon-offer {
	background-color: #A7CD46;
	color: #000;
}


  /* Black Friday Swiper */
.black-friday-swiper {
	position: relative;
	padding: 30px 0;
	margin-bottom: 50px;
}

.black-friday-swiper-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

/* Black Friday Swiper */
.black-friday-swiper {
	position: relative;
	padding: 45px 0 55px 0;
	margin-bottom: 25px;
	background-position: center center;
}

.black-friday-swiper .accent {
	margin-bottom: 0;
}

.accent-banners--black-friday .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	border-color: #dcdcdc;
}

.box-type-2 .ribbon-promo {
	max-width: 100%;
}

.special-offer-ribbon {
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 14px;
	line-height: 1.2;
	text-transform: uppercase;
	padding: 5px 10px;
	border-radius: 4px;
}

.accent-banners--black-friday {
	margin-bottom: 0;
}

.accent-banners--black-friday .swiper-pagination {
	background-color: #3c3c3c;
}

/* Product detailed page */
.block-products-view .product-detailed-content {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr 2fr;
	grid-template-areas: "preview title title"
	"preview description aside"
	"variations variations utils";
}

/* Products view tab */
.block-products-view .box-type-2:not(.box-related) {
	padding: 0;
	color: unset;
	border-color: unset;
}

.block-products-view .box-type-2 .ribbon {
	top: 24px;
}

.product-label-container {
	width: 50mm;
}

.product-label {
	margin: 0;
	padding: 0;
	height: fit-content;
	font-size: 2px;
	transform: translateY(-20px);
}

.product-label__name {
	margin: 0;
	padding: 0;
	color: var(--color-black);
	font-size: 2px;
	font-weight: 700;
	text-align: center;
}

.product-label__barcode {
	margin: 0;
	padding: 0;
	height: 58px;
}

.product-label__quantity {
	margin: 0;
	padding: 0;
	color: var(--color-black);
	text-transform: uppercase;
	font-size: 5px;
	font-weight: 500;
	text-align: center;
}

.product-label__barcode-container {
	margin: 0;
	padding: 0;
	text-align: center;
}

.label-quantity-modal--show {
	display: block;
}

.label-quantity-modal__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.label-quantity-modal__unit-input {
	display: none;
}

.label-quantity-modal__unit-input:checked + .label-quantity-modal__unit-select-option {
	background-color: var(--color-light-green);
	color: var(--color-white);
}

.label-quantity-modal__unit-select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-self: center;
	padding: 2px;
	background-color: var(--color-white-md);
	border: 1px solid;
	border-radius: 3px;
}

.label-quantity-modal__unit-select-option{
	display: flex;
	justify-content: center;
	padding: 8px 10px;
	border-radius: 3px;
	color: var(--color-black);
	font-weight: 500;
	font-size: 14px;
	line-height: 16.4px;
}

/* Low availability cart */
.low-availability {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}
.low-availability-head {
	position: relative;
}
.low-availability-head .icon {
	fill: #000;
}
.low-availability-body {
	position: absolute;
	top: 50px;
	right: 0;
	max-width: 100vw;
	min-height: 300px;
	width: 479px;
	visibility: hidden;
	overflow: hidden;
	pointer-events: none;
}
.low-availability-body:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-primary);
	backface-visibility: hidden;
	transition: transform 0.6s cubic-bezier(0.2, 1, 0.3, 1);
	transform: translate(100%, -100%) translate(-68px, 68px);
	transform: translate3d(100%, -100%, 0) translate3d(-68px, 68px, 0);
}
.low-availability-body.is-active {
	max-height: 640px;
	background: var(--color-primary);
	visibility: visible;
	overflow-y: auto;
	pointer-events: auto;
	z-index: 101;
}
.low-availability-body.is-active:before {
	transform: translate(0);
	transform: translate3d(0, 0, 0);
}
.low-availability-body .low-availability-summary {
	opacity: 0;
}
.low-availability-body.is-active .low-availability-summary {
	opacity: 1;
	transition: opacity 0.6s 0.3s cubic-bezier(0.2, 1, 0.3, 1);
}
.low-availability-summary { position: relative; padding: 27px; color: #fff; font-size: 14px; line-height: 1.2; }
.low-availability-summary .title { font-family: var(--font-secondary); font-size: 24px; line-height: 1.3; color: #fff; text-transform: uppercase; }
.low-availability-summary .btn-close { position: absolute; top: 20px; right: 17px; display: block; padding: 10px; }
.low-availability-summary_table thead tr,
.low-availability-summary_table tbody tr {
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 4px;
}
.low-availability-summary .low-availability-summary_table thead tr th,
.low-availability-summary .low-availability-summary_table tbody tr td {
	text-align: left;
}
.low-availability-summary_table .product-details {
	display: block;
	font-size: 14px;
	color: #fff;
	text-decoration: none;
}
.low-availability-summary .btn-primary { width: 340px; display: flex; align-items: center; justify-content: center; gap: 10px; background: #fff; color: var(--color-secondary); }
.low-availability-summary .btn-primary.not-active { background-color: #6f6666; pointer-events: none; }
.low-availability-summary-bottom .btn-primary { float: right; }
.low-availability-summary-bottom { overflow: hidden; padding: 20px 0 3px; }
.low-availability-summary .low-availability-head { position: absolute; padding: 0;  }
.navigation-bar__delete-button {
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 4px;
	border: none;
	border-radius: 3px;
	background-color: #f7f7f7;
	cursor: pointer;
}
.low-availability-summary_table-content {
	display: flex;
	justify-content: space-between;
	gap: 4px;
}
.quantity-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #fff;
	border-radius: 3px;
	text-align: center;
}
.quantity-wrapper span {
	padding: 6px 2px;
	font-family: var(--font-primary);
}
.quantity-wrapper span:first-child {
	border-bottom: 1px solid #fff;
}

.brochure-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	min-height: 50vh;
}

.brochure-modal {
	visibility: hidden;
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: var(--color-white);
	z-index: 103;
	transform: translate(-50%, -50%);
	pointer-events: none;

	@media(--breakpoint-tablet-sm-up) {
		width: 80%;
	}
}

.brochure-modal--open {
	visibility: visible;
	pointer-events: all;
}

.brochure-modal__close {
	display: inline-block;
	align-self: flex-end;
	padding: 10px;
	cursor: pointer;
}

.overlay-brochure-open {
	z-index: 102;
}

.js_brochure_modal_trigger {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.border-radius-5 {
	border-radius: 5px;
}

.overlay-global--z-index {
	z-index: 101;
}

.filters-aside-item-body {
	padding-top: 16px;
}

.filters-aside-item-body .check > input {
	width: 20px;
	height: 20px;
}

.filters-aside-item-body .check > label::before,
.filters-aside-item-body .check > label::after {
	width: 20px;
	height: 20px;
	border-radius: 2px;
}

.filters-aside-item-body .check > label::after {
	content: url("../../../images/check.svg");
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #99CF28;
}

.filters-aside-item-body .check > label::before {
	border: 1px solid #dbdbdb;
	background: transparent;
}

.filters-aside-item-body .check {
	display: flex;
	align-items: center;
	padding-bottom: 16px;
}

.filter-checklist .filters-aside-item-body,
.filter-categories .filters-aside-item-body {
	max-height: 165px;
}

.filter-checklist.is-active::after,
.filter-categories::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(0deg, #FFF 25.13%, rgba(255, 255, 255, 0.00) 162.5%);
	width: 90%;
	height: 15%;
}

.filters-aside-item-body::-webkit-scrollbar {
	width: 7px;
}

.filters-aside-item-body::-webkit-scrollbar-track {
	width: 7px;
	border-radius: 100px;
	background: #EDEDED;
}

.filters-aside-item-body::-webkit-scrollbar-thumb {
	width: 7px;
	border-radius: 100px;
	background: #DBDBDB; 
}

.box-type-2.promo {
	border: 1px solid #99CF28;
}

.box-price-wrap .box-price span {
	font-size: 18px;
}

.box-price-wrap .promo-price {
	padding: 3px 8px;
	margin-right: 10px;
	color: #fff;
	background-color: #99CF28;
	border-radius: 4px;
}

.box-price-wrap .old-price {
	position: relative;
}

.box-price-wrap .old-price::before {
	content: "";
	position: absolute;
	top: 50%;
	width: 90%;
	height: 1px;
	background-color: #251F20;
	transform: rotate(-15deg);
}


.box-type-2.promo {
	border: 1px solid #99CF28;
}

.box-price-wrap .box-price span {
	font-size: 18px;
}

.box-price-wrap .promo-price {
	padding: 3px 8px;
	margin-right: 10px;
	color: #fff;
	background-color: #99CF28;
	border-radius: 4px;
}

.box-price-wrap .old-price {
	position: relative;
}

.box-price-wrap .old-price::before {
	content: "";
	position: absolute;
	top: 50%;
	width: 90%;
	height: 1px;
	background-color: #251F20;
	transform: rotate(-15deg);
}

.sitemap {
	padding-top: 30px;
}
