#rebrand {
  .register {
    position: relative;
    padding: 35px 0 60px;

    &__title {
      margin-bottom: 25px;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    &__form {
      max-width: 491px;
      margin: 0 auto;

      &-button {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 100px;
      }

      .form-row {
        margin-bottom: 24px;
      }

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;

          &::placeholder {
            font-size: 14px;
            color: var(--color-gray-dk);
          }
        }
      }

      .check {
        display: flex;
        align-items: center;

        a {
          color: var(--redesign-color-black-md);
          text-decoration: underline;
        }

        input {
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }

        label {
          &::before,
          &::after {
            width: 20px;
            height: 20px;
            border-radius: 2px;
          }

          &::before {
            border: 1px solid var(--redesign-color-border);
            background: transparent;
            box-shadow: none;
          }

          &::after {
            content: url("../../../images/check.svg");
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--redesign-color-primary);
          }
        }
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding: 25px 0 30px;
    }
  }
}
