@font-face {
  font-family: 'gs-iconfont';
  src:  url('../../../fonts/iconfonts/gs-iconfont.eot?7e2xd7');
  src:  url('../../../fonts/iconfonts/gs-iconfont.eot?7e2xd7#iefix') format('embedded-opentype'),
    url('../../../fonts/iconfonts/gs-iconfont.ttf?7e2xd7') format('truetype'),
    url('../../../fonts/iconfonts/gs-iconfont.woff?7e2xd7') format('woff'),
    url('../../../fonts/iconfonts/gs-iconfont.svg?7e2xd7#gs-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gs-ico-"], [class*=" gs-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gs-iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gs-ico-chevron_down_thin:before {
  content: "\e906";
}
.gs-ico-chevron_left_thin:before {
  content: "\e907";
}
.gs-ico-chevron_right_thin:before {
  content: "\e908";
}
.gs-ico-chevron_up_thin:before {
  content: "\e909";
}
.gs-ico-carty:before {
  content: "\e900";
}
.gs-ico-user:before {
  content: "\e901";
}
.gs-ico-chevron-down:before {
  content: "\e902";
}
.gs-ico-chevron-left:before {
  content: "\e903";
}
.gs-ico-chevron-right:before {
  content: "\e904";
}
.gs-ico-chevron-up:before {
  content: "\e905";
}
