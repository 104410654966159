#rebrand {
  .cart {
    position: relative;
    padding: 20px 0 80px;
    background-color: var(--color-white-md);
    border-bottom: 1px solid var(--redesign-color-border);

    .wrapper {
      @media (--breakpoint-tablet-sm) {
        padding: 0;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        padding: 0 10px;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 24px;

      @media (--breakpoint-desktop) {
        grid-template-columns: 1fr;
        gap: 32px;
      }
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-right: 20px;
      border-right: 1px solid var(--redesign-color-border);

      @media (--breakpoint-desktop) {
        padding-right: 0;
        border-right: none;
      }
    }

    &__summary {
      &-list {
        margin-bottom: 25px;
      }

      &-item,
      &-discount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 22px;
        color: var(--redesign-color-black-md);

        p {
          font-size: 16px;
          font-weight: 700;
        }

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }

      &-discount {
        padding-top: 22px;
        border-bottom: 1px solid var(--redesign-color-border);
      }

      &-button {
        max-width: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      &-select {
        padding: 22px 0;
        border-top: 1px solid var(--redesign-color-border);
        border-bottom: 1px solid var(--redesign-color-border);

        &.active {
          h3 {
            margin-bottom: 16px;

            &::after {
              transform: translateY(-50%) rotate(180deg);
              transition: transform 0.3s ease-in;
            }
          }

          .cart__summary-form {
            display: block;
          }
        }

        h3 {
          position: relative;
          cursor: pointer;

          &::after {
            content: url("../../../images/arrow-down.svg");
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: transform 0.3s ease-in;
          }
        }
      }

      &-form {
        display: none;

        form {
          display: grid;
          grid-template-columns: 2fr 1fr;
          align-items: center;
          gap: 8px;
          margin-bottom: 10px;

          @media (--breakpoint-tablet-sm) {
            grid-template-columns: 1fr;
            gap: 12px;
          }
        }

        button {
          padding: 12px 20px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.42px;
          color: var(--color-white);
          border-radius: 100px;
          background-color: var(--redesign-color-primary);
          text-transform: uppercase;

          &.filled {
            background-color: var(--color-gray-dk);
          }
        }

        .field {
          input {
            max-width: 279px;
            width: 100%;
            height: 40px;
            padding: 12px 20px;
            border-radius: 100px;
            border: 1px solid var(--redesign-color-border);

            &::placeholder {
              font-size: 14px;
              color: var(--color-gray-dk);
            }

            @media (--breakpoint-tablet-sm) {
              max-width: 100%;
            }
          }
        }

        .message {
          display: flex;
          align-items: center;
          gap: 6px;
          color: var(--redesign-color-primary);
        }
      }

      &-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 22px;
        color: var(--redesign-color-black-md);

        p {
          font-size: 20px;
          font-weight: 700;
        }

        span {
          font-size: 24px;
          font-weight: 700;
        }
      }

      &-content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          color: var(--redesign-color-black-md);
        }

        button {
          position: relative;
        }
      }

      &-buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .complete,
        .continue {
          display: block;
          width: 100%;
          padding: 12px 0;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.42px;
          border-radius: 100px;
          text-align: center;
          text-transform: uppercase;
        }

        .complete {
          color: var(--color-white);
          background-color: var(--redesign-color-primary);
          border: 1px solid transparent;
        }

        .continue {
          color: var(--redesign-color-primary);
          border: 1px solid var(--redesign-color-primary);
        }
      }

      @media (--breakpoint-tablet-sm) {
        padding: 0 10px;
      }

      @media (--breakpoint-desktop) {
        max-width: 861px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding-bottom: 50px;
    }
  }
}
