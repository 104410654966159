#rebrand {
  .products-bought-together {
    position: relative;
    padding: 50px 0;
    background-color: var(--color-white-md);
    margin-bottom: 80px;

    &__title {
      margin-bottom: 50px;
      font-size: 28px;
      font-weight: 600;
      line-height: 26px;
      color: var(--redesign-color-black-md);
      text-align: center;

      @media (--breakpoint-tablet) {
        margin-bottom: 25px;
        font-size: 24px;
      }
    }

    &__boxes-container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
      height: fit-content;
      padding-right: 50px;
      border-right: 1px solid var(--redesign-color-border);

      @media (--breakpoint-tablet) {
        padding: 0 0 20px;
        gap: 12px;
        border-right: 0;
        border-bottom: 1px solid var(--redesign-color-border);
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (--breakpoint-desktop) {
        flex-direction: row-reverse;
      }
    }

    .boxes-related {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;

      @media (--breakpoint-tablet) {
        grid-template-columns: 1fr;
      }
    }

    .box-related {
      position: relative;
      display: grid;
      grid-template-columns: 20px 105px auto;
      align-items: center;
      gap: 20px;
      max-width: 774px;
      width: 100%;
      padding: 12px 20px;
      margin: 0;
      border-radius: 8px;

      &::after {
        content: url("../../../images/add.svg");
        position: absolute;
        bottom: -30px;
        left: 50%;
      }

      &:last-child::after {
        content: none;
      }

      &.checked {
        border: 1px solid var(--redesign-color-primary);

        .picture img {
          border: 1px solid var(--redesign-color-primary);
        }
      }

      .picture {
        margin-bottom: 0;

        img {
          height: 81px;
          border-radius: 4px;
          border: 1px solid var(--redesign-color-border);
        }
      }

      @media (--breakpoint-tablet) {
        padding: 12px;
        gap: 12px;
        max-width: 100%;

        &::after {
          content: none;
        }
      }
    }

    .added-products {
      margin: 0 0 0 50px;
      text-align: left;

      @media (--breakpoint-tablet) {
        padding: 30px 0 0;
        margin: 0;
        text-align: center;
      }
    }

    .added-products .box-added-products {
      width: 100%;
      text-align: left;

      @media (--breakpoint-tablet) {
        text-align: center;
      }
    }

    .added-title {
      max-width: 100%;
      margin: 0 0 25px;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;

      @media (--breakpoint-tablet) {
        margin-bottom: 12px;
      }
    }

    .total-price {
      font-size: 28px;
      font-weight: 700;
      line-height: 26px;
      color: var(--redesign-color-black-md);

      span {
        font-size: 24px;
      }

      @media (--breakpoint-tablet) {
        margin-bottom: 24px;
        font-size: 24px;

        span {
          font-size: 20px;
        }
      }
    }

    .related-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--breakpoint-desktop) {
        flex-direction: column;
        align-items: start;
        gap: 5px;
      }
    }

    .related-content .title {
      max-width: 255px;
      height: 100%;
      margin-bottom: 0;
      font-size: 16px;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet) {
        font-size: 14px;
      }
    }

    .related-price {
      font-size: 18px;
      font-weight: 700;
      color: var(--redesign-color-black-md);

      span {
        font-size: 14px;
      }
    }

    .btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 252px;
      width: 100%;
      height: 100%;
      gap: 10px;
      border-radius: 100px;
      border: none;
      cursor: pointer;

      img {
        display: block;
        width: 18px;
        height: 18px;
      }

      @media (--breakpoint-tablet) {
        max-width: 328px;
        margin: 0 auto;
      }
    }

    .old-price {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        width: 90%;
        height: 1px;
        background-color: var(--redesign-color-black-md);
        transform: rotate(-15deg);
      }
    }

    @media (--breakpoint-tablet) {
      padding: 30px 0;
      margin-bottom: 50px;
    }
  }
}
