/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; /*overflow:hidden;*/}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%; overflow: hidden; }
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative; }
/*#cboxLoadedContent{overflow:hidden !important; -webkit-overflow-scrolling: touch;}*/
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background: #000; opacity: 0.9!important}
#colorbox{outline:0;}
#cboxLoadedContent { }
#cboxContent{margin-top:32px; background:#fff;}
.cboxIframe{background:#000;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadingGraphic{background:url('../../../images/loader.gif') no-repeat center center;}
#cboxLoadingOverlay{background:#fff;}
#cboxCurrent{position:absolute; top:-22px; right:205px; text-indent:-9999px;}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {border:0; padding:0; margin:0; overflow:visible; text-indent:-9999px; width:20px; height:20px; position:absolute; top:-20px; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s;}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {outline:0;}
#cboxPrevious:focus,
#cboxNext:focus { outline: none; }
#cboxClose:focus { outline: 0; }

/* ==========================================================================
    #custom styles
========================================================================== */
#cboxTitle { position:absolute; top: 100%; left: 0; right: 0; padding: 0 10px; background: #fff; font-family: inherit; font-size: 16px; line-height: 22px; color: #000; text-align: center; }

#cboxPrevious,
#cboxNext { position: absolute; top: 50%; opacity: .7; width: 20px; height: 37px; margin: -18px 0 0; outline: none; background-color: transparent; background-repeat: no-repeat; background-position: center; }

#cboxPrevious:hover,
#cboxNext:hover { opacity: 1; }

#cboxPrevious { left: -40px; background-image: url('../../../images/cboxPrev.svg'); }
#cboxNext { right: -40px; background-image: url('../../../images/cboxNext.svg'); }

#cboxClose { top: -30px; right: -30px; opacity: .7; width: 20px; height: 20px; background-color: transparent; background-image: url('../../../images/cboxClose.svg'); background-repeat: no-repeat; background-position: center; }
#cboxClose:hover { opacity: 1; }
#cboxClose:hover {
	-webkit-transform: rotate(90deg);
	   -moz-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	     -o-transform: rotate(90deg);
	        transform: rotate(90deg);
}