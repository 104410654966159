#rebrand {
  .product-preview {
    position: relative;
    padding-top: 30px;

    &__container {
      display: grid;
      grid-template-columns: 116px auto 1fr;
      gap: 34px;
      grid-template-areas:
        "swiper picture content"
        ". locations .";

      .box-type-2 {
        padding: 0;

        &:hover {
          .picture {
            overflow: unset;
          }

          img {
            transform: none;
          }
        }

        .picture {
          max-width: 112px;
          margin-bottom: 12px;
          cursor: pointer;

          &.active {
            img {
              border: 2px solid var(--redesign-color-primary);

              @media (--breakpoint-desktop) {
                border: none;
              }
            }
          }

          img {
            border-radius: 4px;
            border: 2px solid transparent;

            @media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
              max-width: 328px;
            }

            @media (--breakpoint-desktop) {
              min-width: 328px;
              border: none;
            }
          }

          @media (--breakpoint-desktop) {
            max-width: 100%;
            min-height: 300px;
          }
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "swiper"
          "content"
          "locations";
      }

      @media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
        grid-template-columns: 328px auto;
        grid-template-areas:
          "swiper content"
          "swiper locations";
        gap: 23px;
      }

      @media (--breakpoint-tablet-up) and (--breakpoint-desktop) {
        grid-template-columns: 518px auto;
        grid-template-areas:
          "swiper content"
          "swiper locations";
        gap: 23px;
      }
    }

    &__content {
      grid-area: content;

      &-top {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        column-gap: 17px;
        row-gap: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--redesign-color-border);
        grid-template-areas: "title brand" "code info";

        .title {
          grid-area: title;
          max-width: 436px;
          width: 100%;
          font-size: 20px;
          line-height: 26px;
          color: var(--redesign-color-black-md);
        }

        .logo {
          grid-area: brand;

          img {
            float: right;
          }
        }

        .products-code {
          grid-area: code;
          font-size: 12px;
          color: var(--color-gray-dk);

          @media (--breakpoint-desktop) {
            font-size: 14px;
          }
        }

        .product-info {
          grid-area: info;
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 6px;
          font-size: 12px;
          color: var(--redesign-color-primary);

          @media (--breakpoint-desktop) {
            justify-self: start;
            font-size: 14px;
          }
        }

        @media (--breakpoint-desktop) {
          grid-template-areas: "title title" "code brand" "info brand";
          gap: 0;
        }
      }

      &-info {
        padding-top: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--redesign-color-border);
      }

      &-list {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 32px;
        margin-bottom: 16px;
        list-style: none;

        @media (--breakpoint-desktop) {
          grid-template-columns: 1fr;
        }
      }

      &-item {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: var(--redesign-color-black-md);
          transform: translateY(-50%);
        }

        span {
          display: block;
          padding-left: 12px;
          font-size: 14px;
          color: var(--redesign-color-black-md);
        }
      }

      &-link {
        display: block;
        margin-bottom: 20px;
        font-size: 12px;
        color: var(--redesign-color-black-md);
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    &__btn {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 40px;
      height: 40px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }

    &__item {
      .picture {
        position: relative;
        width: 574px;
        height: 526px;
        margin-bottom: 18px;

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }

        @media (--breakpoint-desktop) {
          display: none;
        }
      }
    }

    .box-type-2 {
      display: flex;
      gap: 20px;
      grid-area: swiper;
      justify-content: center;
      overflow: hidden;

      @media (--breakpoint-desktop) {
        position: relative;
        width: 100%;
        height: fit-content;
        padding-bottom: 30px;
      }
    }

    .boxes-type-10 {
      max-width: 120px;
      margin: 0;
      padding: 0;

      @media (--breakpoint-desktop) {
        max-width: 100%;
      }
    }

    .slick-slide {
      border: 0;

      > .picture {
        max-width: 112px;
        height: 112px;

        @media (--breakpoint-desktop) {
          max-width: 500px;
          height: 300px;
          margin: 0 auto;
        }

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }
    }

    .slick-list {
      margin-top: 20px;

      @media (--breakpoint-desktop) {
        margin-top: 0;
      }
    }

    .box-type-10 {
      width: 100%;
      border: 0;
      border-radius: 10px;
    }

    .slick-current {
      > img {
        border: 2px solid var(--redesign-color-primary);

        @media (--breakpoint-desktop) {
          border: 0;
        }
      }
    }

    .slick-dots {
      bottom: -30px;

      li {
        &.slick-active {
          button::before {
            background-color: var(--redesign-color-primary);
            opacity: 1;
          }
        }
      }

      li,
      li button {
        width: 12px;
        height: 12px;
      }

      li button::before {
        width: 12px;
        height: 12px;
        color: transparent;
        border: 1px solid var(--redesign-color-primary);
        border-radius: 50%;
        opacity: 1;
      }
    }

    // Form products add
    .product-variations {
      display: block;
      margin-bottom: 30px;
      border-bottom: 1px solid var(--redesign-color-border);
    }

    .size-variations,
    .color-variations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media (--breakpoint-desktop) {
        flex-direction: column;
        align-items: start;
        gap: 12px;
      }
    }

    .color-variation {
      label {
        @media (--breakpoint-desktop) {
          width: 100%;
          justify-content: start;
        }
      }
    }

    .field {
      @media (--breakpoint-desktop) {
        width: 100%;
      }
    }

    .size-variations {
      option {
        @media (--breakpoint-desktop) {
          display: none;
        }
      }
    }

    .color-variations {
      .select-box {
        @media (--breakpoint-desktop) {
          width: 100%;
          padding: 10px 0;
        }
      }
    }

    select,
    .select-box {
      min-width: 166px;
      border-radius: 100px;

      @media (--breakpoint-desktop) {
        display: none;
      }
    }

    .select-box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border: 1px solid var(--color-neutral-mid);
      font-size: 14px;
      cursor: pointer;

      &.active {
        .select-options {
          display: block;

          @media (--breakpoint-desktop) {
            display: none;
          }
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        right: 10px;
      }
    }

    .selected {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    .select-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid var(--redesign-color-silver);
      background-color: var(--color-white);
      display: none;
      z-index: 10;
    }

    .option {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 0;
      cursor: pointer;

      &:hover {
        background-color: var(--redesign-color-smoke);
      }
    }

    .color-box {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 10px;
      border: 1px solid;
    }

    .color-name {
      display: inline-block;
      font-size: 14px;
      color: var(--color-black);
      white-space: nowrap;
    }

    .field-select,
    .select-box {
      &::before {
        content: url("../../../images/select-arrow.svg");
        width: 10px;
        height: 15px;
        border-width: 0;
        transform: translateY(-50%);
      }
    }

    .field-number {
      > input {
        border-radius: 100px;
        border: 1px solid var(--redesign-color-border);
      }

      > button {
        position: absolute;
        background-color: unset;
        border: 0;
        cursor: pointer;
      }
    }

    .counter-minus {
      top: 50%;
      transform: translateX(-50%);
      left: 20px;
    }

    .counter-plus {
      top: 35%;
      transform: translateX(-35%);
      right: 10px;
    }

    .counter-container {
      margin-bottom: 8px;

      &.counters {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        gap: 17px;

        @media (--breakpoint-desktop) {
          grid-template-columns: 1fr;
          gap: 0;
        }
      }

      @media (--breakpoint-tablet-sm) {
        max-width: 375px;
        margin: 0 auto;
        margin-bottom: 12px;
      }
    }

    .counter-image {
      display: block;
      width: 27px;
      height: 18px;
      padding-top: 35px;

      @media (--breakpoint-desktop) {
        padding-top: 20px;
        margin: 0 auto;
      }
    }

    .price-total {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 30px;

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: var(--redesign-color-black-md);
        text-transform: uppercase;
      }

      strong {
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;

        > span {
          font-size: 20px;
        }
      }

      .default-price {
        font-size: 24px;
        line-height: 26px;
        font-weight: 700;
        color: var(--redesign-color-black-md);

        span {
          font-size: 20px;
          text-transform: lowercase;
        }
      }

      .new-price {
        padding: 8px;
        font-size: 24px;
        line-height: 26px;
        font-weight: 700;
        color: var(--color-white);
        background-color: var(--redesign-color-primary);
        border-radius: 4px;

        span {
          font-size: 20px;
          font-weight: 700;
          color: var(--color-white);
          text-transform: lowercase;
        }
      }

      .old-price {
        position: relative;
        font-size: 18px;
        font-weight: 600;
        color: var(--redesign-color-black-md);

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          width: 90%;
          height: 1px;
          background-color: var(--redesign-color-black-md);
          transform: rotate(-15deg);
        }

        span {
          font-size: 14px;
          text-transform: lowercase;
        }
      }
    }

    .buttons-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;

      .btns {
        width: 100%;

        @media (--breakpoint-tablet-sm) {
          max-width: 375px;
          margin: 0 auto;
        }
      }

      .btn-secondary,
      .btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 100px;

        img {
          width: 18px;
          height: 18px;
        }
      }

      #buy-now-btn {
        border: 1px solid transparent;
      }
      #fast-delivery-btn {
        color: var(--redesign-color-primary);
        background-color: transparent;
        border: 1px solid var(--redesign-color-primary);
      }

      @media (--breakpoint-desktop) {
        grid-template-columns: 1fr;
        gap: 15px;
        margin-bottom: 24px;
      }
    }

    .btn-products {
      min-width: 194px;
      max-width: 328px;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-family: var(--font-secondary);
      font-size: 14px;
      font-weight: 700;
      border-radius: 100px;
      background-color: var(--redesign-color-white-md);
      color: var(--redesign-color-primary);
      text-align: center;
      text-transform: uppercase;
    }

    .msg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 16px;
      font-size: 14px;
      line-height: 16px;
      color: var(--redesign-color-black-md);

      img {
        width: 23px;
        height: 23px;
      }

      @media (--breakpoint-desktop) {
        margin-bottom: 0;
      }
    }

    .product-calc-utils {
      padding: 9px 0;
      margin-bottom: 8px;
      font-size: 12px;
      color: var(--color-gray-dk);
      background-color: var(--color-white-md);
      text-align: center;
    }

    .product-calc-info {
      margin-bottom: 40px;
      font-size: 12px;
      color: var(--color-gray-dk);
    }

    .counter-box-prices {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      .default-price,
      .new-price {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;

        span {
          font-size: 14px;
        }
      }

      .default-price {
        color: var(--redesign-color-black-md);
      }

      .new-price {
        color: var(--redesign-color-primary);
      }

      .old-price {
        font-size: 14px;
        font-weight: 600;
        color: var(--redesign-color-black-md);

        span {
          position: relative;
          font-size: 12px;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -20px;
            width: 70%;
            height: 1px;
            background-color: var(--redesign-color-black-md);
            transform: rotate(-15deg);
          }
        }
      }
    }

    .size-variations-trigger {
      display: none;
      min-width: 166px;
      max-width: 100%;
      width: 100%;
      height: 40px;
      padding: 11px;
      color: var(--redesign-color-black-md);
      border: 1px solid var(--color-gray-dk);
      border-radius: 100px;
      background: var(--color-white);
      font-size: 14px;
      line-height: 20px;
      text-align: left;

      @media (--breakpoint-desktop) {
        display: block;
      }
    }

    // Color modal styles
    .modal {
      display: none;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      &__content {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 30px 0;
        background-color: var(--color-white);
        border-radius: 8px 8px 0 0;
      }

      &__title {
        margin-bottom: 24px;
        text-align: center;
      }

      &__options {
        padding: 0 10px;

        li {
          position: relative;
          width: 100%;
          padding: 16px 0;
          border-bottom: 1px solid var(--redesign-color-border);
          list-style: none;
          cursor: pointer;

          &.active::after {
            content: url("../../../images/filters-check.svg");
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
          }

          &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }

      &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }
    }

    .products-locations {
      @media (--breakpoint-desktop) {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 12px;
      }
    }
  }
}
